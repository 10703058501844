import { useLanguage } from '../../commonHooks/useLocalization';
import { HospitalConstants } from '../../utils/constants/HospitalConstants';

const HospitalAccordionHeader = () => {
    const language = useLanguage();

    return (
        <div className="col">
            <div className="row">
                <div className="col-2 px-2">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .hospitalPen.label
                    }
                </div>
                <div className="col-2 px-2">
                    {
                        HospitalConstants[language.value].accordionHeader.lot
                            .label
                    }
                </div>
                <div className="col-2 px-2">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .animalId.label
                    }
                </div>
                <div className="col-2 px-2">
                    {
                        HospitalConstants[language.value].accordionHeader.eid
                            .label
                    }
                </div>
                <div className="col-2 px-2">
                    {
                        HospitalConstants[language.value].accordionHeader
                            .homePen.label
                    }
                </div>
            </div>
        </div>
    );
};

export default HospitalAccordionHeader;
