import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { TREATMENTS_INITIAL_STATE } from '../Constants/treatmentsInitialState';
import {
    calculateDose_deprecated,
    calculateTotalPrice_deprecated,
} from '../../components/healthTreatment/TreatmentHook';
import { IEditTreatmentsDetails } from '../../utils/Interface/TreatmentsInterface';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import dayjs from '../../utils/dayjs';
import { IBackdatedInfoModal } from '../../utils/Interface/CommonInterface';

const { isCA12931On_HideInactiveMedicalLots } = feedlotManagerFeatures;

const updateChargeoutItemsCollection = (state, weight) => {
    state.chargeoutItemsCollection = state.chargeoutItemsCollection.map(
        chargeoutItem => {
            const medicalItem = current(
                state.medicalItems.find(
                    item => item.medicalItemId === chargeoutItem.medicalItemId,
                ),
            );
            const dose = medicalItem
                ? calculateDose_deprecated(medicalItem, weight)
                : 0;
            const totalPrice = medicalItem
                ? calculateTotalPrice_deprecated(medicalItem, dose)
                : 0;
            return {
                ...chargeoutItem,
                dose,
                totalPrice,
            };
        },
    );
};

const treatmentsSlice = createSlice({
    name: 'treatments',
    initialState: TREATMENTS_INITIAL_STATE,
    reducers: {
        setTreatments: (state, action) => {
            state.treatments = action.payload;
        },
        setTreatmentsLoading: (state, action) => {
            state.loading = action.payload;
        },
        setTreatmentHistoryDetailLoading: (state, action) => {
            state.treatmentHistoryDetailLoading = action.payload;
        },
        setTreatmentsCanCallApi: (state, action) => {
            state.canCallApi = action.payload;
        },
        setTreatmentsFilter: (state, action) => {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...action.payload,
                },
            };
        },
        setEditTreatmentsDetails: (
            state,
            action: PayloadAction<Partial<IEditTreatmentsDetails>>,
        ) => {
            for (const key in action.payload) {
                state.editTreatmentsDetails[key] = action.payload[key];
                if (key === 'currentWeight') {
                    updateChargeoutItemsCollection(state, action.payload[key]);
                }
            }
        },
        setEditTreatmentsDetailsEid: (state, action: PayloadAction<string>) => {
            state.editTreatmentsDetails.eid = action.payload ?? '';
        },
        setEditTreatmentsDetailsError: (state, action) => {
            state.editTreatmentsDetailsError = action.payload;
        },
        setOriginalTreatmentDetails: (state, action) => {
            state.originalTreatmentDetails = action.payload;
            if (isCA12931On_HideInactiveMedicalLots) {
                state.editTreatmentsDetails = action.payload;
            }
        },
        setLots: (state, action) => {
            state.lots = action.payload;
        },
        setTreatmentsDiagnosis: (state, action) => {
            state.diagnosis = action.payload;
        },
        setHospitalPens: (state, action) => {
            state.hospitalPens = action.payload;
        },
        setLotPens: (state, action) => {
            state.lotPens = action.payload;
        },
        setPenFields: (state, action) => {
            state.editTreatmentsDetails = {
                ...state.editTreatmentsDetails,
                ...action.payload,
            };
        },
        setTreatmentsProtocols: (state, action) => {
            state.treatmentProtocols = action.payload;
        },
        setTechs: (state, action) => {
            state.techs = action.payload;
        },
        setRiders: (state, action) => {
            state.riders = action.payload;
        },
        setSex: (state, action) => {
            state.sex = action.payload;
        },
        setAnimalIds: (state, action) => {
            state.animalIds = action.payload;
        },
        setMedicalItems: (state, action) => {
            state.medicalItems = action.payload;
        },
        setHistoricalGiveRecord: (state, action) => {
            state.historicalGiveRecords = action.payload;
        },
        setGiveRecord: (state, action) => {
            state.giveRecord = action.payload;
        },
        setGiveRecords: (state, action) => {
            state.giveRecords = action.payload;
        },
        setTreatmentProtocolGiveRecords: (state, action) => {
            state.giveTreatmentProtocolRecords = action.payload;
        },
        setHistory: (state, action) => {
            const sortedHistory = action.payload.sort((a, b) => {
                if (dayjs(a.date).isSame(dayjs(b.date))) {
                    return dayjs(a.createdDatetime).isAfter(
                        dayjs(b.createdDatetime),
                    )
                        ? 1
                        : -1;
                } else {
                    return dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1;
                }
            });
            state.history = sortedHistory;
        },
        setChargeoutItemsCollection: (state, action) => {
            state.chargeoutItemsCollection = action.payload;
        },
        setOriginalChargeoutItemsCollection: (state, action) => {
            state.originalChargeoutItemsCollection = action.payload;
            state.chargeoutItemsCollection = action.payload;
        },
        setChargeoutOnWeightUpdate: (state, action) => {
            updateChargeoutItemsCollection(state, action.payload);
        },
        setChargeoutLoading: (state, action) => {
            state.isChargeoutCollectionLoading = action.payload;
        },
        setCompleteTreatments: (state, action) => {
            state.completeTreatments = action.payload;
        },
        setGeneratedInvoiceCutoffDate: (state, action) => {
            state.generatedInvoiceCutoffDate = action.payload;
        },
        setInsufficientInventory: (state, action) => {
            if (!state.insufficientInventory.includes(action.payload)) {
                state.insufficientInventory.push(action.payload);
            }
        },
        removeInsufficientInventory: (state, action) => {
            const { numberToRemove, shiftIndexes } = action.payload;
            state.insufficientInventory = state.insufficientInventory
                .filter(number => number !== numberToRemove)
                .map(number =>
                    shiftIndexes && number > numberToRemove
                        ? number - 1
                        : number,
                );
        },
        clearInsufficientInventory: (state, action) => {
            state.insufficientInventory = [];
        },
        resetState: () => {
            return TREATMENTS_INITIAL_STATE;
        },
        setTreatmentsSubmitPending: (state, action) => {
            state.submitPending = action.payload;
        },
        setProtocolsAreLoading: (state, action) => {
            state.protocolsAreLoading = action.payload;
        },
        setBackdatedInfoModal: (
            state,
            action: PayloadAction<IBackdatedInfoModal>,
        ) => {
            state.backdatedInfoModal = action.payload;
        },
    },
});

export const treatmentActions = treatmentsSlice.actions;

const treatmentsReducer = treatmentsSlice.reducer;
export default treatmentsReducer;
