import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    Row,
} from 'react-bootstrap';
import { currentDate } from '../../helpers/dateTime';
import { IMoveFromHospitalPenModal } from '../../utils/Interface/HealthSection/HealthScreenInterface';
import { IUpdateAnimalPayload } from '../../utils/Interface/HospitalInterface';
import { useLanguage } from '../../commonHooks/useLocalization';
import { MoveFromHospitalPenModalConstants } from '../../utils/constants/MoveFromHospitalPenModalConstants';
import { useEffect, useState } from 'react';
import {
    fetchAnimalsHospital,
    fetchMovePens,
    fetchMovePens_deprecated,
    moveCattleToDifferentPen,
} from '../../Redux/Actions/hospitalActions';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import ProtectedSpinnerButton from '../common/ProtectedSpinnerButton';
import { animalApi } from '../../Redux/Apis/Animal/animalApi';
import { PutApiFmByFlidAnimalAndIdApiArg } from '../../Redux/Apis/Animal/baseAnimalApi';
import { toast } from 'react-toastify';

const {
    isCA10313On_HospitalPageUnassociatedHomePen,
    isCACA13778On_HospitalPageSubmitBug,
} = feedlotManagerFeatures;

const MoveFromHospitalPenModal = ({
    showModal,
    closeModalHandler,
    selectedAnimal,
    penFilter,
}: IMoveFromHospitalPenModal) => {
    const {
        lotId = '',
        lotName = '',
        animalId = '',
        eid = '',
        animalTag = '',
        currentPenName: hospitalPen = '',
        currentPenId: hospitalPenId = '',
        homePenName = '',
        homePenId = '',
    } = selectedAnimal ?? {};

    const dispatch = useAppDispatch();

    const language = useLanguage();
    const feedlotId = useFeedlotId();

    const { hospitalPens, movePens } = useAppSelector(state => state.hospital);

    const [moveToPen, setMoveToPen] = useState('');
    const [moveToPenError, setMoveToPenError] = useState(false);
    const [showHomePenMessage, setShowHomePenMessage] = useState(false);
    const [currentRationNames, setCurrentRationNames] = useState<string[]>([]);
    const [showWarning, setShowWarning] = useState(false);

    const [moveAnimal, { isLoading }] =
        animalApi.usePutApiFmByFlidAnimalAndIdMutation();

    const getTodaysDateStringFormatted = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const moveDate = isCACA13778On_HospitalPageSubmitBug
        ? currentDate('YYYY-MM-DD')
        : getTodaysDateStringFormatted();

    useEffect(() => {
        if (lotId && feedlotId) {
            if (isCA10313On_HospitalPageUnassociatedHomePen) {
                dispatch(
                    fetchMovePens(
                        feedlotId ?? 0,
                        +lotId,
                        setShowHomePenMessage,
                    ),
                );
            } else {
                dispatch(fetchMovePens_deprecated(feedlotId ?? 0, +lotId));
            }
        }
    }, [animalId, feedlotId, lotId, dispatch]);

    const onSubmit = () => {
        if (moveToPen && feedlotId) {
            const payload: PutApiFmByFlidAnimalAndIdApiArg = {
                flid: feedlotId,
                id: +animalId,
                feedlotManagerModelsMedicalUpdateAnimalModel: {
                    animalId: +animalId,
                    moveDate: moveDate,
                    currentPenId: +hospitalPenId,
                    movePenId: +moveToPen,
                },
            };
            moveAnimal(payload)
                .unwrap()
                .then(() => {
                    toast.success(
                        MoveFromHospitalPenModalConstants[language.value]
                            .successMessage,
                    );
                    dispatch(fetchAnimalsHospital(feedlotId, penFilter));
                    closeModalHandler(true);
                    setMoveToPen('');
                })
                .catch(() => {
                    toast.error(
                        MoveFromHospitalPenModalConstants[language.value]
                            .failedMessage,
                    );
                });
        } else {
            setMoveToPenError(true);
        }
    };

    const onSubmit_deprecated = () => {
        if (moveToPen && feedlotId) {
            const payload: IUpdateAnimalPayload = {
                animalId: +animalId,
                moveDate: new Date(moveDate).toISOString(),
                currentPenId: hospitalPenId.toString(),
                movePenId: moveToPen,
            };
            dispatch(
                moveCattleToDifferentPen(
                    feedlotId,
                    payload,
                    language.value,
                    penFilter,
                ),
            ).then(() => {
                dispatch(fetchAnimalsHospital(feedlotId, penFilter));
            });
            closeModalHandler(true);
            setMoveToPen('');
        } else {
            setMoveToPenError(true);
        }
    };

    const moveToPenHandler = (penId: string) => {
        setMoveToPen(penId);
        setMoveToPenError(false);
        setShowWarning(false);
        const currentRationNames = movePens.find(
            pen => pen.penId === +penId,
        )?.currentRationNames;
        setCurrentRationNames(currentRationNames ?? []);
    };

    const addHomePenToMovePens =
        homePenId && !movePens.some(movePen => movePen.penId === homePenId);

    const hospitalPenCurrentRationNames =
        hospitalPens.find(pen => pen.penId === +hospitalPenId)
            ?.currentRationNames ?? [];

    const isSameRation = (): boolean => {
        const hospitalRationSet = new Set(hospitalPenCurrentRationNames);
        const movePenRationSet = new Set(currentRationNames);
        if (hospitalRationSet.size !== movePenRationSet.size) return false;
        return [...hospitalRationSet].every(item => movePenRationSet.has(item));
    };

    const handleMoveClick = () => {
        if (!moveToPen) {
            setMoveToPenError(true);
            return;
        }
        if (!isSameRation()) {
            setShowWarning(true);
        } else {
            if (isCACA13778On_HospitalPageSubmitBug) {
                onSubmit();
            } else {
                onSubmit_deprecated();
            }
        }
    };

    const handleConfirmMove = () => {
        setShowWarning(false);
        if (isCACA13778On_HospitalPageSubmitBug) {
            onSubmit();
        } else {
            onSubmit_deprecated();
        }
    };

    const handleCancelMove = () => {
        setShowWarning(false);
        closeModalHandler();
    };

    const truncateRationName = (rationName: string) => {
        return rationName.length > 15
            ? rationName.slice(0, 15) + '...'
            : rationName;
    };

    const getUniqueRationNames = (rationNames: string[]) => {
        return Array.from(new Set(rationNames)).join(', ');
    };

    return (
        <Modal
            show={showModal}
            onHide={
                isLoading && isCACA13778On_HospitalPageSubmitBug
                    ? undefined
                    : () => closeModalHandler()
            }
            className="px-2 pt-5"
            data-size="md"
            centered={true}
        >
            <ModalHeader closeButton>
                <Modal.Title className="text-secondary">
                    <h5 className="modal-title">
                        {
                            MoveFromHospitalPenModalConstants[language.value]
                                .moveFromHospitalPen
                        }
                    </h5>
                </Modal.Title>
            </ModalHeader>
            <ModalBody>
                <div className="modal-body-inner">
                    <Row className="mb-4">
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].moveDate
                                }
                            </Form.Label>
                            <Form.Control
                                type="date"
                                name="date"
                                value={moveDate}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].lot
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="lot"
                                value={lotName}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        {eid && (
                            <Form.Group className="col-sm-4">
                                <Form.Label>
                                    {
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].eid
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="eid"
                                    value={eid}
                                    readOnly
                                ></Form.Control>
                            </Form.Group>
                        )}
                        {animalTag && (
                            <Form.Group className="col-sm-4">
                                <Form.Label>
                                    {
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].animalId
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="animalId"
                                    value={animalTag}
                                    readOnly
                                ></Form.Control>
                            </Form.Group>
                        )}
                    </Row>
                    <Row className="mb-4">
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].hospitalPen
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="hospitalPen"
                                value={hospitalPen}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                        {
                            <Form.Group className="col-sm-4">
                                <Form.Label>
                                    {
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].hospitalCurrentRation
                                    }
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="hospitalCurrentRation"
                                    value={getUniqueRationNames(
                                        hospitalPenCurrentRationNames,
                                    )}
                                    readOnly
                                ></Form.Control>
                            </Form.Group>
                        }
                        <Form.Group className="col-sm-4">
                            <Form.Label>
                                {
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].homePen
                                }
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="homePen"
                                value={homePenName}
                                readOnly
                            ></Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        {
                            <>
                                <Form.Group className="col-sm-4">
                                    <Form.Label className="me-0">
                                        {
                                            MoveFromHospitalPenModalConstants[
                                                language.value
                                            ].moveToPen
                                        }
                                    </Form.Label>
                                    <span className="necessaryFields">*</span>

                                    <Form.Control
                                        onChange={e => {
                                            moveToPenHandler(e.target.value);
                                        }}
                                        name="moveToPen"
                                        required
                                        as="select"
                                        value={moveToPen}
                                        className={
                                            moveToPenError
                                                ? 'border-danger'
                                                : ''
                                        }
                                    >
                                        <option value="">
                                            {
                                                MoveFromHospitalPenModalConstants[
                                                    language.value
                                                ].selectPen
                                            }
                                        </option>
                                        {!isCA10313On_HospitalPageUnassociatedHomePen &&
                                            addHomePenToMovePens && (
                                                <option value={homePenId}>
                                                    {homePenName}
                                                </option>
                                            )}
                                        {movePens?.map(pen => (
                                            <option
                                                key={pen.penId}
                                                value={pen.penId}
                                            >
                                                {pen.name}
                                                {'   '}
                                                {truncateRationName(
                                                    getUniqueRationNames(
                                                        pen?.currentRationNames ??
                                                            [],
                                                    ),
                                                )}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group className="col-sm-4">
                                    <Form.Label>
                                        {
                                            MoveFromHospitalPenModalConstants[
                                                language.value
                                            ].currentRation
                                        }
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="currentRation"
                                        value={getUniqueRationNames(
                                            currentRationNames,
                                        )}
                                        readOnly
                                    ></Form.Control>
                                </Form.Group>
                            </>
                        }
                    </Row>
                    <Row>
                        <Form.Group className="col-sm-12">
                            <Form.Label>
                                {isCA10313On_HospitalPageUnassociatedHomePen &&
                                    showHomePenMessage &&
                                    MoveFromHospitalPenModalConstants[
                                        language.value
                                    ].noHomePensMessage}
                            </Form.Label>
                        </Form.Group>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter>
                {showWarning ? (
                    <>
                        <div className="warning-message">
                            {MoveFromHospitalPenModalConstants[
                                language.value
                            ].rationMismatchMessage(
                                getUniqueRationNames(
                                    hospitalPenCurrentRationNames,
                                ),
                                getUniqueRationNames(currentRationNames),
                            )}
                        </div>
                        <button
                            type="button"
                            onClick={handleCancelMove}
                            className="secondary"
                        >
                            {
                                MoveFromHospitalPenModalConstants[
                                    language.value
                                ].cancel
                            }
                        </button>
                        <button type="button" onClick={handleConfirmMove}>
                            {
                                MoveFromHospitalPenModalConstants[
                                    language.value
                                ].yesMove
                            }
                        </button>
                    </>
                ) : (
                    <>
                        {isCACA13778On_HospitalPageSubmitBug ? (
                            <>
                                <ProtectedSpinnerButton
                                    className="secondary"
                                    disabled={isLoading}
                                    showSpinner={false}
                                    buttonContent={
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].cancel
                                    }
                                    onClick={() => closeModalHandler()}
                                />
                                <ProtectedSpinnerButton
                                    disabled={isLoading}
                                    showSpinner={isLoading}
                                    buttonContent={
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].move
                                    }
                                    onClick={handleMoveClick}
                                />
                            </>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    onClick={() => closeModalHandler()}
                                    className="secondary"
                                    data-bs-dismiss="modal"
                                >
                                    {
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].cancel
                                    }
                                </button>
                                <button type="button" onClick={handleMoveClick}>
                                    {
                                        MoveFromHospitalPenModalConstants[
                                            language.value
                                        ].move
                                    }
                                </button>
                            </>
                        )}
                    </>
                )}
            </ModalFooter>
        </Modal>
    );
};

export default MoveFromHospitalPenModal;
