import { currentDate_deprecated } from '../../helpers';
import { IMortalityInitialState } from '../../utils/Interface/MortalityReportInterface';

export const MORTALITY_INITIAL_STATE: IMortalityInitialState = {
    mortalityReportCSV: [],
    loading: false,
    canCallApi: true,
    sex: [],
    lots: [],
    riders: [],
    animalIds: [],
    usedAnimalTags: [],
    pens: [],
    mortalityDetails: {
        mortalityId: 0,
        mortalityDate: currentDate_deprecated('YYYY-MM-DD'),
        lotId: 0,
        animalId: 0,
        animalTag: '',
        eid: '',
        riderId: 0,
        mortalityReason: '',
        mortalityReasonId: 0,
        comments: '',
        necropsy: false,
        euthanasia: false,
        penId: 0,
    },
    mortalityReportsDetailsError: {
        animalTag: false,
        eid: false,
        mortalityDate: false,
        lotId: false,
        mortalityReasonId: false,
        penId: false,
    },
    generatedInvoiceCutoffDate: '',
    firstAllowedDateBasedOnCutoff: null,
    mortalityReasons: [],
    mortalityTreatments: [],
    filter: {
        lotId: 0,
        limit: 20,
        offset: 0,
        include:
            'MortalityReason,Lot,Animal,Pen,Animal.CattleTreatments,Animal.CattleTreatments.Diagnosis,Animal.CattleTreatments.TreatmentProtocol,Rider,Animal.CattleTreatments.Rider,AverageDaysOnFeed,Lot.Owner',
        mortalityFromDate: '',
        mortalityToDate: '',
        riderId: 0,
        riderLotId: 0,
        animalId: 0,
        animalTag: '',
        eid: '',
        riderDate: '',
        penId: 0,
        mortalityReasonId: 0,
        owner: '',
    },
    mortalityList: [],
    riderAnimalId: [],
    lotDetails: [],
    submitPending: false,
    backdatedInfoModal: { showModal: false, method: '' },
};
