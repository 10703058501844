import { rootApi as api } from '../rootApi';
export const addTagTypes = ['Animal'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: build => ({
            postApiFmByFlidAnimal: build.mutation<
                PostApiFmByFlidAnimalApiResponse,
                PostApiFmByFlidAnimalApiArg
            >({
                query: queryArg => ({
                    url: `/api/fm/${queryArg.flid}/animal`,
                    method: 'POST',
                    body: queryArg.feedlotManagerModelsMedicalCreateAnimalModel,
                }),
                invalidatesTags: ['Animal'],
            }),
            getApiFmByFlidAnimal: build.query<
                GetApiFmByFlidAnimalApiResponse,
                GetApiFmByFlidAnimalApiArg
            >({
                query: queryArg => ({
                    url: `/api/fm/${queryArg.flid}/animal`,
                    params: {
                        include: queryArg.include,
                        filter: queryArg.filter,
                        limit: queryArg.limit,
                        offset: queryArg.offset,
                        sort: queryArg.sort,
                        isRemoveDeadAnimal: queryArg.isRemoveDeadAnimal,
                        isRemoveRealizeAnimal: queryArg.isRemoveRealizeAnimal,
                        penType: queryArg.penType,
                    },
                }),
                providesTags: ['Animal'],
            }),
            putApiFmByFlidAnimalAndId: build.mutation<
                PutApiFmByFlidAnimalAndIdApiResponse,
                PutApiFmByFlidAnimalAndIdApiArg
            >({
                query: queryArg => ({
                    url: `/api/fm/${queryArg.flid}/animal/${queryArg.id}`,
                    method: 'PUT',
                    body: queryArg.feedlotManagerModelsMedicalUpdateAnimalModel,
                }),
                invalidatesTags: ['Animal'],
            }),
            getApiFmByFlidAnimalAndId: build.query<
                GetApiFmByFlidAnimalAndIdApiResponse,
                GetApiFmByFlidAnimalAndIdApiArg
            >({
                query: queryArg => ({
                    url: `/api/fm/${queryArg.flid}/animal/${queryArg.id}`,
                    params: {
                        include: queryArg.include,
                    },
                }),
                providesTags: ['Animal'],
            }),
            getApiFmByFlidAnimalOdata: build.query<
                GetApiFmByFlidAnimalOdataApiResponse,
                GetApiFmByFlidAnimalOdataApiArg
            >({
                query: queryArg => ({
                    url: `/api/fm/${queryArg.flid}/animal/odata`,
                    params: {
                        include: queryArg.include,
                        queryOptions: queryArg.queryOptions,
                    },
                }),
                providesTags: ['Animal'],
            }),
            postApiFmByFlidAnimalAndLotidEids: build.mutation<
                PostApiFmByFlidAnimalAndLotidEidsApiResponse,
                PostApiFmByFlidAnimalAndLotidEidsApiArg
            >({
                query: queryArg => ({
                    url: `/api/fm/${queryArg.flid}/animal/${queryArg.lotid}/eids`,
                    method: 'POST',
                    body: queryArg.body,
                    params: {
                        duplicateAction: queryArg.duplicateAction,
                    },
                }),
                invalidatesTags: ['Animal'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as baseAnimalApi };
export type PostApiFmByFlidAnimalApiResponse = /** status 200 OK */ number;
export type PostApiFmByFlidAnimalApiArg = {
    flid: number;
    feedlotManagerModelsMedicalCreateAnimalModel: FeedlotManagerModelsMedicalCreateAnimalModel;
};
export type GetApiFmByFlidAnimalApiResponse =
    /** status 200 OK */ FeedlotManagerModelsResponseModelsAnimalListApiResponse[];
export type GetApiFmByFlidAnimalApiArg = {
    flid: number;
    include?: string;
    filter?: string;
    limit?: number;
    offset?: number;
    sort?: string[];
    isRemoveDeadAnimal?: boolean;
    isRemoveRealizeAnimal?: boolean;
    penType?: FeedlotManagerCommonEnumsPenType;
};
export type PutApiFmByFlidAnimalAndIdApiResponse = unknown;
export type PutApiFmByFlidAnimalAndIdApiArg = {
    /** FeedLotId */
    flid: number;
    /** AnimalId */
    id: number;
    /** UpdateAnimalModel */
    feedlotManagerModelsMedicalUpdateAnimalModel: FeedlotManagerModelsMedicalUpdateAnimalModel;
};
export type GetApiFmByFlidAnimalAndIdApiResponse =
    /** status 200 OK */ FeedlotManagerModelsMedicalAnimalModelRead;
export type GetApiFmByFlidAnimalAndIdApiArg = {
    flid: number;
    id: number;
    include?: string;
};
export type GetApiFmByFlidAnimalOdataApiResponse =
    /** status 200 OK */ FeedlotManagerDataEntitiesMedicalAnimalRead[];
export type GetApiFmByFlidAnimalOdataApiArg = {
    flid: number;
    include?: string;
    queryOptions?: MicrosoftAspNetCoreODataQueryODataQueryOptions601FeedlotManagerDataEntitiesMedicalAnimal;
};
export type PostApiFmByFlidAnimalAndLotidEidsApiResponse = unknown;
export type PostApiFmByFlidAnimalAndLotidEidsApiArg = {
    /** Feedlot Id */
    flid: number;
    /** Default Lot Id - Used if Lot is not specified for EID row */
    lotid: number;
    /** How to handle duplicates */
    duplicateAction?: FeedlotManagerCommonEnumsEidCsvOnDuplicateAction;
    body: {
        /** CSV Upload File */
        csvUpload?: Blob;
    };
};
export type FeedlotManagerCommonEnumsAnimalStatus =
    | 'Untreated'
    | 'Treated'
    | 'Deceased'
    | 'Realized'
    | 'Clean'
    | 'Withdrawal'
    | 'HormoneClean'
    | 'HormoneWithdrawal'
    | 'AntibioticClean'
    | 'AntibioticWithdrawal'
    | 'Unknown';
export type FeedlotManagerModelsMedicalCreateAnimalModel = {
    animalTag?: string | null;
    isAlive?: boolean;
    lotId?: number;
    sexId?: number;
    status?: FeedlotManagerCommonEnumsAnimalStatus;
};
export type CcCommonApIsModelsAddressInfoModel = {
    address1?: string | null;
    address2?: string | null;
    addressId?: number;
    city?: string | null;
    coordinates?: string | null;
    country?: string | null;
    postalCode?: string | null;
    state?: string | null;
};
export type CcCommonApIsModelsLocationModel = {
    addressId?: number;
    addressInfo?: CcCommonApIsModelsAddressInfoModel;
    altContactNumber?: string | null;
    altEmail?: string | null;
    contactName?: string | null;
    contactNumber?: string | null;
    email?: string | null;
    id?: number;
    isActive?: boolean;
    moduleName?: string | null;
    name?: string | null;
};
export type FeedlotManagerModelsFmFeedlotModel = {
    feedLotId?: number;
    feedLotSubscriptionId?: number | null;
    isInactive?: boolean | null;
    label?: string | null;
    labelColorHex?: string | null;
    location?: CcCommonApIsModelsLocationModel;
    locationId?: number;
    manager?: string | null;
    yardageRate?: number | null;
};
export type FeedlotManagerCommonEnumsPenType =
    | 'Home'
    | 'Hospital'
    | 'Receiving'
    | 'Buller'
    | 'Railer';
export type FeedlotManagerServicesCacheContextEstimatedHeadTracker = {
    createdDateTime?: string;
    effectiveDate?: string | null;
    hdCount?: number;
    lotId?: number | null;
    lotName?: string | null;
    lotPenId?: number | null;
    penId?: number;
};
export type FeedlotManagerServicesCacheContextEstimatedHeadTrackerCacheContext =
    {
        estimatedHeads?:
            | FeedlotManagerServicesCacheContextEstimatedHeadTracker[]
            | null;
    };
export type FeedlotManagerModelsFeedRationTypeModel = {
    category?: string | null;
    rationTypeId?: number;
};
export type FeedlotManagerCommonEnumsFreightType = 'FOB' | 'Delivered';
export type FeedlotManagerCommonEnumsContractStatus =
    | 'Unknown'
    | 'Open'
    | 'Full'
    | 'Closed';
export type FeedlotManagerDataEntitiesFmFeedLotSubscription = {
    createdBy?: string | null;
    createdDatetime?: string;
    currentBillingAmountInCents?: number | null;
    feedLotSubscriptionId?: number;
    isBillingActive?: boolean;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    percentageDiscount?: number | null;
    subscriptionId?: string | null;
};
export type CcCommonDataSharedEntitiesAddressInfo = {
    address1?: string | null;
    address2?: string | null;
    addressId?: number;
    city?: string | null;
    coordinates?: string | null;
    country?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    postalCode?: string | null;
    state?: string | null;
};
export type CcCommonDataSharedEntitiesLocation = {
    addressId?: number;
    addressInfo?: CcCommonDataSharedEntitiesAddressInfo;
    altContactName?: string | null;
    altContactNumber?: string | null;
    altEmail?: string | null;
    contactName?: string | null;
    contactNumber?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    email?: string | null;
    id?: number;
    isActive?: boolean;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    moduleName?: string | null;
    name?: string | null;
};
export type FeedlotManagerDataEntitiesCattleType = {
    cattleTypeId?: number;
    createdBy?: string | null;
    createdDatetime?: string;
    description?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
};
export type CcCommonDataSharedEntitiesOwners = {
    addressId?: number;
    altContactNumber?: string | null;
    contactName?: string | null;
    contactNumber?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    email?: string | null;
    id?: number;
    isActive?: boolean;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    moduleName?: string | null;
    name?: string | null;
};
export type FeedlotManagerCommonEnumsBinType = 'Mixer' | 'Truck' | 'MixerTruck';
export type FeedlotManagerDataEntitiesFeedRationType = {
    category?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    rationTypeId?: number;
};
export type FeedlotManagerDataEntitiesFeedRationInternalName = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    ration?: FeedlotManagerDataEntitiesFeedRation;
    rationId?: number;
    rationInternalNameId?: number;
};
export type FeedlotManagerDataEntitiesFeedRationInternalNameRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    ration?: FeedlotManagerDataEntitiesFeedRation;
    rationId?: number;
    rationInternalNameId?: number;
};
export type FeedlotManagerDataEntitiesFeedRationVersion = {
    category?: FeedlotManagerDataEntitiesFeedRationType;
    categoryId?: number | null;
    comment?: string | null;
    corporatePrice?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    customPrice?: number | null;
    effectiveDate?: string;
    effectiveTime?: string;
    loadSize?: number | null;
    markUp?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    neg?: string | null;
    nem?: string | null;
    ration?: FeedlotManagerDataEntitiesFeedRation;
    rationId?: number;
    rationIngredients?: FeedlotManagerDataEntitiesFeedRationIngredient[] | null;
    rationName?: string | null;
    rationVersionId?: number;
    version?: number;
};
export type FeedlotManagerDataEntitiesFeedRationVersionRead = {
    category?: FeedlotManagerDataEntitiesFeedRationType;
    categoryId?: number | null;
    comment?: string | null;
    corporatePrice?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    customPrice?: number | null;
    effectiveDate?: string;
    effectiveTime?: string;
    loadSize?: number | null;
    markUp?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    neg?: string | null;
    nem?: string | null;
    ration?: FeedlotManagerDataEntitiesFeedRation;
    rationId?: number;
    rationIngredients?: FeedlotManagerDataEntitiesFeedRationIngredient[] | null;
    rationName?: string | null;
    rationVersionId?: number;
    version?: number;
};
export type FeedlotManagerDataEntitiesFeedRation = {
    category?: FeedlotManagerDataEntitiesFeedRationType;
    categoryId?: number | null;
    corporatePrice?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    customPrice?: number | null;
    effectiveDate?: string;
    effectiveTime?: string;
    entityCache?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    internalNames?: FeedlotManagerDataEntitiesFeedRationInternalName[] | null;
    loadSize?: number | null;
    markUp?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    neg?: string | null;
    nem?: string | null;
    rationId?: number;
    rationIngredients?: FeedlotManagerDataEntitiesFeedRationIngredient[] | null;
    rationVersion?: number;
    rationVersionId?: number;
    rationVersions?: FeedlotManagerDataEntitiesFeedRationVersion[] | null;
};
export type FeedlotManagerDataEntitiesFeedRationRead = {
    category?: FeedlotManagerDataEntitiesFeedRationType;
    categoryId?: number | null;
    corporatePrice?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    customPrice?: number | null;
    effectiveDate?: string;
    effectiveTime?: string;
    entityCache?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    internalNames?:
        | FeedlotManagerDataEntitiesFeedRationInternalNameRead[]
        | null;
    loadSize?: number | null;
    markUp?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    neg?: string | null;
    nem?: string | null;
    rationId?: number;
    rationIngredients?: FeedlotManagerDataEntitiesFeedRationIngredient[] | null;
    rationVersion?: number;
    rationVersionId?: number;
    rationVersions?: FeedlotManagerDataEntitiesFeedRationVersionRead[] | null;
};
export type FeedlotManagerDataEntitiesFeedRationIngredient = {
    amountPercentage?: number;
    commodity?: FeedlotManagerDataEntitiesFeedCommodity;
    commodityId?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    dryMatterPercentage?: number;
    ingredientLoad?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    ordinal?: number;
    ration?: FeedlotManagerDataEntitiesFeedRation;
    rationId?: number | null;
    rationVersion?: FeedlotManagerDataEntitiesFeedRationVersion;
    rationVersionId?: number | null;
};
export type FeedlotManagerDataEntitiesFeedRationIngredientRead = {
    amountPercentage?: number;
    commodity?: FeedlotManagerDataEntitiesFeedCommodity;
    commodityId?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    dryMatterPercentage?: number;
    ingredientLoad?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    ordinal?: number;
    ration?: FeedlotManagerDataEntitiesFeedRationRead;
    rationId?: number | null;
    rationVersion?: FeedlotManagerDataEntitiesFeedRationVersionRead;
    rationVersionId?: number | null;
};
export type FeedlotManagerDataEntitiesFeedIngredientLoadHistory = {
    amountLoaded?: number;
    createdBy?: string | null;
    createdDatetime?: string;
    feedTask?: FeedlotManagerDataEntitiesFeedFeedTask;
    feedTaskId?: number;
    ingredientLoadHistoryId?: number;
    loadEndTime?: string | null;
    loadStartTime?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    rationIngredient?: FeedlotManagerDataEntitiesFeedRationIngredient;
    rationIngredientId?: number;
};
export type FeedlotManagerDataEntitiesFeedIngredientLoadHistoryRead = {
    amountLoaded?: number;
    createdBy?: string | null;
    createdDatetime?: string;
    feedTask?: FeedlotManagerDataEntitiesFeedFeedTask;
    feedTaskId?: number;
    ingredientLoadHistoryId?: number;
    loadEndTime?: string | null;
    loadStartTime?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    rationIngredient?: FeedlotManagerDataEntitiesFeedRationIngredientRead;
    rationIngredientId?: number;
};
export type FeedlotManagerDataEntitiesFeedLoader = {
    active?: boolean;
    createdBy?: string | null;
    createdDatetime?: string;
    driver?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    loaderId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
};
export type FeedlotManagerDataEntitiesFeedLoaderRead = {
    active?: boolean;
    createdBy?: string | null;
    createdDatetime?: string;
    driver?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    loaderId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
};
export type FeedlotManagerDataEnumsFeedPenRationHistorySourceEnum =
    | 'MFE'
    | 'FeedingApp';
export type FeedlotManagerDataEnumsFeedBunkScoreEnum =
    | 'NoBunkScore'
    | 'Slick'
    | 'Crumbs'
    | '_50_100'
    | '_100_300'
    | '_300_500'
    | '_500_Plus';
export type FeedlotManagerDataEntitiesFeedScheduleType = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    scheduleTypeId?: number;
    tableName?: string | null;
    type?: string | null;
};
export type FeedlotManagerDataEntitiesFeedPenRationScheduleTemplate = {
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    penRationScheduleTemplateId?: number;
    scheduleType?: FeedlotManagerDataEntitiesFeedScheduleType;
    scheduleTypeId?: number | null;
    startingAFPerHd?: number;
    startingDryMatterPerHd?: number;
    tableId?: number;
};
export type FeedlotManagerDataEntitiesFeedPenRationScheduleTemplateRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    penRationScheduleTemplateId?: number;
    scheduleType?: FeedlotManagerDataEntitiesFeedScheduleType;
    scheduleTypeId?: number | null;
    startingAFPerHd?: number;
    startingDryMatterPerHd?: number;
    tableId?: number;
};
export type FeedlotManagerDataEntitiesFeedPenRationSchedule = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    penRationScheduleId?: number;
    penRationScheduleTemplate?: FeedlotManagerDataEntitiesFeedPenRationScheduleTemplate;
    penRationScheduleTemplateId?: number | null;
    scheduleType?: FeedlotManagerDataEntitiesFeedScheduleType;
    scheduleTypeId?: number | null;
    startingAFPerHd?: number;
    startingDryMatterPerHd?: number;
    tableId?: number;
};
export type FeedlotManagerDataEntitiesFeedFeedCall = {
    adg?: number;
    afChangePerHd?: number;
    afPerHd?: number;
    bunkScore?: FeedlotManagerDataEnumsFeedBunkScoreEnum;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentFeedingSchedule?: string | null;
    daysOnCurrentRation?: number | null;
    daysOnFeed?: number | null;
    dmChangePerHd?: number;
    dmPerHd?: number;
    endRation?: FeedlotManagerDataEntitiesFeedRation;
    endRationId?: number | null;
    endRationName?: string | null;
    endRationPercentage?: number;
    estimatedHdCount?: number | null;
    feedCallId?: number;
    feedDeliveryCache?: string | null;
    feedlotCreatedDate?: string;
    feedlotModifiedDate?: string;
    hayFeeding1?: number | null;
    hayFeeding2?: number | null;
    hdCount?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    noOfFeedingsBeforeShipment?: number | null;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number;
    penRationHistories?:
        | FeedlotManagerDataEntitiesFeedPenRationHistory[]
        | null;
    penRationSchedule?: FeedlotManagerDataEntitiesFeedPenRationSchedule;
    penRationScheduleId?: number | null;
    scheduledRationCount?: number | null;
    startRation?: FeedlotManagerDataEntitiesFeedRation;
    startRationId?: number | null;
    startRationName?: string | null;
    startRationPercentage?: number;
    totalCall?: number;
    wtPerDM?: number;
};
export type FeedlotManagerDataEntitiesFeedFeedCallRead = {
    adg?: number;
    afChangePerHd?: number;
    afPerHd?: number;
    bunkScore?: FeedlotManagerDataEnumsFeedBunkScoreEnum;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentFeedingSchedule?: string | null;
    daysOnCurrentRation?: number | null;
    daysOnFeed?: number | null;
    dmChangePerHd?: number;
    dmPerHd?: number;
    endRation?: FeedlotManagerDataEntitiesFeedRationRead;
    endRationId?: number | null;
    endRationName?: string | null;
    endRationPercentage?: number;
    estimatedHdCount?: number | null;
    feedCallId?: number;
    feedDeliveryCache?: string | null;
    feedlotCreatedDate?: string;
    feedlotModifiedDate?: string;
    hayFeeding1?: number | null;
    hayFeeding2?: number | null;
    hdCount?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    noOfFeedingsBeforeShipment?: number | null;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number;
    penRationHistories?:
        | FeedlotManagerDataEntitiesFeedPenRationHistory[]
        | null;
    penRationSchedule?: FeedlotManagerDataEntitiesFeedPenRationSchedule;
    penRationScheduleId?: number | null;
    scheduledRationCount?: number | null;
    startRation?: FeedlotManagerDataEntitiesFeedRationRead;
    startRationId?: number | null;
    startRationName?: string | null;
    startRationPercentage?: number;
    totalCall?: number;
    wtPerDM?: number;
};
export type FeedlotManagerDataEntitiesFeedFeedCallBase = {
    adg?: number;
    afChangePerHd?: number;
    afPerHd?: number;
    bunkScore?: FeedlotManagerDataEnumsFeedBunkScoreEnum;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentFeedingSchedule?: string | null;
    daysOnCurrentRation?: number | null;
    daysOnFeed?: number | null;
    dmChangePerHd?: number;
    dmPerHd?: number;
    estimatedHdCount?: number | null;
    feedCallId?: number;
    feedDeliveryCache?: string | null;
    feedlotCreatedDate?: string;
    feedlotModifiedDate?: string;
    hdCount?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number;
    penRationHistories?:
        | FeedlotManagerDataEntitiesFeedPenRationHistory[]
        | null;
    penRationSchedule?: FeedlotManagerDataEntitiesFeedPenRationSchedule;
    penRationScheduleId?: number | null;
    scheduledRationCount?: number | null;
    totalCall?: number;
    wtPerDM?: number;
};
export type FeedlotManagerDataEntitiesFeedFeedCallBaseRead = {
    adg?: number;
    afChangePerHd?: number;
    afPerHd?: number;
    bunkScore?: FeedlotManagerDataEnumsFeedBunkScoreEnum;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentFeedingSchedule?: string | null;
    daysOnCurrentRation?: number | null;
    daysOnFeed?: number | null;
    dmChangePerHd?: number;
    dmPerHd?: number;
    estimatedHdCount?: number | null;
    feedCallId?: number;
    feedDeliveryCache?: string | null;
    feedlotCreatedDate?: string;
    feedlotModifiedDate?: string;
    hdCount?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number;
    penRationHistories?:
        | FeedlotManagerDataEntitiesFeedPenRationHistory[]
        | null;
    penRationSchedule?: FeedlotManagerDataEntitiesFeedPenRationSchedule;
    penRationScheduleId?: number | null;
    scheduledRationCount?: number | null;
    totalCall?: number;
    wtPerDM?: number;
};
export type FeedlotManagerDataEntitiesFeedRationAllocation = {
    createdBy?: string | null;
    createdDatetime?: string;
    dailyPercentageChange?: number | null;
    feedingOrder?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    ration?: FeedlotManagerDataEntitiesFeedRation;
    rationAllocationId?: number;
    rationId?: number;
    rationPercentage?: number;
    rationScheduleSegment?: FeedlotManagerDataEntitiesFeedRationScheduleSegment;
    rationScheduleSegmentId?: number;
    rationVersion?: FeedlotManagerDataEntitiesFeedRationVersion;
    rationVersionId?: number | null;
};
export type FeedlotManagerDataEntitiesFeedRationAllocationRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    dailyPercentageChange?: number | null;
    feedingOrder?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    ration?: FeedlotManagerDataEntitiesFeedRationRead;
    rationAllocationId?: number;
    rationId?: number;
    rationPercentage?: number;
    rationScheduleSegment?: FeedlotManagerDataEntitiesFeedRationScheduleSegment;
    rationScheduleSegmentId?: number;
    rationVersion?: FeedlotManagerDataEntitiesFeedRationVersionRead;
    rationVersionId?: number | null;
};
export type FeedlotManagerDataEntitiesFeedSplitRationSegment = {
    createdBy?: string | null;
    createdDatetime?: string;
    dayDuration?: number;
    endRation?: FeedlotManagerDataEntitiesFeedRation;
    endRationId?: number | null;
    endRationIncrement?: number;
    feedings?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    splitRationSegmentId?: number;
    splitRationSegmentOrderNumber?: number | null;
    splitSchedule?: FeedlotManagerDataEntitiesFeedSplitSchedule;
    splitScheduleId?: number | null;
    startDate?: string;
    startRation?: FeedlotManagerDataEntitiesFeedRation;
    startRationId?: number | null;
    startRationPercentage?: number;
};
export type FeedlotManagerDataEntitiesFeedSplitRationSegmentRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    dayDuration?: number;
    endRation?: FeedlotManagerDataEntitiesFeedRationRead;
    endRationId?: number | null;
    endRationIncrement?: number;
    feedings?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    splitRationSegmentId?: number;
    splitRationSegmentOrderNumber?: number | null;
    splitSchedule?: FeedlotManagerDataEntitiesFeedSplitSchedule;
    splitScheduleId?: number | null;
    startDate?: string;
    startRation?: FeedlotManagerDataEntitiesFeedRationRead;
    startRationId?: number | null;
    startRationPercentage?: number;
};
export type FeedlotManagerDataEntitiesFeedSplitSchedule = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    rationScheduleSegments?:
        | FeedlotManagerDataEntitiesFeedRationScheduleSegment[]
        | null;
    splitRationSegments?:
        | FeedlotManagerDataEntitiesFeedSplitRationSegment[]
        | null;
    splitScheduleId?: number;
};
export type FeedlotManagerDataEntitiesFeedRationScheduleSegment = {
    createdBy?: string | null;
    createdDatetime?: string;
    dayDuration?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    noOfFeeding?: number;
    rationAllocations?: FeedlotManagerDataEntitiesFeedRationAllocation[] | null;
    rationScheduleSegmentId?: number;
    rationScheduleSegmentOrder?: number;
    splitSchedule?: FeedlotManagerDataEntitiesFeedSplitSchedule;
    splitScheduleId?: number | null;
    startDate?: string;
};
export type FeedlotManagerDataEntitiesFeedV2RationFeedingSnapshot = {
    activeRationVersion?: FeedlotManagerDataEntitiesFeedRationVersion;
    activeRationVersionId?: number;
    createdBy?: string | null;
    createdDatetime?: string;
    feedCall?: FeedlotManagerDataEntitiesFeedV2FeedCallV2;
    feedCallId?: number;
    feedingNumber?: number;
    feedingOrderNumber?: number;
    isComplete?: boolean;
    isRollOver?: boolean;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    rationDeliveredAmount?: number;
    rationFeedingCall?: number;
    rationFeedingSnapshotId?: number;
    rationId?: number;
    rationName?: string | null;
    rationPercentage?: number;
    rationScheduleSegment?: FeedlotManagerDataEntitiesFeedRationScheduleSegment;
    rationScheduleSegmentId?: number | null;
    splitRationSegment?: FeedlotManagerDataEntitiesFeedSplitRationSegment;
    splitRationSegmentId?: number | null;
};
export type FeedlotManagerCommonEnumsFeedCallStatus =
    | 'Assessment'
    | 'Configured'
    | 'Started'
    | 'Completed';
export type FeedlotManagerDataEntitiesFeedV2SublotSnapshot = {
    createdBy?: string | null;
    createdDatetime?: string;
    currentWeight?: number;
    estimatedHdCount?: number | null;
    feedCall?: FeedlotManagerDataEntitiesFeedV2FeedCallV2;
    feedCallId?: number;
    feedingNumberEnd?: number | null;
    feedingNumberStart?: number | null;
    headCount?: number;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotId?: number | null;
    lotPenId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    penId?: number | null;
    sublotSnapshotId?: number;
};
export type FeedlotManagerDataEntitiesFeedV2SublotSnapshotRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    currentWeight?: number;
    estimatedHdCount?: number | null;
    feedCall?: FeedlotManagerDataEntitiesFeedV2FeedCallV2;
    feedCallId?: number;
    feedingNumberEnd?: number | null;
    feedingNumberStart?: number | null;
    headCount?: number;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotId?: number | null;
    lotPenId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    penId?: number | null;
    sublotSnapshotId?: number;
};
export type FeedlotManagerDataEntitiesFeedV2FeedCallV2 = {
    adg?: number;
    afChangePerHd?: number;
    afPerHd?: number;
    bunkScore?: FeedlotManagerDataEnumsFeedBunkScoreEnum;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentFeedingNumber?: number;
    currentFeedingSchedule?: string | null;
    daysOnCurrentRation?: number | null;
    daysOnFeed?: number | null;
    dmChangePerHd?: number;
    dmPerHd?: number;
    effectiveDate?: string;
    estimatedHdCount?: number | null;
    feedCallId?: number;
    feedDeliveryCache?: string | null;
    feedlotCreatedDate?: string;
    feedlotModifiedDate?: string;
    hdCount?: number;
    isLocked?: boolean;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number;
    penRationHistories?:
        | FeedlotManagerDataEntitiesFeedPenRationHistory[]
        | null;
    penRationSchedule?: FeedlotManagerDataEntitiesFeedPenRationSchedule;
    penRationScheduleId?: number | null;
    rationFeedingSnapshots?:
        | FeedlotManagerDataEntitiesFeedV2RationFeedingSnapshot[]
        | null;
    scheduledRationCount?: number | null;
    status?: FeedlotManagerCommonEnumsFeedCallStatus;
    sublotSnapshots?: FeedlotManagerDataEntitiesFeedV2SublotSnapshot[] | null;
    totalCall?: number;
    wtPerDM?: number;
};
export type FeedlotManagerDataEntitiesFeedV2FeedCallV2Read = {
    adg?: number;
    afChangePerHd?: number;
    afPerHd?: number;
    bunkScore?: FeedlotManagerDataEnumsFeedBunkScoreEnum;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentFeedingNumber?: number;
    currentFeedingSchedule?: string | null;
    daysOnCurrentRation?: number | null;
    daysOnFeed?: number | null;
    dmChangePerHd?: number;
    dmPerHd?: number;
    effectiveDate?: string;
    estimatedHdCount?: number | null;
    feedCallId?: number;
    feedDeliveryCache?: string | null;
    feedlotCreatedDate?: string;
    feedlotModifiedDate?: string;
    hdCount?: number;
    isLocked?: boolean;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number;
    penRationHistories?:
        | FeedlotManagerDataEntitiesFeedPenRationHistory[]
        | null;
    penRationSchedule?: FeedlotManagerDataEntitiesFeedPenRationSchedule;
    penRationScheduleId?: number | null;
    rationFeedingSnapshots?:
        | FeedlotManagerDataEntitiesFeedV2RationFeedingSnapshot[]
        | null;
    scheduledRationCount?: number | null;
    status?: FeedlotManagerCommonEnumsFeedCallStatus;
    sublotSnapshots?:
        | FeedlotManagerDataEntitiesFeedV2SublotSnapshotRead[]
        | null;
    totalCall?: number;
    wtPerDM?: number;
};
export type FeedlotManagerDataEntitiesFeedPenRationHistory = {
    afChangePerHd?: number | null;
    afHd?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    createdSource?: FeedlotManagerDataEnumsFeedPenRationHistorySourceEnum;
    deliveredAmount?: number;
    deliveryWithinVariance?: boolean | null;
    dmChangePerHd?: number | null;
    dmHd?: number | null;
    driver?: string | null;
    effectiveDate?: string;
    feedCall?: FeedlotManagerDataEntitiesFeedFeedCall;
    feedCallBase?: FeedlotManagerDataEntitiesFeedFeedCallBase;
    feedCallId?: number | null;
    feedCallV2?: FeedlotManagerDataEntitiesFeedV2FeedCallV2;
    feedCallV2Id?: number | null;
    feedDeliveryCache?: string | null;
    feedingNumber?: number | null;
    feedingPortionAmount?: number;
    feedTask?: FeedlotManagerDataEntitiesFeedFeedTask;
    feedTaskId?: number | null;
    hdCount?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    modifiedSource?: FeedlotManagerDataEnumsFeedPenRationHistorySourceEnum;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number | null;
    penRationHistoryId?: number;
    penRationSchedule?: FeedlotManagerDataEntitiesFeedPenRationSchedule;
    penRationScheduleId?: number | null;
    ration?: FeedlotManagerDataEntitiesFeedRation;
    rationFeedAmount?: number;
    rationId?: number | null;
    rationName?: string | null;
    rationVersionId?: number;
    registeredDeliveredAmount?: number | null;
    scheduledAmount?: number;
    totalPenFeedCall?: number;
    truck?: string | null;
    wtdm?: number | null;
};
export type FeedlotManagerDataEntitiesFeedPenRationHistoryRead = {
    afChangePerHd?: number | null;
    afHd?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    createdSource?: FeedlotManagerDataEnumsFeedPenRationHistorySourceEnum;
    deliveredAmount?: number;
    deliveryWithinVariance?: boolean | null;
    dmChangePerHd?: number | null;
    dmHd?: number | null;
    driver?: string | null;
    effectiveDate?: string;
    feedCall?: FeedlotManagerDataEntitiesFeedFeedCallRead;
    feedCallBase?: FeedlotManagerDataEntitiesFeedFeedCallBaseRead;
    feedCallBaseId?: number | null;
    feedCallId?: number | null;
    feedCallV2?: FeedlotManagerDataEntitiesFeedV2FeedCallV2Read;
    feedCallV2Id?: number | null;
    feedDeliveryCache?: string | null;
    feedingNumber?: number | null;
    feedingPortionAmount?: number;
    feedTask?: FeedlotManagerDataEntitiesFeedFeedTask;
    feedTaskId?: number | null;
    hdCount?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    modifiedSource?: FeedlotManagerDataEnumsFeedPenRationHistorySourceEnum;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number | null;
    penRationHistoryId?: number;
    penRationSchedule?: FeedlotManagerDataEntitiesFeedPenRationSchedule;
    penRationScheduleId?: number | null;
    ration?: FeedlotManagerDataEntitiesFeedRationRead;
    rationFeedAmount?: number;
    rationId?: number | null;
    rationName?: string | null;
    rationVersionId?: number;
    registeredDeliveredAmount?: number | null;
    scheduledAmount?: number;
    totalPenFeedCall?: number;
    truck?: string | null;
    wtdm?: number | null;
};
export type FeedlotManagerCommonEnumsFeedTaskStatus =
    | 'Initializing'
    | 'Ready'
    | 'LoadRationStart'
    | 'LoadRationComplete'
    | 'MixRationStart'
    | 'MixRationComplete'
    | 'PickupRationStart'
    | 'PickupRationComplete'
    | 'RationDeliveryStart'
    | 'RationDeliveryComplete'
    | 'RationDeliveryBreakdown';
export type FeedlotManagerDataEntitiesFeedFeedRoute = {
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    feedRouteId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    ration?: FeedlotManagerDataEntitiesFeedRation;
    rationId?: number | null;
    subRoutes?: FeedlotManagerDataEntitiesFeedSubRoute[] | null;
};
export type FeedlotManagerDataEntitiesFeedFeedRouteRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    feedRouteId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    ration?: FeedlotManagerDataEntitiesFeedRationRead;
    rationId?: number | null;
    subRoutes?: FeedlotManagerDataEntitiesFeedSubRoute[] | null;
};
export type FeedlotManagerDataEntitiesFeedSubRoute = {
    createdBy?: string | null;
    createdDatetime?: string;
    feedRoute?: FeedlotManagerDataEntitiesFeedFeedRoute;
    feedRouteId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    penId?: number | null;
    penStart?: FeedlotManagerDataEntitiesPen;
    subRouteId?: number;
    subRouteName?: string | null;
};
export type FeedlotManagerDataEntitiesFeedSubRouteRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    feedRoute?: FeedlotManagerDataEntitiesFeedFeedRouteRead;
    feedRouteId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    penId?: number | null;
    penStart?: FeedlotManagerDataEntitiesPen;
    subRouteId?: number;
    subRouteName?: string | null;
};
export type FeedlotManagerCommonEnumsTruckType = 'Truck' | 'MixerTruck';
export type FeedlotManagerDataEntitiesFeedTruck = {
    active?: boolean;
    capacity?: number;
    createdBy?: string | null;
    createdDatetime?: string;
    driver?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    truckId?: number;
    type?: FeedlotManagerCommonEnumsTruckType;
};
export type FeedlotManagerDataEntitiesFeedTruckRead = {
    active?: boolean;
    capacity?: number;
    createdBy?: string | null;
    createdDatetime?: string;
    driver?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    truckId?: number;
    type?: FeedlotManagerCommonEnumsTruckType;
};
export type FeedlotManagerDataEntitiesFeedFeedTask = {
    binType?: FeedlotManagerCommonEnumsBinType;
    binTypeId?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentIngredientId?: number | null;
    currentIngredientReferenceStartWeight?: number | null;
    currentIngredientTargetAmount?: number | null;
    currentLoadWeight?: number | null;
    effectiveDate?: string;
    feedDeliveryCache?: string | null;
    feedingNumber?: number | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedlotCreatedDate?: string;
    feedlotFinishTime?: string | null;
    feedLotId?: number;
    feedlotModifiedDate?: string;
    feedlotStartTime?: string | null;
    feedTaskId?: number;
    finishTime?: string | null;
    ingredientLoads?:
        | FeedlotManagerDataEntitiesFeedIngredientLoadHistory[]
        | null;
    initMutex?: number;
    isFeedTaskRecalculated?: boolean;
    loader?: FeedlotManagerDataEntitiesFeedLoader;
    loaderDriver?: string | null;
    loaderId?: number | null;
    loadNumber?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    penFeedTasks?: FeedlotManagerDataEntitiesFeedPenRationHistory[] | null;
    ration?: FeedlotManagerDataEntitiesFeedRation;
    rationDeliveredCallAmount?: number | null;
    rationId?: number | null;
    rationName?: string | null;
    remainingFeedingCallAmount?: number | null;
    remainingFeedingLoadAmount?: number | null;
    rollOverFeedingNumber?: number | null;
    rowVersion?: string | null;
    scheduledLoadAmount?: number | null;
    scheduledPens?: number;
    startTime?: string | null;
    status?: FeedlotManagerCommonEnumsFeedTaskStatus;
    subRoute?: FeedlotManagerDataEntitiesFeedSubRoute;
    subRouteId?: number | null;
    totalFeedingCallAmount?: number | null;
    totalLoadAmount?: number | null;
    truck?: FeedlotManagerDataEntitiesFeedTruck;
    truckDriver?: string | null;
    truckId?: number | null;
};
export type FeedlotManagerDataEntitiesFeedFeedTaskRead = {
    binType?: FeedlotManagerCommonEnumsBinType;
    binTypeId?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentIngredientId?: number | null;
    currentIngredientReferenceStartWeight?: number | null;
    currentIngredientTargetAmount?: number | null;
    currentLoadWeight?: number | null;
    effectiveDate?: string;
    feedDeliveryCache?: string | null;
    feedingNumber?: number | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedlotCreatedDate?: string;
    feedlotFinishTime?: string | null;
    feedLotId?: number;
    feedlotModifiedDate?: string;
    feedlotStartTime?: string | null;
    feedTaskId?: number;
    finishTime?: string | null;
    ingredientLoads?:
        | FeedlotManagerDataEntitiesFeedIngredientLoadHistoryRead[]
        | null;
    initMutex?: number;
    isFeedTaskRecalculated?: boolean;
    loader?: FeedlotManagerDataEntitiesFeedLoaderRead;
    loaderDriver?: string | null;
    loaderId?: number | null;
    loadNumber?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    penFeedTasks?: FeedlotManagerDataEntitiesFeedPenRationHistoryRead[] | null;
    ration?: FeedlotManagerDataEntitiesFeedRationRead;
    rationDeliveredCallAmount?: number | null;
    rationId?: number | null;
    rationName?: string | null;
    remainingFeedingCallAmount?: number | null;
    remainingFeedingLoadAmount?: number | null;
    rollOverFeedingNumber?: number | null;
    rowVersion?: string | null;
    scheduledLoadAmount?: number | null;
    scheduledPens?: number;
    startTime?: string | null;
    status?: FeedlotManagerCommonEnumsFeedTaskStatus;
    subRoute?: FeedlotManagerDataEntitiesFeedSubRouteRead;
    subRouteId?: number | null;
    totalFeedingCallAmount?: number | null;
    totalLoadAmount?: number | null;
    truck?: FeedlotManagerDataEntitiesFeedTruckRead;
    truckDriver?: string | null;
    truckId?: number | null;
};
export type FeedlotManagerDataEntitiesPen = {
    active?: boolean;
    createdBy?: string | null;
    createdDatetime?: string;
    currentDayRations?: string | null;
    currentFeedingAmount?: number | null;
    currentFeedingNumber?: number | null;
    currentRation?: string | null;
    currentRationAmount?: number | null;
    currentRationId?: number;
    currentTotalCall?: number | null;
    feedDeliveryCache?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    feedTask?: FeedlotManagerDataEntitiesFeedFeedTask;
    feedTaskId?: number | null;
    hdCount?: number | null;
    headCapacity?: number | null;
    isRationChangeConfirmed?: boolean | null;
    kind?: FeedlotManagerCommonEnumsPenType;
    lots?: FeedlotManagerDataEntitiesFmLot[] | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    penId?: number;
    penRationHistory?: FeedlotManagerDataEntitiesFeedPenRationHistory[] | null;
    penRationSchedule?: FeedlotManagerDataEntitiesFeedPenRationSchedule;
    penRationScheduleId?: number | null;
};
export type FeedlotManagerDataEntitiesPenRead = {
    active?: boolean;
    createdBy?: string | null;
    createdDatetime?: string;
    currentDayRations?: string | null;
    currentFeedingAmount?: number | null;
    currentFeedingNumber?: number | null;
    currentRation?: string | null;
    currentRationAmount?: number | null;
    currentRationId?: number;
    currentTotalCall?: number | null;
    feedDeliveryCache?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    feedTask?: FeedlotManagerDataEntitiesFeedFeedTaskRead;
    feedTaskId?: number | null;
    hdCount?: number | null;
    headCapacity?: number | null;
    isRationChangeConfirmed?: boolean | null;
    kind?: FeedlotManagerCommonEnumsPenType;
    lots?: FeedlotManagerDataEntitiesFmLot[] | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    penId?: number;
    penRationHistory?:
        | FeedlotManagerDataEntitiesFeedPenRationHistoryRead[]
        | null;
    penRationSchedule?: FeedlotManagerDataEntitiesFeedPenRationSchedule;
    penRationScheduleId?: number | null;
};
export type FeedlotManagerDataEnumsMedicalCategory =
    | 'Both'
    | 'Treatment'
    | 'Processing'
    | 'Unit';
export type FeedlotManagerDataEntitiesMedicalAnimalCacheModel = {
    tzOffset?: number | null;
};
export type FeedlotManagerDataEntitiesMedicalMortalityReason = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    reason?: string | null;
    reasonId?: number;
};
export type FeedlotManagerDataEntitiesMedicalRider = {
    active?: boolean;
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    riderId?: number;
};
export type FeedlotManagerDataEntitiesMedicalRiderRead = {
    active?: boolean;
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    riderId?: number;
};
export type FeedlotManagerDataEntitiesMedicalMortality = {
    adfValue?: number | null;
    animal?: FeedlotManagerDataEntitiesMedicalAnimal;
    animalId?: number;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    date?: string;
    deadCount?: number | null;
    euthanasia?: boolean | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    isDeleted?: boolean;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    mortalityID?: number;
    mortalityReason?: FeedlotManagerDataEntitiesMedicalMortalityReason;
    mortalityReasonId?: number;
    necropsyDone?: boolean | null;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number | null;
    rider?: FeedlotManagerDataEntitiesMedicalRider;
    riderId?: number | null;
};
export type FeedlotManagerDataEntitiesMedicalMortalityRead = {
    adfValue?: number | null;
    animal?: FeedlotManagerDataEntitiesMedicalAnimal;
    animalId?: number;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    date?: string;
    deadCount?: number | null;
    euthanasia?: boolean | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    isDeleted?: boolean;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    mortalityID?: number;
    mortalityReason?: FeedlotManagerDataEntitiesMedicalMortalityReason;
    mortalityReasonId?: number;
    necropsyDone?: boolean | null;
    pen?: FeedlotManagerDataEntitiesPenRead;
    penId?: number | null;
    rider?: FeedlotManagerDataEntitiesMedicalRiderRead;
    riderId?: number | null;
};
export type FeedlotManagerDataEntitiesMedicalCattleDiedReason = {
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    reasonId?: number;
    reasonName?: string | null;
};
export type FeedlotManagerDataEntitiesMedicalCattleDiedReasonRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    reasonId?: number;
    reasonName?: string | null;
};
export type FeedlotManagerDataEntitiesRealizerShipment = {
    brand?: string | null;
    brandSlipNumber?: string | null;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    date?: string;
    destination?: CcCommonDataSharedEntitiesLocation;
    destinationId?: number | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    hdCount?: number;
    isCompleted?: boolean;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    payWeight?: number | null;
    realizers?: FeedlotManagerDataEntitiesRealizer[] | null;
    realizerShipmentId?: number;
    totalRevenue?: number | null;
};
export type FeedlotManagerDataEntitiesRealizerShipmentRead = {
    brand?: string | null;
    brandSlipNumber?: string | null;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    date?: string;
    destination?: CcCommonDataSharedEntitiesLocation;
    destinationId?: number | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    hdCount?: number;
    isCompleted?: boolean;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    payWeight?: number | null;
    realizers?: FeedlotManagerDataEntitiesRealizer[] | null;
    realizerShipmentId?: number;
    totalRevenue?: number | null;
};
export type FeedlotManagerDataEntitiesRealizer = {
    animal?: FeedlotManagerDataEntitiesMedicalAnimal;
    animalId?: number;
    cattleDiedReason?: FeedlotManagerDataEntitiesMedicalCattleDiedReason;
    cattleDiedReasonId?: number | null;
    cattleRevenue?: number | null;
    condemned?: boolean;
    createdBy?: string | null;
    createdDatetime?: string;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    paymentDate?: string | null;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number | null;
    realizerId?: number;
    realizerShipment?: FeedlotManagerDataEntitiesRealizerShipment;
    realizerShipmentId?: number | null;
    weight?: number | null;
};
export type FeedlotManagerDataEntitiesRealizerRead = {
    animal?: FeedlotManagerDataEntitiesMedicalAnimal;
    animalId?: number;
    cattleDiedReason?: FeedlotManagerDataEntitiesMedicalCattleDiedReasonRead;
    cattleDiedReasonId?: number | null;
    cattleRevenue?: number | null;
    condemned?: boolean;
    createdBy?: string | null;
    createdDatetime?: string;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    paymentDate?: string | null;
    pen?: FeedlotManagerDataEntitiesPenRead;
    penId?: number | null;
    realizerId?: number;
    realizerShipment?: FeedlotManagerDataEntitiesRealizerShipmentRead;
    realizerShipmentId?: number | null;
    weight?: number | null;
};
export type FeedlotManagerDataEntitiesSex = {
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    sexId?: number;
};
export type FeedlotManagerDataEntitiesMedicalAnimal = {
    animalId?: number;
    animalTag?: string | null;
    cacheModel?: FeedlotManagerDataEntitiesMedicalAnimalCacheModel;
    cattleTreatments?:
        | FeedlotManagerDataEntitiesMedicalCattleTreatment[]
        | null;
    cleanDate?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentPen?: FeedlotManagerDataEntitiesPen;
    currentPenId?: number | null;
    eid?: string | null;
    entityCache?: string | null;
    homePen?: FeedlotManagerDataEntitiesPen;
    homePenId?: number | null;
    isAlive?: boolean;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    mortalities?: FeedlotManagerDataEntitiesMedicalMortality[] | null;
    previousPen?: FeedlotManagerDataEntitiesPen;
    previousPenId?: number | null;
    realizers?: FeedlotManagerDataEntitiesRealizer[] | null;
    sex?: FeedlotManagerDataEntitiesSex;
    sexId?: number;
    status?: FeedlotManagerCommonEnumsAnimalStatus;
};
export type FeedlotManagerDataEntitiesMedicalAnimalRead = {
    animalId?: number;
    animalTag?: string | null;
    cacheModel?: FeedlotManagerDataEntitiesMedicalAnimalCacheModel;
    cattleTreatments?:
        | FeedlotManagerDataEntitiesMedicalCattleTreatment[]
        | null;
    cleanDate?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentPen?: FeedlotManagerDataEntitiesPenRead;
    currentPenId?: number | null;
    eid?: string | null;
    entityCache?: string | null;
    homePen?: FeedlotManagerDataEntitiesPenRead;
    homePenId?: number | null;
    isAlive?: boolean;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    mortalities?: FeedlotManagerDataEntitiesMedicalMortalityRead[] | null;
    previousPen?: FeedlotManagerDataEntitiesPenRead;
    previousPenId?: number | null;
    realizers?: FeedlotManagerDataEntitiesRealizerRead[] | null;
    sex?: FeedlotManagerDataEntitiesSex;
    sexId?: number;
    status?: FeedlotManagerCommonEnumsAnimalStatus;
};
export type FeedlotManagerDataEntitiesMedicalTreatmentProtocol = {
    createdBy?: string | null;
    createdDatetime?: string;
    diagnosis?: FeedlotManagerDataEntitiesMedicalDiagnosis;
    diagnosisId: number;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId: number;
    medicalItems: FeedlotManagerDataEntitiesMedicalMedicalItem[];
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name: string;
    treatmentProtocolId?: number;
};
export type FeedlotManagerDataEntitiesMedicalTreatmentProtocolRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    diagnosis?: FeedlotManagerDataEntitiesMedicalDiagnosis;
    diagnosisId: number;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId: number;
    medicalItems: FeedlotManagerDataEntitiesMedicalMedicalItem[];
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name: string;
    treatmentProtocolId?: number;
};
export type FeedlotManagerDataEntitiesMedicalDiagnosis = {
    createdBy?: string | null;
    createdDatetime?: string;
    diagnosisId?: number;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name: string;
    treatmentProtocols?:
        | FeedlotManagerDataEntitiesMedicalTreatmentProtocol[]
        | null;
};
export type FeedlotManagerDataEntitiesMedicalDiagnosisRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    diagnosisId?: number;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name: string;
    treatmentProtocols?:
        | FeedlotManagerDataEntitiesMedicalTreatmentProtocolRead[]
        | null;
};
export type FeedlotManagerDataEnumsMedicalSeverity =
    | 'Healthy'
    | 'Mild'
    | 'Moderate'
    | 'Severe'
    | 'VerySevere';
export type FeedlotManagerDataEntitiesMedicalTech = {
    active?: boolean;
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    techId?: number;
};
export type FeedlotManagerDataEntitiesMedicalTechRead = {
    active?: boolean;
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    techId?: number;
};
export type FeedlotManagerDataEntitiesMedicalCattleTreatment = {
    adfValue?: number | null;
    animal?: FeedlotManagerDataEntitiesMedicalAnimal;
    animalId?: number;
    cattleTreatmentId?: number;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentPen?: FeedlotManagerDataEntitiesPen;
    currentPenId?: number | null;
    currentWeight?: number;
    date?: string;
    diagnosis?: FeedlotManagerDataEntitiesMedicalDiagnosis;
    diagnosisId?: number;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    homePen?: FeedlotManagerDataEntitiesPen;
    homePenId?: number | null;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotAverageCurrentWeight?: number;
    lotId?: number;
    medicalItems?: FeedlotManagerDataEntitiesMedicalMedicalItem[] | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    movePen?: FeedlotManagerDataEntitiesPen;
    movePenId?: number | null;
    rider?: FeedlotManagerDataEntitiesMedicalRider;
    riderId?: number | null;
    severity?: FeedlotManagerDataEnumsMedicalSeverity;
    tech?: FeedlotManagerDataEntitiesMedicalTech;
    techId?: number | null;
    temperature?: string | null;
    treatmentProtocol?: FeedlotManagerDataEntitiesMedicalTreatmentProtocol;
    treatmentProtocolId?: number;
};
export type FeedlotManagerDataEntitiesMedicalCattleTreatmentRead = {
    adfValue?: number | null;
    animal?: FeedlotManagerDataEntitiesMedicalAnimalRead;
    animalId?: number;
    cattleTreatmentId?: number;
    comments?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    currentPen?: FeedlotManagerDataEntitiesPenRead;
    currentPenId?: number | null;
    currentWeight?: number;
    date?: string;
    diagnosis?: FeedlotManagerDataEntitiesMedicalDiagnosisRead;
    diagnosisId?: number;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    homePen?: FeedlotManagerDataEntitiesPenRead;
    homePenId?: number | null;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotAverageCurrentWeight?: number;
    lotId?: number;
    medicalItems?: FeedlotManagerDataEntitiesMedicalMedicalItem[] | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    movePen?: FeedlotManagerDataEntitiesPenRead;
    movePenId?: number | null;
    rider?: FeedlotManagerDataEntitiesMedicalRiderRead;
    riderId?: number | null;
    severity?: FeedlotManagerDataEnumsMedicalSeverity;
    tech?: FeedlotManagerDataEntitiesMedicalTechRead;
    techId?: number | null;
    temperature?: string | null;
    treatmentProtocol?: FeedlotManagerDataEntitiesMedicalTreatmentProtocolRead;
    treatmentProtocolId?: number;
};
export type FeedlotManagerDataEnumsMedicalDosageType =
    | 'CC'
    | 'Dose'
    | 'Head'
    | 'Unit';
export type FeedlotManagerDataEntitiesMedicalMedicalItemSku = {
    amount: number;
    cost: number;
    createdBy?: string | null;
    createdDatetime?: string;
    isActive: boolean;
    markup: number;
    medicalItem?: FeedlotManagerDataEntitiesMedicalMedicalItem;
    medicalItemId: number;
    medicalItemSkuId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    sku: string;
};
export type FeedlotManagerDataEntitiesMedicalMedicalItemSkuRead = {
    amount: number;
    cost: number;
    createdBy?: string | null;
    createdDatetime?: string;
    isActive: boolean;
    markup: number;
    medicalItem?: FeedlotManagerDataEntitiesMedicalMedicalItem;
    medicalItemId: number;
    medicalItemSkuId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    sku: string;
};
export type FeedlotManagerDataEnumsMedicalSubCategory =
    | 'Medicine'
    | 'Other'
    | 'Vaccine'
    | 'Parasitic'
    | 'Implant'
    | 'Labor'
    | 'Unit'
    | 'Antibiotic'
    | 'Hormone';
export type FeedlotManagerDataEntitiesMedicalMedicalItemVersion = {
    category: FeedlotManagerDataEnumsMedicalCategory;
    cost: number;
    createdBy?: string | null;
    createdDatetime?: string;
    dosageRate: number;
    dosageType: FeedlotManagerDataEnumsMedicalDosageType;
    inEffect: string;
    markup: number;
    medicalItem?: FeedlotManagerDataEntitiesMedicalMedicalItem;
    medicalItemId: number;
    medicalItemVersionId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    subCategory: FeedlotManagerDataEnumsMedicalSubCategory;
    version?: number;
    withdrawal: number;
};
export type FeedlotManagerDataEntitiesMedicalMedicalItemVersionRead = {
    category: FeedlotManagerDataEnumsMedicalCategory;
    cost: number;
    createdBy?: string | null;
    createdDatetime?: string;
    dosageRate: number;
    dosageType: FeedlotManagerDataEnumsMedicalDosageType;
    inEffect: string;
    markup: number;
    medicalItem?: FeedlotManagerDataEntitiesMedicalMedicalItem;
    medicalItemId: number;
    medicalItemVersionId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    subCategory: FeedlotManagerDataEnumsMedicalSubCategory;
    version?: number;
    withdrawal: number;
};
export type FeedlotManagerDataEntitiesMedicalMedicalLot = {
    latestDate?: string | null;
    lotAmount?: number;
    medicalItemId?: number;
    medicalLotId?: string | null;
};
export type FeedlotManagerDataEntitiesMedicalProcessingProtocol = {
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId: number;
    medicalItems: FeedlotManagerDataEntitiesMedicalMedicalItem[];
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name: string;
    processingProtocolId?: number;
};
export type FeedlotManagerDataEntitiesMedicalProcessingProtocolRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId: number;
    medicalItems: FeedlotManagerDataEntitiesMedicalMedicalItem[];
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name: string;
    processingProtocolId?: number;
};
export type FeedlotManagerDataEntitiesMedicalMedicalItem = {
    category: FeedlotManagerDataEnumsMedicalCategory;
    cattleTreatments?:
        | FeedlotManagerDataEntitiesMedicalCattleTreatment[]
        | null;
    createdBy?: string | null;
    createdDatetime?: string;
    date?: string | null;
    dosageRate: number;
    dosageType: FeedlotManagerDataEnumsMedicalDosageType;
    entityCache?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId: number;
    inEffect: string;
    medicalItemId?: number;
    medicalItemSku?: FeedlotManagerDataEntitiesMedicalMedicalItemSku[] | null;
    medicalItemVersions?:
        | FeedlotManagerDataEntitiesMedicalMedicalItemVersion[]
        | null;
    medicalLot?: FeedlotManagerDataEntitiesMedicalMedicalLot[] | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name: string;
    processingProtocols?:
        | FeedlotManagerDataEntitiesMedicalProcessingProtocol[]
        | null;
    subCategory: FeedlotManagerDataEnumsMedicalSubCategory;
    treatmentProtocols?:
        | FeedlotManagerDataEntitiesMedicalTreatmentProtocol[]
        | null;
    version?: number;
    versionId?: number | null;
    withdrawal: number;
};
export type FeedlotManagerDataEntitiesMedicalMedicalItemRead = {
    category: FeedlotManagerDataEnumsMedicalCategory;
    cattleTreatments?:
        | FeedlotManagerDataEntitiesMedicalCattleTreatmentRead[]
        | null;
    createdBy?: string | null;
    createdDatetime?: string;
    date?: string | null;
    dosageRate: number;
    dosageType: FeedlotManagerDataEnumsMedicalDosageType;
    entityCache?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId: number;
    inEffect: string;
    medicalItemId?: number;
    medicalItemSku?:
        | FeedlotManagerDataEntitiesMedicalMedicalItemSkuRead[]
        | null;
    medicalItemVersions?:
        | FeedlotManagerDataEntitiesMedicalMedicalItemVersionRead[]
        | null;
    medicalLot?: FeedlotManagerDataEntitiesMedicalMedicalLot[] | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name: string;
    processingProtocols?:
        | FeedlotManagerDataEntitiesMedicalProcessingProtocolRead[]
        | null;
    subCategory: FeedlotManagerDataEnumsMedicalSubCategory;
    treatmentProtocols?:
        | FeedlotManagerDataEntitiesMedicalTreatmentProtocolRead[]
        | null;
    version?: number;
    versionId?: number | null;
    withdrawal: number;
};
export type FeedlotManagerDataEntitiesMedicalProcessingMedicalItem = {
    actualDose?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    hdCountTreated?: number;
    medicalItem?: FeedlotManagerDataEntitiesMedicalMedicalItem;
    medicalItemId?: number;
    medicalLotId?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    processingMedicalItemId?: number;
    processingWorkset?: FeedlotManagerDataEntitiesMedicalProcessingWorkset;
    processingWorksetId?: number;
};
export type FeedlotManagerDataEntitiesMedicalProcessingMedicalItemRead = {
    actualDose?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    hdCountTreated?: number;
    medicalItem?: FeedlotManagerDataEntitiesMedicalMedicalItemRead;
    medicalItemId?: number;
    medicalLotId?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    processingMedicalItemId?: number;
    processingWorkset?: FeedlotManagerDataEntitiesMedicalProcessingWorkset;
    processingWorksetId?: number;
};
export type FeedlotManagerDataEntitiesMedicalProcessingPen = {
    createdBy?: string | null;
    createdDatetime?: string;
    hdCount?: number;
    hdCountProcessed?: number;
    hdCountToProcess?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    pen?: FeedlotManagerDataEntitiesPen;
    penId?: number;
    processingPenId?: number;
    processingWorkset?: FeedlotManagerDataEntitiesMedicalProcessingWorkset;
    processingWorksetId?: number;
};
export type FeedlotManagerDataEntitiesMedicalProcessingPenRead = {
    createdBy?: string | null;
    createdDatetime?: string;
    hdCount?: number;
    hdCountProcessed?: number;
    hdCountToProcess?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    pen?: FeedlotManagerDataEntitiesPenRead;
    penId?: number;
    processingPenId?: number;
    processingWorkset?: FeedlotManagerDataEntitiesMedicalProcessingWorkset;
    processingWorksetId?: number;
};
export type FeedlotManagerDataEnumsMedicalLotProcessing =
    | 'Started'
    | 'Processing'
    | 'Processed'
    | 'StartedReprocessing'
    | 'Reprocessing'
    | 'Reprocessed'
    | 'DoNotProcess';
export type FeedlotManagerDataEntitiesMedicalProcessingWorkset = {
    averageWeight?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    processedDate?: string | null;
    processingMedicalItems?:
        | FeedlotManagerDataEntitiesMedicalProcessingMedicalItem[]
        | null;
    processingPens?: FeedlotManagerDataEntitiesMedicalProcessingPen[] | null;
    processingProtocol?: FeedlotManagerDataEntitiesMedicalProcessingProtocol;
    processingProtocolId?: number | null;
    processingWorksetId?: number;
    status?: FeedlotManagerDataEnumsMedicalLotProcessing;
};
export type FeedlotManagerDataEntitiesMedicalProcessingWorksetRead = {
    averageWeight?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number;
    lot?: FeedlotManagerDataEntitiesFmLot;
    lotId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    processedDate?: string | null;
    processingMedicalItems?:
        | FeedlotManagerDataEntitiesMedicalProcessingMedicalItemRead[]
        | null;
    processingPens?:
        | FeedlotManagerDataEntitiesMedicalProcessingPenRead[]
        | null;
    processingProtocol?: FeedlotManagerDataEntitiesMedicalProcessingProtocolRead;
    processingProtocolId?: number | null;
    processingWorksetId?: number;
    status?: FeedlotManagerDataEnumsMedicalLotProcessing;
};
export type FeedlotManagerCommonEnumsLotState = 'Open' | 'Closing' | 'Inactive';
export type FeedlotManagerDataEntitiesFmLot = {
    averageDailyGain?: number;
    averageWeight?: number;
    cattleType?: FeedlotManagerDataEntitiesCattleType;
    cattleTypeId?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    entityCache?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    hdCount?: number;
    hdCountProcessed?: number | null;
    hdCountReprocessed?: number | null;
    lastAuditCreatedDatetime?: string;
    lotId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    owner?: CcCommonDataSharedEntitiesOwners;
    ownerId?: number | null;
    pens?: FeedlotManagerDataEntitiesPen[] | null;
    processingWorksets?:
        | FeedlotManagerDataEntitiesMedicalProcessingWorkset[]
        | null;
    rowVersion?: string | null;
    sex?: FeedlotManagerDataEntitiesSex;
    sexId?: number | null;
    state?: FeedlotManagerCommonEnumsLotState;
    totalHdCount?: number | null;
};
export type FeedlotManagerDataEntitiesFmLotRead = {
    averageDailyGain?: number;
    averageWeight?: number;
    cattleType?: FeedlotManagerDataEntitiesCattleType;
    cattleTypeId?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    entityCache?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    hdCount?: number;
    hdCountProcessed?: number | null;
    hdCountReprocessed?: number | null;
    lastAuditCreatedDatetime?: string;
    lotId?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    owner?: CcCommonDataSharedEntitiesOwners;
    ownerId?: number | null;
    pens?: FeedlotManagerDataEntitiesPenRead[] | null;
    processingWorksets?:
        | FeedlotManagerDataEntitiesMedicalProcessingWorksetRead[]
        | null;
    rowVersion?: string | null;
    sex?: FeedlotManagerDataEntitiesSex;
    sexId?: number | null;
    state?: FeedlotManagerCommonEnumsLotState;
    totalHdCount?: number | null;
};
export type TenantManagementCommonEnumsFeedLotType =
    | 'Standard'
    | 'Test'
    | 'Calf';
export type FeedlotManagerDataEntitiesFmFeedLot = {
    altManager?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    entityCache?: string | null;
    feedLotId?: number;
    feedLotSubscription?: FeedlotManagerDataEntitiesFmFeedLotSubscription;
    feedLotSubscriptionId?: number | null;
    isInactive?: boolean;
    label?: string | null;
    labelColorHex?: string | null;
    location?: CcCommonDataSharedEntitiesLocation;
    locationId?: number | null;
    lots?: FeedlotManagerDataEntitiesFmLot[] | null;
    manager?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    pens?: FeedlotManagerDataEntitiesPen[] | null;
    timeZoneStandardName?: string | null;
    type?: TenantManagementCommonEnumsFeedLotType;
    varianceThreshold?: number;
    yardageRate?: number | null;
};
export type FeedlotManagerDataEntitiesFmFeedLotRead = {
    altManager?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    entityCache?: string | null;
    feedLotId?: number;
    feedLotSubscription?: FeedlotManagerDataEntitiesFmFeedLotSubscription;
    feedLotSubscriptionId?: number | null;
    isInactive?: boolean;
    label?: string | null;
    labelColorHex?: string | null;
    location?: CcCommonDataSharedEntitiesLocation;
    locationId?: number | null;
    lots?: FeedlotManagerDataEntitiesFmLotRead[] | null;
    manager?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    pens?: FeedlotManagerDataEntitiesPenRead[] | null;
    timeZoneStandardName?: string | null;
    type?: TenantManagementCommonEnumsFeedLotType;
    varianceThreshold?: number;
    yardageRate?: number | null;
};
export type FeedlotManagerDataEnumsStatusEnum = 'Inactive' | 'Active';
export type FeedlotManagerDataEntitiesFeedTank = {
    commodity?: FeedlotManagerDataEntitiesFeedCommodity;
    commodityId?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    description?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    inactivated?: boolean;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    preload?: boolean;
    tankId?: number;
};
export type FeedlotManagerDataEntitiesFeedTankRead = {
    commodity?: FeedlotManagerDataEntitiesFeedCommodity;
    commodityId?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    description?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLotRead;
    feedLotId?: number | null;
    inactivated?: boolean;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    preload?: boolean;
    tankId?: number;
};
export type FeedlotManagerCommonEnumsCommodityType =
    | 'Dry'
    | 'Liquid'
    | 'Microingredient';
export type FeedlotManagerDataEntitiesFeedCommodity = {
    actionRecalculationDate?: string | null;
    commodityId?: number;
    contracts?: FeedlotManagerDataEntitiesFeedContract[] | null;
    createdBy?: string | null;
    createdDatetime?: string;
    dryMatterPercentage?: number;
    feedDeliveryCache?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    inventoryAmount?: number | null;
    lastAdjusted?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    price?: number | null;
    priceEffectiveDate?: string;
    rowVersion?: string | null;
    status?: FeedlotManagerDataEnumsStatusEnum;
    tank?: FeedlotManagerDataEntitiesFeedTank;
    type?: FeedlotManagerCommonEnumsCommodityType;
    version?: number | null;
    versionId?: number | null;
};
export type FeedlotManagerDataEntitiesFeedCommodityRead = {
    actionRecalculationDate?: string | null;
    commodityId?: number;
    contracts?: FeedlotManagerDataEntitiesFeedContract[] | null;
    createdBy?: string | null;
    createdDatetime?: string;
    dryMatterPercentage?: number;
    feedDeliveryCache?: string | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLotRead;
    feedLotId?: number | null;
    inventoryAmount?: number | null;
    lastAdjusted?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    price?: number | null;
    priceEffectiveDate?: string;
    rowVersion?: string | null;
    status?: FeedlotManagerDataEnumsStatusEnum;
    tank?: FeedlotManagerDataEntitiesFeedTankRead;
    type?: FeedlotManagerCommonEnumsCommodityType;
    version?: number | null;
    versionId?: number | null;
};
export type FeedlotManagerDataEnumsContractType =
    | 'Inbound'
    | 'Outbound'
    | 'Both';
export type FeedlotManagerDataEntitiesFeedVendor = {
    active?: boolean;
    address?: string | null;
    city?: string | null;
    contactName?: string | null;
    contactNumber?: string | null;
    contracts?: FeedlotManagerDataEntitiesFeedContract[] | null;
    createdBy?: string | null;
    createdDatetime?: string;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    state?: string | null;
    vendorId?: number;
    zipCode?: string | null;
};
export type FeedlotManagerDataEntitiesFeedContract = {
    comment?: string | null;
    commodity?: FeedlotManagerDataEntitiesFeedCommodity;
    commodityId?: number;
    contractCreationDate?: string;
    contractId?: number;
    contractName?: string | null;
    contractType?: FeedlotManagerDataEnumsContractType;
    createdBy?: string | null;
    createdDatetime?: string;
    dmPercentage?: number | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number | null;
    freight?: FeedlotManagerCommonEnumsFreightType;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    payee?: string | null;
    price?: number | null;
    rate?: number;
    remainingAmount?: number | null;
    status?: FeedlotManagerCommonEnumsContractStatus;
    totalAmount?: number;
    vendor?: FeedlotManagerDataEntitiesFeedVendor;
    vendorId?: number;
};
export type FeedlotManagerDataEntitiesFeedContractRead = {
    comment?: string | null;
    commodity?: FeedlotManagerDataEntitiesFeedCommodityRead;
    commodityId?: number;
    contractCreationDate?: string;
    contractId?: number;
    contractName?: string | null;
    contractType?: FeedlotManagerDataEnumsContractType;
    createdBy?: string | null;
    createdDatetime?: string;
    dmPercentage?: number | null;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLotRead;
    feedLotId?: number | null;
    freight?: FeedlotManagerCommonEnumsFreightType;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    payee?: string | null;
    price?: number | null;
    rate?: number;
    remainingAmount?: number | null;
    status?: FeedlotManagerCommonEnumsContractStatus;
    totalAmount?: number;
    vendor?: FeedlotManagerDataEntitiesFeedVendor;
    vendorId?: number;
};
export type FeedlotManagerModelsFeedVendorModel = {
    active?: boolean;
    address?: string | null;
    city?: string | null;
    contactName?: string | null;
    contactNumber?: string | null;
    contracts?: FeedlotManagerDataEntitiesFeedContract[] | null;
    name?: string | null;
    state?: string | null;
    vendorId?: number;
    zipCode?: string | null;
};
export type FeedlotManagerModelsFeedContractModel = {
    comment?: string | null;
    commodity?: FeedlotManagerModelsFeedCommodityModel;
    commodityId?: number;
    contractCreationDate?: string;
    contractId?: number;
    contractName?: string | null;
    createdBy?: string | null;
    createdDatetime?: string;
    dmPercentage?: number | null;
    feedLot?: FeedlotManagerModelsFmFeedlotModel;
    feedLotId?: number | null;
    freight?: FeedlotManagerCommonEnumsFreightType;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    payee?: string | null;
    price?: number | null;
    rate?: number;
    remainingAmount?: number | null;
    status?: FeedlotManagerCommonEnumsContractStatus;
    totalAmount?: number;
    vendor?: FeedlotManagerModelsFeedVendorModel;
    vendorId?: number;
};
export type FeedlotManagerModelsFeedTankModel = {
    active?: boolean;
    commodity?: FeedlotManagerModelsFeedCommodityModel;
    commodityId?: number | null;
    description?: string | null;
    feedLot?: FeedlotManagerModelsFmFeedlotModel;
    feedLotId?: number | null;
    name: string;
    preload?: boolean;
    tankId?: number;
};
export type FeedlotManagerModelsFeedCommodityModel = {
    commodityId?: number;
    contracts?: FeedlotManagerModelsFeedContractModel[] | null;
    createdBy?: string | null;
    createdDatetime?: string;
    dryMatterPercentage?: number;
    inventoryAmount?: number | null;
    lastAdjusted?: string | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    price?: number | null;
    priceEffectiveDate?: string;
    status?: FeedlotManagerDataEnumsStatusEnum;
    tank?: FeedlotManagerModelsFeedTankModel;
    type?: FeedlotManagerCommonEnumsCommodityType;
    version?: number | null;
};
export type FeedlotManagerModelsFeedRationIngredientModel = {
    amountPercentage?: number;
    commodity?: FeedlotManagerModelsFeedCommodityModel;
    commodityId?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    dryMatterPercentage?: number;
    lbsPerLoad?: number;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    ordinal?: number;
    price?: number;
    ration?: FeedlotManagerModelsFeedRationModel;
    rationId?: number | null;
};
export type FeedlotManagerModelsFeedRationVersionModel = {
    category?: FeedlotManagerDataEntitiesFeedRationType;
    categoryId?: number | null;
    comment?: string | null;
    corporatePrice?: number | null;
    customPrice?: number | null;
    effectiveDate?: string;
    effectiveTime?: string;
    loadSize?: number | null;
    markUp?: number | null;
    modifiedBy?: string | null;
    modifiedDateTime?: string | null;
    neg?: string | null;
    nem?: string | null;
    ration?: FeedlotManagerModelsFeedRationModel;
    rationId?: number;
    rationIngredients?: FeedlotManagerModelsFeedRationIngredientModel[] | null;
    rationName?: string | null;
    rationVersionId?: number;
    version?: number;
};
export type FeedlotManagerModelsFeedRationModel = {
    category?: FeedlotManagerModelsFeedRationTypeModel;
    categoryId?: number;
    comments?: string | null;
    corporatePrice?: number | null;
    createdBy?: string | null;
    createdDatetime?: string;
    customPrice?: number | null;
    effectiveDate?: string;
    feedLot?: FeedlotManagerModelsFmFeedlotModel;
    feedLotId?: number | null;
    internalName?: string | null;
    isCurrentRation?: boolean;
    loadSize?: number | null;
    markUp?: number | null;
    modifiedBy?: string | null;
    modifiedDatetime?: string | null;
    name?: string | null;
    neg?: string | null;
    nem?: string | null;
    rationId?: number;
    rationIngredients?: FeedlotManagerModelsFeedRationIngredientModel[] | null;
    rationVersion?: number;
    rationVersionId?: number | null;
    rationVersions?: FeedlotManagerModelsFeedRationVersionModel[] | null;
};
export type FeedlotManagerModelsFeedScheduleTypeScheduleTypeModel = {
    scheduleTypeId?: number;
    tableName?: string | null;
    type?: string | null;
};
export type FeedlotManagerModelsFeedPenRationScheduleTemplateModel = {
    feedLotId?: number;
    name?: string | null;
    penRationScheduleTemplateId?: number;
    scheduleType?: FeedlotManagerModelsFeedScheduleTypeScheduleTypeModel;
    scheduleTypeId?: number | null;
    startingAFPerHd?: number;
    startingDryMatterPerHd?: number;
    tableId?: number;
};
export type FeedlotManagerModelsFeedPenRationSchedulePenRationScheduleModel = {
    name?: string | null;
    penRationScheduleId?: number;
    penRationScheduleTemplate?: FeedlotManagerModelsFeedPenRationScheduleTemplateModel;
    penRationScheduleTemplateId?: number | null;
    scheduleType?: FeedlotManagerModelsFeedScheduleTypeScheduleTypeModel;
    scheduleTypeId?: number | null;
    startingAFPerHd?: number;
    startingDryMatterPerHd?: number;
    tableId?: number;
};
export type FeedlotManagerModelsFeedFeedCallFeedCallModel = {
    adg?: number;
    afChangePerHd?: number;
    afPerHd?: number;
    bunkScore?: number;
    comments?: string | null;
    daysOnCurrentRation?: number | null;
    daysOnFeed?: number | null;
    dmChangePerHd?: number;
    dmPerHd?: number;
    effectiveDate?: string;
    endRation?: FeedlotManagerModelsFeedRationModel;
    endRationId?: number | null;
    endRationName?: string | null;
    endRationPercentage?: number;
    estimatedHdCount?: number | null;
    fedToday?: number | null;
    feedCallId?: number;
    hayFeeding1?: number | null;
    hayFeeding2?: number | null;
    hdCount?: number;
    noOfFeedingsBeforeShipment?: number | null;
    pen?: FeedlotManagerModelsPenModel;
    penId?: number;
    penRationSchedule?: FeedlotManagerModelsFeedPenRationSchedulePenRationScheduleModel;
    penRationScheduleId?: number | null;
    startRation?: FeedlotManagerModelsFeedRationModel;
    startRationId?: number | null;
    startRationName?: string | null;
    startRationPercentage?: number;
    totalCall?: number;
    variancePercentage?: number | null;
    wtPerDM?: number | null;
};
export type FeedlotManagerDataEntitiesBillingFeedChargeModel = {
    baseChargeId?: number;
    chargeInCents?: number;
    chargeType?: string | null;
    date?: string;
    deliveredAmount?: number;
    headCount?: number | null;
    lotId?: number;
    penRationHistory?: FeedlotManagerModelsFeedPenRationHistoryModel;
    penRationHistoryId?: number;
    rationVersion?: FeedlotManagerDataEntitiesFeedRationVersion;
    rationVersionId?: number;
};
export type FeedlotManagerModelsFeedPenRationHistoryModel = {
    afChangePerHd?: number | null;
    createdDatetime?: string | null;
    deliveredAmount?: number;
    deliveryWithinVariance?: boolean | null;
    driver?: string | null;
    estimatedHdCount?: number;
    estimatedHeadTracker?: FeedlotManagerServicesCacheContextEstimatedHeadTrackerCacheContext;
    feedCall?: FeedlotManagerModelsFeedFeedCallFeedCallModel;
    feedCallId?: number | null;
    feedCharges?: FeedlotManagerDataEntitiesBillingFeedChargeModel[] | null;
    feedingNumber?: number | null;
    feedingPortionAmount?: number;
    feedTaskId?: number | null;
    hdCount?: number;
    modifiedDatetime?: string | null;
    outstandingEstimatedHeads?:
        | FeedlotManagerServicesCacheContextEstimatedHeadTracker[]
        | null;
    pen?: FeedlotManagerModelsPenModel;
    penId?: number | null;
    penRationHistoryId?: number;
    penRationScheduleId?: number | null;
    rationFeedAmount?: number;
    rationId?: number | null;
    rationName?: string | null;
    registeredDeliveredAmount?: number | null;
    scheduledAmount?: number;
    totalPenFeedCall?: number;
    truck?: string | null;
};
export type FeedlotManagerModelsPenModel = {
    active?: boolean;
    currentFeedingAmount?: number | null;
    currentFeedingNumber?: number | null;
    currentRation?: string | null;
    currentRationAmount?: number | null;
    currentRationId?: number;
    currentRationNames?: string[] | null;
    currentTotalCall?: number | null;
    currentTotalCallLeft?: number | null;
    feedlot?: FeedlotManagerModelsFmFeedlotModel;
    feedLotId?: number;
    feedTaskId?: number | null;
    hdCount?: number;
    kind?: FeedlotManagerCommonEnumsPenType;
    modifiedDatetime?: string;
    name?: string | null;
    nextActiveFeedingNumberForRation?: number | null;
    penId?: number;
    penRationHistory?: FeedlotManagerModelsFeedPenRationHistoryModel[] | null;
    penRationSchedule?: FeedlotManagerModelsFeedPenRationSchedulePenRationScheduleModel;
    penRationScheduleId?: number | null;
};
export type FeedlotManagerModelsCattleTypeModel = {
    cattleTypeId?: number;
    description?: string | null;
    name?: string | null;
};
export type CcCommonApIsModelsOwnersModel = {
    addressId?: number;
    altContactNumber?: string | null;
    contactName?: string | null;
    contactNumber?: string | null;
    email?: string | null;
    id?: number;
    isActive?: boolean;
    name?: string | null;
};
export type FeedlotManagerModelsMedicalMedicalItemModel = {
    category?: FeedlotManagerDataEnumsMedicalCategory;
    cost?: number;
    date?: string | null;
    dosageRate?: number;
    dosageType?: FeedlotManagerDataEnumsMedicalDosageType;
    isActive?: boolean | null;
    markup?: number;
    medicalItemId?: number;
    medicalItemVersions?:
        | FeedlotManagerDataEntitiesMedicalMedicalItemVersion[]
        | null;
    medicalLot?: FeedlotManagerDataEntitiesMedicalMedicalLot[] | null;
    name?: string | null;
    subCategory?: FeedlotManagerDataEnumsMedicalSubCategory;
    totalCost?: number | null;
    version?: number;
    versionId?: number | null;
    withdrawal?: number;
};
export type FeedlotManagerModelsMedicalProcessingMedicalItemModel = {
    actualDose?: number | null;
    hdCountTreated?: number;
    medicalItem?: FeedlotManagerModelsMedicalMedicalItemModel;
    medicalItemId?: number;
    medicalLotId?: string | null;
};
export type FeedlotManagerModelsMedicalProcessingPenModel = {
    hdCount?: number;
    hdCountProcessed?: number;
    hdCountToProcess?: number;
    name?: string | null;
    penId?: number;
};
export type FeedlotManagerModelsMedicalProcessingProtocolModel = {
    medicalItems?: FeedlotManagerModelsMedicalMedicalItemModel[] | null;
    name?: string | null;
    processingProtocolId?: number;
};
export type FeedlotManagerModelsResponseModelsProcessingWorksetApiResponseModel =
    {
        averageWeight?: number | null;
        createdDatetime?: string | null;
        hdCountProcessed?: number | null;
        lotId?: number;
        processedDate?: string | null;
        processingMedicalItems?:
            | FeedlotManagerModelsMedicalProcessingMedicalItemModel[]
            | null;
        processingPens?: FeedlotManagerModelsMedicalProcessingPenModel[] | null;
        processingProtocol?: FeedlotManagerModelsMedicalProcessingProtocolModel;
        processingProtocolId?: number | null;
        processingWorksetId?: number;
        status?: FeedlotManagerDataEnumsMedicalLotProcessing;
    };
export type FeedlotManagerModelsSexModel = {
    name?: string | null;
    sexId?: number;
};
export type FeedlotManagerModelsFmLotModel = {
    averageDailyGain?: number;
    cattleType?: FeedlotManagerModelsCattleTypeModel;
    cattleTypeId?: number | null;
    createdDatetime?: string;
    feedlot?: FeedlotManagerModelsFmFeedlotModel;
    feedLotId?: number | null;
    hdCount?: number | null;
    hdCountProcessed?: number | null;
    hdCountReprocessed?: number | null;
    lotAverageWeight?: number | null;
    lotId?: number;
    name?: string | null;
    owner?: CcCommonApIsModelsOwnersModel;
    ownerId?: number | null;
    pens?: FeedlotManagerModelsPenModel[] | null;
    processingWorksets?:
        | FeedlotManagerModelsResponseModelsProcessingWorksetApiResponseModel[]
        | null;
    sex?: FeedlotManagerModelsSexModel;
    sexId?: number | null;
    state?: FeedlotManagerCommonEnumsLotState;
    totalHdCount?: number | null;
};
export type FeedlotManagerModelsResponseModelsAnimalListApiResponse = {
    animalId?: number;
    animalTag?: string | null;
    cleanDate?: string | null;
    currentPen?: FeedlotManagerModelsPenModel;
    currentPenId?: number | null;
    homePen?: FeedlotManagerModelsPenModel;
    homePenId?: number | null;
    isAlive?: boolean;
    lot?: FeedlotManagerModelsFmLotModel;
    lotId?: number;
    sex?: string | null;
    status?: FeedlotManagerCommonEnumsAnimalStatus;
};
export type FeedlotManagerModelsMedicalUpdateAnimalModel = {
    animalId?: number;
    currentPenId?: number;
    isAlive?: boolean;
    moveDate?: string;
    movePenId?: number;
};
export type FeedlotManagerModelsMedicalTreatmentProtocolModel = {
    diagnosis?: FeedlotManagerModelsMedicalDiagnosisModel;
    diagnosisId?: number;
    medicalItems?: FeedlotManagerModelsMedicalMedicalItemModel[] | null;
    name?: string | null;
    treatmentProtocolId?: number;
};
export type FeedlotManagerModelsMedicalDiagnosisModel = {
    diagnosisId?: number;
    name?: string | null;
    treatmentProtocols?:
        | FeedlotManagerModelsMedicalTreatmentProtocolModel[]
        | null;
};
export type CcCommonEnumsModuleTypeModuleEnum =
    | 'BuyPO'
    | 'BuyPOSource'
    | 'BuyPODestination'
    | 'ShipPO'
    | 'ShipPOShipLocation'
    | 'ShipPODestination'
    | 'FeedlotManager'
    | 'Feedlot'
    | 'FMSource'
    | 'FMDestination'
    | 'ReceiveCattle'
    | 'ShipCattle'
    | 'CattleTreatment'
    | 'ProcessingTreatment'
    | 'FMLot'
    | 'Invoice'
    | 'CattlePO';
export type FeedlotManagerModelsMedicalMedicalChargeModel = {
    actualDose?: number | null;
    charge?: string | null;
    chargeId?: number;
    dosageRate?: number;
    headCount?: number | null;
    medicalItem?: FeedlotManagerModelsMedicalMedicalItemModel;
    medicalItemId?: number;
    medicalLotName?: string | null;
    moduleName?: CcCommonEnumsModuleTypeModuleEnum;
    pricePerHead?: number;
    tableId?: number | null;
};
export type FeedlotManagerModelsMedicalTechModel = {
    active?: boolean;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLot;
    feedLotId?: number;
    name?: string | null;
    techId?: number;
};
export type FeedlotManagerModelsMedicalTechModelRead = {
    active?: boolean;
    feedLot?: FeedlotManagerDataEntitiesFmFeedLotRead;
    feedLotId?: number;
    name?: string | null;
    techId?: number;
};
export type FeedlotManagerModelsMedicalCattleTreatmentModel = {
    animal?: FeedlotManagerModelsMedicalAnimalModel;
    animalId?: number;
    cattleTreatmentId?: number;
    comments?: string | null;
    currentPen?: FeedlotManagerModelsPenModel;
    currentPenId?: number | null;
    date?: string;
    diagnosis?: FeedlotManagerModelsMedicalDiagnosisModel;
    diagnosisId?: number;
    feedLotId?: number | null;
    homePen?: FeedlotManagerModelsPenModel;
    homePenId?: number | null;
    lot?: FeedlotManagerModelsFmLotModel;
    lotCurrentWeight?: number | null;
    lotId?: number;
    medicalCharges?: FeedlotManagerModelsMedicalMedicalChargeModel[] | null;
    medicalItems?: FeedlotManagerModelsMedicalMedicalItemModel[] | null;
    movePen?: FeedlotManagerModelsPenModel;
    movePenId?: number | null;
    rider?: FeedlotManagerDataEntitiesMedicalRider;
    riderId?: number | null;
    severity?: FeedlotManagerDataEnumsMedicalSeverity;
    tech?: FeedlotManagerModelsMedicalTechModel;
    techId?: number | null;
    temperature?: string | null;
    treatmentProtocol?: FeedlotManagerModelsMedicalTreatmentProtocolModel;
    treatmentProtocolId?: number;
};
export type FeedlotManagerModelsMedicalCattleTreatmentModelRead = {
    animal?: FeedlotManagerModelsMedicalAnimalModel;
    animalId?: number;
    cattleTreatmentId?: number;
    comments?: string | null;
    currentPen?: FeedlotManagerModelsPenModel;
    currentPenId?: number | null;
    date?: string;
    diagnosis?: FeedlotManagerModelsMedicalDiagnosisModel;
    diagnosisId?: number;
    feedLotId?: number | null;
    homePen?: FeedlotManagerModelsPenModel;
    homePenId?: number | null;
    lot?: FeedlotManagerModelsFmLotModel;
    lotCurrentWeight?: number | null;
    lotId?: number;
    medicalCharges?: FeedlotManagerModelsMedicalMedicalChargeModel[] | null;
    medicalItems?: FeedlotManagerModelsMedicalMedicalItemModel[] | null;
    movePen?: FeedlotManagerModelsPenModel;
    movePenId?: number | null;
    rider?: FeedlotManagerDataEntitiesMedicalRiderRead;
    riderId?: number | null;
    severity?: FeedlotManagerDataEnumsMedicalSeverity;
    tech?: FeedlotManagerModelsMedicalTechModelRead;
    techId?: number | null;
    temperature?: string | null;
    treatmentProtocol?: FeedlotManagerModelsMedicalTreatmentProtocolModel;
    treatmentProtocolId?: number;
};
export type FeedlotManagerModelsMedicalAnimalModel = {
    animalId?: number;
    animalTag?: string | null;
    cattleTreatments?: FeedlotManagerModelsMedicalCattleTreatmentModel[] | null;
    cleanDate?: string | null;
    currentPen?: FeedlotManagerModelsPenModel;
    currentPenId?: number | null;
    eid?: string | null;
    homePen?: FeedlotManagerModelsPenModel;
    homePenId?: number | null;
    isAlive?: boolean;
    lot?: FeedlotManagerModelsFmLotModel;
    lotId?: number;
    previousPen?: FeedlotManagerModelsPenModel;
    previousPenId?: number | null;
    sex?: FeedlotManagerModelsSexModel;
    sexId?: number;
    status?: FeedlotManagerCommonEnumsAnimalStatus;
};
export type FeedlotManagerModelsMedicalAnimalModelRead = {
    animalId?: number;
    animalTag?: string | null;
    cattleTreatments?:
        | FeedlotManagerModelsMedicalCattleTreatmentModelRead[]
        | null;
    cleanDate?: string | null;
    currentPen?: FeedlotManagerModelsPenModel;
    currentPenId?: number | null;
    eid?: string | null;
    homePen?: FeedlotManagerModelsPenModel;
    homePenId?: number | null;
    isAlive?: boolean;
    lot?: FeedlotManagerModelsFmLotModel;
    lotId?: number;
    previousPen?: FeedlotManagerModelsPenModel;
    previousPenId?: number | null;
    sex?: FeedlotManagerModelsSexModel;
    sexId?: number;
    status?: FeedlotManagerCommonEnumsAnimalStatus;
};
export type MicrosoftODataUriParserAggregationTransformationNodeKind =
    | 'Aggregate'
    | 'GroupBy'
    | 'Filter'
    | 'Compute'
    | 'Expand';
export type MicrosoftODataUriParserAggregationTransformationNode = {
    kind?: MicrosoftODataUriParserAggregationTransformationNodeKind;
};
export type MicrosoftODataUriParserAggregationApplyClause = {
    transformations?:
        | MicrosoftODataUriParserAggregationTransformationNode[]
        | null;
};
export type MicrosoftAspNetCoreODataQueryDefaultQueryConfigurations = {
    enableCount?: boolean;
    enableExpand?: boolean;
    enableFilter?: boolean;
    enableOrderBy?: boolean;
    enableSelect?: boolean;
    enableSkipToken?: boolean;
    maxTop?: number | null;
};
export type SystemReflectionMethodAttributes =
    | 'PrivateScope'
    | 'Private'
    | 'FamANDAssem'
    | 'Assembly'
    | 'Family'
    | 'FamORAssem'
    | 'Public'
    | 'MemberAccessMask'
    | 'UnmanagedExport'
    | 'Static'
    | 'Final'
    | 'Virtual'
    | 'HideBySig'
    | 'NewSlot'
    | 'CheckAccessOnOverride'
    | 'Abstract'
    | 'SpecialName'
    | 'RTSpecialName'
    | 'PinvokeImpl'
    | 'HasSecurity'
    | 'RequireSecObject'
    | 'ReservedMask';
export type SystemReflectionCallingConventions =
    | 'Standard'
    | 'VarArgs'
    | 'Any'
    | 'HasThis'
    | 'ExplicitThis';
export type SystemReflectionMemberTypes =
    | 'Constructor'
    | 'Event'
    | 'Field'
    | 'Method'
    | 'Property'
    | 'TypeInfo'
    | 'Custom'
    | 'NestedType'
    | 'All';
export type SystemIntPtr = object;
export type SystemRuntimeMethodHandle = {
    value?: SystemIntPtr;
};
export type SystemReflectionMethodImplAttributes =
    | 'IL'
    | 'Native'
    | 'OPTIL'
    | 'CodeTypeMask'
    | 'ManagedMask'
    | 'NoInlining'
    | 'ForwardRef'
    | 'Synchronized'
    | 'NoOptimization'
    | 'PreserveSig'
    | 'AggressiveInlining'
    | 'AggressiveOptimization'
    | 'InternalCall'
    | 'MaxMethodImplVal';
export type SystemModuleHandle = {};
export type SystemModuleHandleRead = {
    mdStreamVersion?: number;
};
export type SystemReflectionModule = {
    assembly?: SystemReflectionAssembly;
    moduleHandle?: SystemModuleHandle;
};
export type SystemReflectionConstructorInfo = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    declaringType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
};
export type SystemReflectionConstructorInfoRead = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    containsGenericParameters?: boolean;
    customAttributes?: SystemReflectionCustomAttributeData[] | null;
    declaringType?: SystemType;
    isAbstract?: boolean;
    isAssembly?: boolean;
    isCollectible?: boolean;
    isConstructedGenericMethod?: boolean;
    isConstructor?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isFinal?: boolean;
    isGenericMethod?: boolean;
    isGenericMethodDefinition?: boolean;
    isHideBySig?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    isVirtual?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    reflectedType?: SystemType;
};
export type SystemReflectionCustomAttributeData = {
    attributeType?: SystemType;
    constructor?: SystemReflectionConstructorInfo;
};
export type SystemReflectionCustomAttributeTypedArgument = {
    argumentType?: SystemType;
    value?: any | null;
};
export type SystemReflectionCustomAttributeTypedArgumentRead = {
    argumentType?: SystemType;
    value?: any | null;
};
export type SystemReflectionMemberInfo = {
    declaringType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
};
export type SystemReflectionMemberInfoRead = {
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    isCollectible?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    reflectedType?: SystemType;
};
export type SystemReflectionCustomAttributeNamedArgument = {
    memberInfo?: SystemReflectionMemberInfo;
    typedValue?: SystemReflectionCustomAttributeTypedArgument;
};
export type SystemReflectionCustomAttributeNamedArgumentRead = {
    isField?: boolean;
    memberInfo?: SystemReflectionMemberInfoRead;
    memberName?: string | null;
    typedValue?: SystemReflectionCustomAttributeTypedArgumentRead;
};
export type SystemReflectionCustomAttributeDataRead = {
    attributeType?: SystemType;
    constructor?: SystemReflectionConstructorInfoRead;
    constructorArguments?:
        | SystemReflectionCustomAttributeTypedArgumentRead[]
        | null;
    namedArguments?: SystemReflectionCustomAttributeNamedArgumentRead[] | null;
};
export type SystemReflectionModuleRead = {
    assembly?: SystemReflectionAssembly;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    fullyQualifiedName?: string | null;
    mdStreamVersion?: number;
    metadataToken?: number;
    moduleHandle?: SystemModuleHandleRead;
    moduleVersionId?: string;
    name?: string | null;
    scopeName?: string | null;
};
export type SystemReflectionParameterAttributes =
    | 'None'
    | 'In'
    | 'Out'
    | 'Lcid'
    | 'Retval'
    | 'Optional'
    | 'HasDefault'
    | 'HasFieldMarshal'
    | 'Reserved3'
    | 'Reserved4'
    | 'ReservedMask';
export type SystemReflectionParameterInfo = {
    attributes?: SystemReflectionParameterAttributes;
    member?: SystemReflectionMemberInfo;
    parameterType?: SystemType;
};
export type SystemReflectionParameterInfoRead = {
    attributes?: SystemReflectionParameterAttributes;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    defaultValue?: any | null;
    hasDefaultValue?: boolean;
    isIn?: boolean;
    isLcid?: boolean;
    isOptional?: boolean;
    isOut?: boolean;
    isRetval?: boolean;
    member?: SystemReflectionMemberInfoRead;
    metadataToken?: number;
    name?: string | null;
    parameterType?: SystemType;
    position?: number;
    rawDefaultValue?: any | null;
};
export type SystemReflectionICustomAttributeProvider = object;
export type SystemReflectionMethodInfo = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    declaringType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
    returnParameter?: SystemReflectionParameterInfo;
    returnType?: SystemType;
    returnTypeCustomAttributes?: SystemReflectionICustomAttributeProvider;
};
export type SystemReflectionMethodInfoRead = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    containsGenericParameters?: boolean;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    isAbstract?: boolean;
    isAssembly?: boolean;
    isCollectible?: boolean;
    isConstructedGenericMethod?: boolean;
    isConstructor?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isFinal?: boolean;
    isGenericMethod?: boolean;
    isGenericMethodDefinition?: boolean;
    isHideBySig?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    isVirtual?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    reflectedType?: SystemType;
    returnParameter?: SystemReflectionParameterInfoRead;
    returnType?: SystemType;
    returnTypeCustomAttributes?: SystemReflectionICustomAttributeProvider;
};
export type SystemSecuritySecurityRuleSet = 'None' | 'Level1' | 'Level2';
export type SystemReflectionAssembly = {
    entryPoint?: SystemReflectionMethodInfo;
    manifestModule?: SystemReflectionModule;
    securityRuleSet?: SystemSecuritySecurityRuleSet;
};
export type SystemReflectionTypeAttributes =
    | 'NotPublic'
    | 'Public'
    | 'NestedPublic'
    | 'NestedPrivate'
    | 'NestedFamily'
    | 'NestedAssembly'
    | 'NestedFamANDAssem'
    | 'VisibilityMask'
    | 'SequentialLayout'
    | 'ExplicitLayout'
    | 'LayoutMask'
    | 'Interface'
    | 'Abstract'
    | 'Sealed'
    | 'SpecialName'
    | 'RTSpecialName'
    | 'Import'
    | 'Serializable'
    | 'WindowsRuntime'
    | 'UnicodeClass'
    | 'AutoClass'
    | 'StringFormatMask'
    | 'HasSecurity'
    | 'ReservedMask'
    | 'BeforeFieldInit'
    | 'CustomFormatMask';
export type SystemReflectionMethodBase = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    declaringType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
};
export type SystemReflectionMethodBaseRead = {
    attributes?: SystemReflectionMethodAttributes;
    callingConvention?: SystemReflectionCallingConventions;
    containsGenericParameters?: boolean;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    isAbstract?: boolean;
    isAssembly?: boolean;
    isCollectible?: boolean;
    isConstructedGenericMethod?: boolean;
    isConstructor?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isFinal?: boolean;
    isGenericMethod?: boolean;
    isGenericMethodDefinition?: boolean;
    isHideBySig?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    isVirtual?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    methodHandle?: SystemRuntimeMethodHandle;
    methodImplementationFlags?: SystemReflectionMethodImplAttributes;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    reflectedType?: SystemType;
};
export type SystemReflectionGenericParameterAttributes =
    | 'None'
    | 'Covariant'
    | 'Contravariant'
    | 'VarianceMask'
    | 'ReferenceTypeConstraint'
    | 'NotNullableValueTypeConstraint'
    | 'DefaultConstructorConstraint'
    | 'SpecialConstraintMask';
export type SystemRuntimeInteropServicesLayoutKind =
    | 'Sequential'
    | 'Explicit'
    | 'Auto';
export type SystemRuntimeInteropServicesStructLayoutAttribute = {
    value?: SystemRuntimeInteropServicesLayoutKind;
};
export type SystemRuntimeInteropServicesStructLayoutAttributeRead = {
    typeId?: any | null;
    value?: SystemRuntimeInteropServicesLayoutKind;
};
export type SystemRuntimeTypeHandle = {
    value?: SystemIntPtr;
};
export type SystemReflectionTypeInfo = {
    assembly?: SystemReflectionAssembly;
    attributes?: SystemReflectionTypeAttributes;
    baseType?: SystemType;
    declaringMethod?: SystemReflectionMethodBase;
    declaringType?: SystemType;
    genericParameterAttributes?: SystemReflectionGenericParameterAttributes;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
    structLayoutAttribute?: SystemRuntimeInteropServicesStructLayoutAttribute;
    typeHandle?: SystemRuntimeTypeHandle;
    typeInitializer?: SystemReflectionConstructorInfo;
    underlyingSystemType?: SystemType;
};
export type SystemReflectionEventAttributes =
    | 'None'
    | 'SpecialName'
    | 'RTSpecialName';
export type SystemReflectionEventInfo = {
    addMethod?: SystemReflectionMethodInfo;
    attributes?: SystemReflectionEventAttributes;
    declaringType?: SystemType;
    eventHandlerType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    raiseMethod?: SystemReflectionMethodInfo;
    reflectedType?: SystemType;
    removeMethod?: SystemReflectionMethodInfo;
};
export type SystemReflectionEventInfoRead = {
    addMethod?: SystemReflectionMethodInfoRead;
    attributes?: SystemReflectionEventAttributes;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    eventHandlerType?: SystemType;
    isCollectible?: boolean;
    isMulticast?: boolean;
    isSpecialName?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    raiseMethod?: SystemReflectionMethodInfoRead;
    reflectedType?: SystemType;
    removeMethod?: SystemReflectionMethodInfoRead;
};
export type SystemReflectionFieldAttributes =
    | 'PrivateScope'
    | 'Private'
    | 'FamANDAssem'
    | 'Assembly'
    | 'Family'
    | 'FamORAssem'
    | 'Public'
    | 'FieldAccessMask'
    | 'Static'
    | 'InitOnly'
    | 'Literal'
    | 'NotSerialized'
    | 'HasFieldRVA'
    | 'SpecialName'
    | 'RTSpecialName'
    | 'HasFieldMarshal'
    | 'PinvokeImpl'
    | 'HasDefault'
    | 'ReservedMask';
export type SystemRuntimeFieldHandle = {
    value?: SystemIntPtr;
};
export type SystemReflectionFieldInfo = {
    attributes?: SystemReflectionFieldAttributes;
    declaringType?: SystemType;
    fieldHandle?: SystemRuntimeFieldHandle;
    fieldType?: SystemType;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
};
export type SystemReflectionFieldInfoRead = {
    attributes?: SystemReflectionFieldAttributes;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    fieldHandle?: SystemRuntimeFieldHandle;
    fieldType?: SystemType;
    isAssembly?: boolean;
    isCollectible?: boolean;
    isFamily?: boolean;
    isFamilyAndAssembly?: boolean;
    isFamilyOrAssembly?: boolean;
    isInitOnly?: boolean;
    isLiteral?: boolean;
    isNotSerialized?: boolean;
    isPinvokeImpl?: boolean;
    isPrivate?: boolean;
    isPublic?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSpecialName?: boolean;
    isStatic?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    reflectedType?: SystemType;
};
export type SystemReflectionPropertyAttributes =
    | 'None'
    | 'SpecialName'
    | 'RTSpecialName'
    | 'HasDefault'
    | 'Reserved2'
    | 'Reserved3'
    | 'Reserved4'
    | 'ReservedMask';
export type SystemReflectionPropertyInfo = {
    attributes?: SystemReflectionPropertyAttributes;
    declaringType?: SystemType;
    getMethod?: SystemReflectionMethodInfo;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    propertyType?: SystemType;
    reflectedType?: SystemType;
    setMethod?: SystemReflectionMethodInfo;
};
export type SystemReflectionPropertyInfoRead = {
    attributes?: SystemReflectionPropertyAttributes;
    canRead?: boolean;
    canWrite?: boolean;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringType?: SystemType;
    getMethod?: SystemReflectionMethodInfoRead;
    isCollectible?: boolean;
    isSpecialName?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    propertyType?: SystemType;
    reflectedType?: SystemType;
    setMethod?: SystemReflectionMethodInfoRead;
};
export type SystemReflectionTypeInfoRead = {
    assembly?: SystemReflectionAssemblyRead;
    assemblyQualifiedName?: string | null;
    attributes?: SystemReflectionTypeAttributes;
    baseType?: SystemType;
    containsGenericParameters?: boolean;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaredConstructors?: SystemReflectionConstructorInfoRead[] | null;
    declaredEvents?: SystemReflectionEventInfoRead[] | null;
    declaredFields?: SystemReflectionFieldInfoRead[] | null;
    declaredMembers?: SystemReflectionMemberInfoRead[] | null;
    declaredMethods?: SystemReflectionMethodInfoRead[] | null;
    declaredNestedTypes?: SystemReflectionTypeInfoRead[] | null;
    declaredProperties?: SystemReflectionPropertyInfoRead[] | null;
    declaringMethod?: SystemReflectionMethodBaseRead;
    declaringType?: SystemType;
    fullName?: string | null;
    genericParameterAttributes?: SystemReflectionGenericParameterAttributes;
    genericParameterPosition?: number;
    genericTypeArguments?: SystemType[] | null;
    genericTypeParameters?: SystemType[] | null;
    guid?: string;
    hasElementType?: boolean;
    implementedInterfaces?: SystemType[] | null;
    isAbstract?: boolean;
    isAnsiClass?: boolean;
    isArray?: boolean;
    isAutoClass?: boolean;
    isAutoLayout?: boolean;
    isByRef?: boolean;
    isByRefLike?: boolean;
    isClass?: boolean;
    isCollectible?: boolean;
    isCOMObject?: boolean;
    isConstructedGenericType?: boolean;
    isContextful?: boolean;
    isEnum?: boolean;
    isExplicitLayout?: boolean;
    isFunctionPointer?: boolean;
    isGenericMethodParameter?: boolean;
    isGenericParameter?: boolean;
    isGenericType?: boolean;
    isGenericTypeDefinition?: boolean;
    isGenericTypeParameter?: boolean;
    isImport?: boolean;
    isInterface?: boolean;
    isLayoutSequential?: boolean;
    isMarshalByRef?: boolean;
    isNested?: boolean;
    isNestedAssembly?: boolean;
    isNestedFamANDAssem?: boolean;
    isNestedFamily?: boolean;
    isNestedFamORAssem?: boolean;
    isNestedPrivate?: boolean;
    isNestedPublic?: boolean;
    isNotPublic?: boolean;
    isPointer?: boolean;
    isPrimitive?: boolean;
    isPublic?: boolean;
    isSealed?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSerializable?: boolean;
    isSignatureType?: boolean;
    isSpecialName?: boolean;
    isSZArray?: boolean;
    isTypeDefinition?: boolean;
    isUnicodeClass?: boolean;
    isUnmanagedFunctionPointer?: boolean;
    isValueType?: boolean;
    isVariableBoundArray?: boolean;
    isVisible?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    namespace?: string | null;
    reflectedType?: SystemType;
    structLayoutAttribute?: SystemRuntimeInteropServicesStructLayoutAttributeRead;
    typeHandle?: SystemRuntimeTypeHandle;
    typeInitializer?: SystemReflectionConstructorInfoRead;
    underlyingSystemType?: SystemType;
};
export type SystemReflectionAssemblyRead = {
    codeBase?: string | null;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    definedTypes?: SystemReflectionTypeInfoRead[] | null;
    entryPoint?: SystemReflectionMethodInfoRead;
    escapedCodeBase?: string | null;
    exportedTypes?: SystemType[] | null;
    fullName?: string | null;
    globalAssemblyCache?: boolean;
    hostContext?: number;
    imageRuntimeVersion?: string | null;
    isCollectible?: boolean;
    isDynamic?: boolean;
    isFullyTrusted?: boolean;
    location?: string | null;
    manifestModule?: SystemReflectionModuleRead;
    modules?: SystemReflectionModuleRead[] | null;
    reflectionOnly?: boolean;
    securityRuleSet?: SystemSecuritySecurityRuleSet;
};
export type SystemType = {
    assembly?: SystemReflectionAssembly;
    attributes?: SystemReflectionTypeAttributes;
    baseType?: SystemType;
    declaringMethod?: SystemReflectionMethodBase;
    declaringType?: SystemType;
    genericParameterAttributes?: SystemReflectionGenericParameterAttributes;
    memberType?: SystemReflectionMemberTypes;
    module?: SystemReflectionModule;
    reflectedType?: SystemType;
    structLayoutAttribute?: SystemRuntimeInteropServicesStructLayoutAttribute;
    typeHandle?: SystemRuntimeTypeHandle;
    typeInitializer?: SystemReflectionConstructorInfo;
    underlyingSystemType?: SystemType;
};
export type SystemTypeRead = {
    assembly?: SystemReflectionAssemblyRead;
    assemblyQualifiedName?: string | null;
    attributes?: SystemReflectionTypeAttributes;
    baseType?: SystemTypeRead;
    containsGenericParameters?: boolean;
    customAttributes?: SystemReflectionCustomAttributeDataRead[] | null;
    declaringMethod?: SystemReflectionMethodBaseRead;
    declaringType?: SystemTypeRead;
    fullName?: string | null;
    genericParameterAttributes?: SystemReflectionGenericParameterAttributes;
    genericParameterPosition?: number;
    genericTypeArguments?: SystemTypeRead[] | null;
    guid?: string;
    hasElementType?: boolean;
    isAbstract?: boolean;
    isAnsiClass?: boolean;
    isArray?: boolean;
    isAutoClass?: boolean;
    isAutoLayout?: boolean;
    isByRef?: boolean;
    isByRefLike?: boolean;
    isClass?: boolean;
    isCollectible?: boolean;
    isCOMObject?: boolean;
    isConstructedGenericType?: boolean;
    isContextful?: boolean;
    isEnum?: boolean;
    isExplicitLayout?: boolean;
    isFunctionPointer?: boolean;
    isGenericMethodParameter?: boolean;
    isGenericParameter?: boolean;
    isGenericType?: boolean;
    isGenericTypeDefinition?: boolean;
    isGenericTypeParameter?: boolean;
    isImport?: boolean;
    isInterface?: boolean;
    isLayoutSequential?: boolean;
    isMarshalByRef?: boolean;
    isNested?: boolean;
    isNestedAssembly?: boolean;
    isNestedFamANDAssem?: boolean;
    isNestedFamily?: boolean;
    isNestedFamORAssem?: boolean;
    isNestedPrivate?: boolean;
    isNestedPublic?: boolean;
    isNotPublic?: boolean;
    isPointer?: boolean;
    isPrimitive?: boolean;
    isPublic?: boolean;
    isSealed?: boolean;
    isSecurityCritical?: boolean;
    isSecuritySafeCritical?: boolean;
    isSecurityTransparent?: boolean;
    isSerializable?: boolean;
    isSignatureType?: boolean;
    isSpecialName?: boolean;
    isSZArray?: boolean;
    isTypeDefinition?: boolean;
    isUnicodeClass?: boolean;
    isUnmanagedFunctionPointer?: boolean;
    isValueType?: boolean;
    isVariableBoundArray?: boolean;
    isVisible?: boolean;
    memberType?: SystemReflectionMemberTypes;
    metadataToken?: number;
    module?: SystemReflectionModuleRead;
    name?: string | null;
    namespace?: string | null;
    reflectedType?: SystemTypeRead;
    structLayoutAttribute?: SystemRuntimeInteropServicesStructLayoutAttributeRead;
    typeHandle?: SystemRuntimeTypeHandle;
    typeInitializer?: SystemReflectionConstructorInfoRead;
    underlyingSystemType?: SystemTypeRead;
};
export type MicrosoftODataEdmEdmTypeKind =
    | 'None'
    | 'Primitive'
    | 'Entity'
    | 'Complex'
    | 'Collection'
    | 'EntityReference'
    | 'Enum'
    | 'TypeDefinition'
    | 'Untyped'
    | 'Path';
export type MicrosoftODataEdmIEdmType = {
    typeKind?: MicrosoftODataEdmEdmTypeKind;
};
export type MicrosoftODataEdmVocabulariesIEdmDirectValueAnnotationsManager =
    object;
export type MicrosoftODataEdmEdmSchemaElementKind =
    | 'None'
    | 'TypeDefinition'
    | 'Term'
    | 'Action'
    | 'EntityContainer'
    | 'Function';
export type MicrosoftODataEdmIEdmEntityContainer = {
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
};
export type MicrosoftODataEdmEdmContainerElementKind =
    | 'None'
    | 'EntitySet'
    | 'ActionImport'
    | 'FunctionImport'
    | 'Singleton';
export type MicrosoftODataEdmIEdmEntityContainerElement = {
    container?: MicrosoftODataEdmIEdmEntityContainer;
    containerElementKind?: MicrosoftODataEdmEdmContainerElementKind;
};
export type MicrosoftODataEdmIEdmEntityContainerElementRead = {
    container?: MicrosoftODataEdmIEdmEntityContainerRead;
    containerElementKind?: MicrosoftODataEdmEdmContainerElementKind;
    name?: string | null;
};
export type MicrosoftODataEdmIEdmEntityContainerRead = {
    elements?: MicrosoftODataEdmIEdmEntityContainerElementRead[] | null;
    name?: string | null;
    namespace?: string | null;
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
};
export type MicrosoftODataEdmIEdmModel = {
    directValueAnnotationsManager?: MicrosoftODataEdmVocabulariesIEdmDirectValueAnnotationsManager;
    entityContainer?: MicrosoftODataEdmIEdmEntityContainer;
};
export type MicrosoftODataEdmIEdmSchemaElement = {
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
};
export type MicrosoftODataEdmIEdmSchemaElementRead = {
    name?: string | null;
    namespace?: string | null;
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
};
export type MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotatable = object;
export type MicrosoftODataEdmIEdmTypeReference = {
    definition?: MicrosoftODataEdmIEdmType;
};
export type MicrosoftODataEdmIEdmTypeReferenceRead = {
    definition?: MicrosoftODataEdmIEdmType;
    isNullable?: boolean;
};
export type MicrosoftODataEdmVocabulariesIEdmTerm = {
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
    type?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataEdmVocabulariesIEdmTermRead = {
    appliesTo?: string | null;
    defaultValue?: string | null;
    name?: string | null;
    namespace?: string | null;
    schemaElementKind?: MicrosoftODataEdmEdmSchemaElementKind;
    type?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataEdmEdmExpressionKind =
    | 'None'
    | 'BinaryConstant'
    | 'BooleanConstant'
    | 'DateTimeOffsetConstant'
    | 'DecimalConstant'
    | 'FloatingConstant'
    | 'GuidConstant'
    | 'IntegerConstant'
    | 'StringConstant'
    | 'DurationConstant'
    | 'Null'
    | 'Record'
    | 'Collection'
    | 'Path'
    | 'If'
    | 'Cast'
    | 'IsType'
    | 'FunctionApplication'
    | 'LabeledExpressionReference'
    | 'Labeled'
    | 'PropertyPath'
    | 'NavigationPropertyPath'
    | 'DateConstant'
    | 'TimeOfDayConstant'
    | 'EnumMember'
    | 'AnnotationPath';
export type MicrosoftODataEdmIEdmExpression = {
    expressionKind?: MicrosoftODataEdmEdmExpressionKind;
};
export type MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotation = {
    target?: MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotatable;
    term?: MicrosoftODataEdmVocabulariesIEdmTerm;
    value?: MicrosoftODataEdmIEdmExpression;
};
export type MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotationRead = {
    qualifier?: string | null;
    target?: MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotatable;
    term?: MicrosoftODataEdmVocabulariesIEdmTermRead;
    value?: MicrosoftODataEdmIEdmExpression;
};
export type MicrosoftODataEdmIEdmModelRead = {
    declaredNamespaces?: string[] | null;
    directValueAnnotationsManager?: MicrosoftODataEdmVocabulariesIEdmDirectValueAnnotationsManager;
    entityContainer?: MicrosoftODataEdmIEdmEntityContainerRead;
    referencedModels?: MicrosoftODataEdmIEdmModelRead[] | null;
    schemaElements?: MicrosoftODataEdmIEdmSchemaElementRead[] | null;
    vocabularyAnnotations?:
        | MicrosoftODataEdmVocabulariesIEdmVocabularyAnnotationRead[]
        | null;
};
export type MicrosoftODataEdmIEdmPathExpression = {
    expressionKind?: MicrosoftODataEdmEdmExpressionKind;
};
export type MicrosoftODataEdmIEdmPathExpressionRead = {
    expressionKind?: MicrosoftODataEdmEdmExpressionKind;
    path?: string | null;
    pathSegments?: string[] | null;
};
export type MicrosoftODataEdmIEdmNavigationSource = {
    path?: MicrosoftODataEdmIEdmPathExpression;
    type?: MicrosoftODataEdmIEdmType;
};
export type MicrosoftODataEdmIEdmStructuredType = {
    baseType?: MicrosoftODataEdmIEdmStructuredType;
    typeKind?: MicrosoftODataEdmEdmTypeKind;
};
export type MicrosoftODataEdmEdmPropertyKind =
    | 'None'
    | 'Structural'
    | 'Navigation';
export type MicrosoftODataEdmIEdmProperty = {
    declaringType?: MicrosoftODataEdmIEdmStructuredType;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    type?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataEdmIEdmPropertyRead = {
    declaringType?: MicrosoftODataEdmIEdmStructuredTypeRead;
    name?: string | null;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    type?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataEdmIEdmStructuredTypeRead = {
    baseType?: MicrosoftODataEdmIEdmStructuredTypeRead;
    declaredProperties?: MicrosoftODataEdmIEdmPropertyRead[] | null;
    isAbstract?: boolean;
    isOpen?: boolean;
    typeKind?: MicrosoftODataEdmEdmTypeKind;
};
export type MicrosoftODataEdmEdmOnDeleteAction = 'None' | 'Cascade';
export type MicrosoftODataEdmIEdmReferentialConstraint = {};
export type MicrosoftODataEdmIEdmStructuralProperty = {
    declaringType?: MicrosoftODataEdmIEdmStructuredType;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    type?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataEdmIEdmStructuralPropertyRead = {
    declaringType?: MicrosoftODataEdmIEdmStructuredTypeRead;
    defaultValueString?: string | null;
    name?: string | null;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    type?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataEdmEdmReferentialConstraintPropertyPair = {
    dependentProperty?: MicrosoftODataEdmIEdmStructuralProperty;
    principalProperty?: MicrosoftODataEdmIEdmStructuralProperty;
};
export type MicrosoftODataEdmEdmReferentialConstraintPropertyPairRead = {
    dependentProperty?: MicrosoftODataEdmIEdmStructuralPropertyRead;
    principalProperty?: MicrosoftODataEdmIEdmStructuralPropertyRead;
};
export type MicrosoftODataEdmIEdmReferentialConstraintRead = {
    propertyPairs?:
        | MicrosoftODataEdmEdmReferentialConstraintPropertyPairRead[]
        | null;
};
export type MicrosoftODataEdmIEdmNavigationProperty = {
    declaringType?: MicrosoftODataEdmIEdmStructuredType;
    onDelete?: MicrosoftODataEdmEdmOnDeleteAction;
    partner?: MicrosoftODataEdmIEdmNavigationProperty;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    referentialConstraint?: MicrosoftODataEdmIEdmReferentialConstraint;
    type?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataEdmIEdmNavigationPropertyRead = {
    containsTarget?: boolean;
    declaringType?: MicrosoftODataEdmIEdmStructuredTypeRead;
    name?: string | null;
    onDelete?: MicrosoftODataEdmEdmOnDeleteAction;
    partner?: MicrosoftODataEdmIEdmNavigationPropertyRead;
    propertyKind?: MicrosoftODataEdmEdmPropertyKind;
    referentialConstraint?: MicrosoftODataEdmIEdmReferentialConstraintRead;
    type?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataEdmIEdmNavigationPropertyBinding = {
    navigationProperty?: MicrosoftODataEdmIEdmNavigationProperty;
    path?: MicrosoftODataEdmIEdmPathExpression;
    target?: MicrosoftODataEdmIEdmNavigationSource;
};
export type MicrosoftODataEdmIEdmNavigationPropertyBindingRead = {
    navigationProperty?: MicrosoftODataEdmIEdmNavigationPropertyRead;
    path?: MicrosoftODataEdmIEdmPathExpressionRead;
    target?: MicrosoftODataEdmIEdmNavigationSourceRead;
};
export type MicrosoftODataEdmIEdmNavigationSourceRead = {
    name?: string | null;
    navigationPropertyBindings?:
        | MicrosoftODataEdmIEdmNavigationPropertyBindingRead[]
        | null;
    path?: MicrosoftODataEdmIEdmPathExpressionRead;
    type?: MicrosoftODataEdmIEdmType;
};
export type MicrosoftODataUriParserODataPathSegment = {
    edmType?: MicrosoftODataEdmIEdmType;
    identifier?: string | null;
};
export type SystemIServiceProvider = object;
export type MicrosoftAspNetCoreODataQueryODataQueryContext = {
    defaultQueryConfigurations?: MicrosoftAspNetCoreODataQueryDefaultQueryConfigurations;
    elementClrType?: SystemType;
    elementType?: MicrosoftODataEdmIEdmType;
    model?: MicrosoftODataEdmIEdmModel;
    navigationSource?: MicrosoftODataEdmIEdmNavigationSource;
    path?: MicrosoftODataUriParserODataPathSegment[] | null;
    requestContainer?: SystemIServiceProvider;
};
export type MicrosoftAspNetCoreODataQueryODataQueryContextRead = {
    defaultQueryConfigurations?: MicrosoftAspNetCoreODataQueryDefaultQueryConfigurations;
    elementClrType?: SystemTypeRead;
    elementType?: MicrosoftODataEdmIEdmType;
    model?: MicrosoftODataEdmIEdmModelRead;
    navigationSource?: MicrosoftODataEdmIEdmNavigationSourceRead;
    path?: MicrosoftODataUriParserODataPathSegment[] | null;
    requestContainer?: SystemIServiceProvider;
};
export type MicrosoftAspNetCoreODataQueryApplyQueryOption = {
    applyClause?: MicrosoftODataUriParserAggregationApplyClause;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    resultClrType?: SystemType;
};
export type MicrosoftAspNetCoreODataQueryApplyQueryOptionRead = {
    applyClause?: MicrosoftODataUriParserAggregationApplyClause;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    resultClrType?: SystemTypeRead;
};
export type MicrosoftODataUriParserQueryNodeKind =
    | 'None'
    | 'Constant'
    | 'Convert'
    | 'NonResourceRangeVariableReference'
    | 'BinaryOperator'
    | 'UnaryOperator'
    | 'SingleValuePropertyAccess'
    | 'CollectionPropertyAccess'
    | 'SingleValueFunctionCall'
    | 'Any'
    | 'CollectionNavigationNode'
    | 'SingleNavigationNode'
    | 'SingleValueOpenPropertyAccess'
    | 'SingleResourceCast'
    | 'All'
    | 'CollectionResourceCast'
    | 'ResourceRangeVariableReference'
    | 'SingleResourceFunctionCall'
    | 'CollectionFunctionCall'
    | 'CollectionResourceFunctionCall'
    | 'NamedFunctionParameter'
    | 'ParameterAlias'
    | 'EntitySet'
    | 'KeyLookup'
    | 'SearchTerm'
    | 'CollectionOpenPropertyAccess'
    | 'CollectionComplexNode'
    | 'SingleComplexNode'
    | 'Count'
    | 'SingleValueCast'
    | 'CollectionPropertyNode'
    | 'AggregatedCollectionPropertyNode'
    | 'In'
    | 'CollectionConstant';
export type MicrosoftODataUriParserSingleValueNode = {
    kind?: MicrosoftODataUriParserQueryNodeKind;
    typeReference?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataUriParserSingleValueNodeRead = {
    kind?: MicrosoftODataUriParserQueryNodeKind;
    typeReference?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataUriParserComputeExpression = {
    alias?: string | null;
    expression?: MicrosoftODataUriParserSingleValueNode;
    typeReference?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataUriParserComputeExpressionRead = {
    alias?: string | null;
    expression?: MicrosoftODataUriParserSingleValueNodeRead;
    typeReference?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataUriParserComputeClause = {
    computedItems?: MicrosoftODataUriParserComputeExpression[] | null;
};
export type MicrosoftODataUriParserComputeClauseRead = {
    computedItems?: MicrosoftODataUriParserComputeExpressionRead[] | null;
};
export type MicrosoftAspNetCoreODataQueryValidatorIComputeQueryValidator =
    object;
export type MicrosoftAspNetCoreODataQueryComputeQueryOption = {
    computeClause?: MicrosoftODataUriParserComputeClause;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    resultClrType?: SystemType;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIComputeQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryComputeQueryOptionRead = {
    computeClause?: MicrosoftODataUriParserComputeClauseRead;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    resultClrType?: SystemTypeRead;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIComputeQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryValidatorICountQueryValidator = object;
export type MicrosoftAspNetCoreODataQueryCountQueryOption = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorICountQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryCountQueryOptionRead = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorICountQueryValidator;
    value?: boolean;
};
export type MicrosoftODataUriParserRangeVariable = {
    typeReference?: MicrosoftODataEdmIEdmTypeReference;
};
export type MicrosoftODataUriParserRangeVariableRead = {
    kind?: number;
    name?: string | null;
    typeReference?: MicrosoftODataEdmIEdmTypeReferenceRead;
};
export type MicrosoftODataUriParserFilterClause = {
    expression?: MicrosoftODataUriParserSingleValueNode;
    itemType?: MicrosoftODataEdmIEdmTypeReference;
    rangeVariable?: MicrosoftODataUriParserRangeVariable;
};
export type MicrosoftODataUriParserFilterClauseRead = {
    expression?: MicrosoftODataUriParserSingleValueNodeRead;
    itemType?: MicrosoftODataEdmIEdmTypeReferenceRead;
    rangeVariable?: MicrosoftODataUriParserRangeVariableRead;
};
export type MicrosoftAspNetCoreODataQueryValidatorIFilterQueryValidator =
    object;
export type MicrosoftAspNetCoreODataQueryFilterQueryOption = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOption;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    filterClause?: MicrosoftODataUriParserFilterClause;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIFilterQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryFilterQueryOptionRead = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOptionRead;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    filterClause?: MicrosoftODataUriParserFilterClauseRead;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIFilterQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryETag601FeedlotManagerDataEntitiesMedicalAnimal =
    {
        entityType?: SystemType;
        isAny?: boolean;
        isIfNoneMatch?: boolean;
        isWellFormed?: boolean;
    };
export type MicrosoftAspNetCoreODataQueryETag601FeedlotManagerDataEntitiesMedicalAnimalRead =
    {
        entityType?: SystemTypeRead;
        isAny?: boolean;
        isIfNoneMatch?: boolean;
        isWellFormed?: boolean;
    };
export type MicrosoftODataUriParserOrderByDirection =
    | 'Ascending'
    | 'Descending';
export type MicrosoftODataUriParserOrderByClause = {
    direction?: MicrosoftODataUriParserOrderByDirection;
    expression?: MicrosoftODataUriParserSingleValueNode;
    itemType?: MicrosoftODataEdmIEdmTypeReference;
    rangeVariable?: MicrosoftODataUriParserRangeVariable;
    thenBy?: MicrosoftODataUriParserOrderByClause;
};
export type MicrosoftODataUriParserOrderByClauseRead = {
    direction?: MicrosoftODataUriParserOrderByDirection;
    expression?: MicrosoftODataUriParserSingleValueNodeRead;
    itemType?: MicrosoftODataEdmIEdmTypeReferenceRead;
    rangeVariable?: MicrosoftODataUriParserRangeVariableRead;
    thenBy?: MicrosoftODataUriParserOrderByClauseRead;
};
export type MicrosoftAspNetCoreODataQueryValidatorIOrderByQueryValidator =
    object;
export type MicrosoftAspNetCoreODataQueryOrderByQueryOption = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOption;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    orderByClause?: MicrosoftODataUriParserOrderByClause;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIOrderByQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryOrderByNode = {
    direction?: MicrosoftODataUriParserOrderByDirection;
};
export type MicrosoftAspNetCoreODataQueryOrderByQueryOptionRead = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOptionRead;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    orderByClause?: MicrosoftODataUriParserOrderByClauseRead;
    orderByNodes?: MicrosoftAspNetCoreODataQueryOrderByNode[] | null;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorIOrderByQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryODataRawQueryOptions = {};
export type MicrosoftAspNetCoreODataQueryODataRawQueryOptionsRead = {
    apply?: string | null;
    compute?: string | null;
    count?: string | null;
    deltaToken?: string | null;
    expand?: string | null;
    filter?: string | null;
    format?: string | null;
    orderBy?: string | null;
    search?: string | null;
    select?: string | null;
    skip?: string | null;
    skipToken?: string | null;
    top?: string | null;
};
export type SystemCollectionsGenericKeyValuePair602SystemStringSystemString = {
    key?: string | null;
    value?: string | null;
};
export type SystemCollectionsGenericKeyValuePair602SystemStringMicrosoftExtensionsPrimitivesStringValues =
    {
        key?: string | null;
        value?: string[];
    };
export type MicrosoftAspNetCoreHttpHostString = {
    value?: string | null;
};
export type MicrosoftAspNetCoreHttpHostStringRead = {
    hasValue?: boolean;
    host?: string | null;
    port?: number | null;
    value?: string | null;
};
export type SystemSecurityCryptographyOid = {
    friendlyName?: string | null;
    value?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesX500DistinguishedName = {
    oid?: SystemSecurityCryptographyOid;
    rawData?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesX500DistinguishedNameRead =
    {
        name?: string | null;
        oid?: SystemSecurityCryptographyOid;
        rawData?: string | null;
    };
export type SystemSecurityCryptographyAsymmetricAlgorithm = {
    keySize?: number;
};
export type SystemSecurityCryptographyKeySizes = {
    maxSize?: number;
    minSize?: number;
    skipSize?: number;
};
export type SystemSecurityCryptographyAsymmetricAlgorithmRead = {
    keyExchangeAlgorithm?: string | null;
    keySize?: number;
    legalKeySizes?: SystemSecurityCryptographyKeySizes[] | null;
    signatureAlgorithm?: string | null;
};
export type SystemSecurityCryptographyAsnEncodedData = {
    oid?: SystemSecurityCryptographyOid;
    rawData?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesPublicKey = {
    encodedKeyValue?: SystemSecurityCryptographyAsnEncodedData;
    encodedParameters?: SystemSecurityCryptographyAsnEncodedData;
    key?: SystemSecurityCryptographyAsymmetricAlgorithm;
    oid?: SystemSecurityCryptographyOid;
};
export type SystemSecurityCryptographyX509CertificatesPublicKeyRead = {
    encodedKeyValue?: SystemSecurityCryptographyAsnEncodedData;
    encodedParameters?: SystemSecurityCryptographyAsnEncodedData;
    key?: SystemSecurityCryptographyAsymmetricAlgorithmRead;
    oid?: SystemSecurityCryptographyOid;
};
export type SystemReadOnlySpan601SystemByte = {};
export type SystemReadOnlySpan601SystemByteRead = {
    isEmpty?: boolean;
    length?: number;
};
export type SystemReadOnlyMemory601SystemByte = {
    span?: SystemReadOnlySpan601SystemByte;
};
export type SystemReadOnlyMemory601SystemByteRead = {
    isEmpty?: boolean;
    length?: number;
    span?: SystemReadOnlySpan601SystemByteRead;
};
export type SystemSecurityCryptographyX509CertificatesX509Certificate2 = {
    archived?: boolean;
    friendlyName?: string | null;
    handle?: SystemIntPtr;
    issuerName?: SystemSecurityCryptographyX509CertificatesX500DistinguishedName;
    privateKey?: SystemSecurityCryptographyAsymmetricAlgorithm;
    publicKey?: SystemSecurityCryptographyX509CertificatesPublicKey;
    rawDataMemory?: SystemReadOnlyMemory601SystemByte;
    serialNumberBytes?: SystemReadOnlyMemory601SystemByte;
    signatureAlgorithm?: SystemSecurityCryptographyOid;
    subjectName?: SystemSecurityCryptographyX509CertificatesX500DistinguishedName;
};
export type SystemSecurityCryptographyX509CertificatesX509Extension = {
    critical?: boolean;
    oid?: SystemSecurityCryptographyOid;
    rawData?: string | null;
};
export type SystemSecurityCryptographyX509CertificatesX509Certificate2Read = {
    archived?: boolean;
    extensions?:
        | SystemSecurityCryptographyX509CertificatesX509Extension[]
        | null;
    friendlyName?: string | null;
    handle?: SystemIntPtr;
    hasPrivateKey?: boolean;
    issuer?: string | null;
    issuerName?: SystemSecurityCryptographyX509CertificatesX500DistinguishedNameRead;
    notAfter?: string;
    notBefore?: string;
    privateKey?: SystemSecurityCryptographyAsymmetricAlgorithmRead;
    publicKey?: SystemSecurityCryptographyX509CertificatesPublicKeyRead;
    rawData?: string | null;
    rawDataMemory?: SystemReadOnlyMemory601SystemByteRead;
    serialNumber?: string | null;
    serialNumberBytes?: SystemReadOnlyMemory601SystemByteRead;
    signatureAlgorithm?: SystemSecurityCryptographyOid;
    subject?: string | null;
    subjectName?: SystemSecurityCryptographyX509CertificatesX500DistinguishedNameRead;
    thumbprint?: string | null;
    version?: number;
};
export type SystemNetSocketsAddressFamily =
    | 'Unspecified'
    | 'Unix'
    | 'InterNetwork'
    | 'ImpLink'
    | 'Pup'
    | 'Chaos'
    | 'NS'
    | 'Iso'
    | 'Ecma'
    | 'DataKit'
    | 'Ccitt'
    | 'Sna'
    | 'DecNet'
    | 'DataLink'
    | 'Lat'
    | 'HyperChannel'
    | 'AppleTalk'
    | 'NetBios'
    | 'VoiceView'
    | 'FireFox'
    | 'Banyan'
    | 'Atm'
    | 'InterNetworkV6'
    | 'Cluster'
    | 'Ieee12844'
    | 'Irda'
    | 'NetworkDesigners'
    | 'Max'
    | 'Packet'
    | 'ControllerAreaNetwork'
    | 'Unknown';
export type SystemNetIpAddress = {
    address?: number;
    addressFamily?: SystemNetSocketsAddressFamily;
    scopeId?: number;
};
export type SystemNetIpAddressRead = {
    address?: number;
    addressFamily?: SystemNetSocketsAddressFamily;
    isIPv4MappedToIPv6?: boolean;
    isIPv6LinkLocal?: boolean;
    isIPv6Multicast?: boolean;
    isIPv6SiteLocal?: boolean;
    isIPv6Teredo?: boolean;
    isIPv6UniqueLocal?: boolean;
    scopeId?: number;
};
export type MicrosoftAspNetCoreHttpConnectionInfo = {
    clientCertificate?: SystemSecurityCryptographyX509CertificatesX509Certificate2;
    id?: string | null;
    localIpAddress?: SystemNetIpAddress;
    localPort?: number;
    remoteIpAddress?: SystemNetIpAddress;
    remotePort?: number;
};
export type MicrosoftAspNetCoreHttpConnectionInfoRead = {
    clientCertificate?: SystemSecurityCryptographyX509CertificatesX509Certificate2Read;
    id?: string | null;
    localIpAddress?: SystemNetIpAddressRead;
    localPort?: number;
    remoteIpAddress?: SystemNetIpAddressRead;
    remotePort?: number;
};
export type MicrosoftWin32SafeHandlesSafeWaitHandle = {};
export type MicrosoftWin32SafeHandlesSafeWaitHandleRead = {
    isClosed?: boolean;
    isInvalid?: boolean;
};
export type SystemThreadingWaitHandle = {
    handle?: SystemIntPtr;
    safeWaitHandle?: MicrosoftWin32SafeHandlesSafeWaitHandle;
};
export type SystemThreadingWaitHandleRead = {
    handle?: SystemIntPtr;
    safeWaitHandle?: MicrosoftWin32SafeHandlesSafeWaitHandleRead;
};
export type SystemThreadingCancellationToken = {
    waitHandle?: SystemThreadingWaitHandle;
};
export type SystemThreadingCancellationTokenRead = {
    canBeCanceled?: boolean;
    isCancellationRequested?: boolean;
    waitHandle?: SystemThreadingWaitHandleRead;
};
export type SystemIoPipelinesPipeWriter = {};
export type SystemIoPipelinesPipeWriterRead = {
    canGetUnflushedBytes?: boolean;
    unflushedBytes?: number;
};
export type MicrosoftAspNetCoreHttpIResponseCookies = object;
export type MicrosoftAspNetCoreHttpHttpResponse = {
    body?: Blob | null;
    bodyWriter?: SystemIoPipelinesPipeWriter;
    contentLength?: number | null;
    contentType?: string | null;
    cookies?: MicrosoftAspNetCoreHttpIResponseCookies;
    httpContext?: MicrosoftAspNetCoreHttpHttpContext;
    statusCode?: number;
};
export type MicrosoftAspNetCoreHttpHttpResponseRead = {
    body?: Blob | null;
    bodyWriter?: SystemIoPipelinesPipeWriterRead;
    contentLength?: number | null;
    contentType?: string | null;
    cookies?: MicrosoftAspNetCoreHttpIResponseCookies;
    hasStarted?: boolean;
    headers?: {
        [key: string]: string[];
    } | null;
    httpContext?: MicrosoftAspNetCoreHttpHttpContext;
    statusCode?: number;
};
export type MicrosoftAspNetCoreHttpISession = {};
export type MicrosoftAspNetCoreHttpISessionRead = {
    id?: string | null;
    isAvailable?: boolean;
    keys?: string[] | null;
};
export type SystemSecurityPrincipalIIdentity = {};
export type SystemSecurityPrincipalIIdentityRead = {
    authenticationType?: string | null;
    isAuthenticated?: boolean;
    name?: string | null;
};
export type SystemSecurityClaimsClaimsPrincipal = {
    identity?: SystemSecurityPrincipalIIdentity;
};
export type SystemSecurityClaimsClaimsIdentity = {
    actor?: SystemSecurityClaimsClaimsIdentity;
    bootstrapContext?: any | null;
    label?: string | null;
};
export type SystemSecurityClaimsClaimsIdentityRead = {
    actor?: SystemSecurityClaimsClaimsIdentityRead;
    authenticationType?: string | null;
    bootstrapContext?: any | null;
    claims?: SystemSecurityClaimsClaim[] | null;
    isAuthenticated?: boolean;
    label?: string | null;
    name?: string | null;
    nameClaimType?: string | null;
    roleClaimType?: string | null;
};
export type SystemSecurityClaimsClaim = {
    subject?: SystemSecurityClaimsClaimsIdentity;
};
export type SystemSecurityClaimsClaimRead = {
    issuer?: string | null;
    originalIssuer?: string | null;
    properties?: {
        [key: string]: string;
    } | null;
    subject?: SystemSecurityClaimsClaimsIdentityRead;
    type?: string | null;
    value?: string | null;
    valueType?: string | null;
};
export type SystemSecurityClaimsClaimsPrincipalRead = {
    claims?: SystemSecurityClaimsClaimRead[] | null;
    identities?: SystemSecurityClaimsClaimsIdentityRead[] | null;
    identity?: SystemSecurityPrincipalIIdentityRead;
};
export type MicrosoftAspNetCoreHttpWebSocketManager = {};
export type MicrosoftAspNetCoreHttpWebSocketManagerRead = {
    isWebSocketRequest?: boolean;
    webSocketRequestedProtocols?: string[] | null;
};
export type MicrosoftAspNetCoreHttpHttpContext = {
    connection?: MicrosoftAspNetCoreHttpConnectionInfo;
    items?: {
        [key: string]: any | null;
    } | null;
    request?: MicrosoftAspNetCoreHttpHttpRequest;
    requestAborted?: SystemThreadingCancellationToken;
    requestServices?: SystemIServiceProvider;
    response?: MicrosoftAspNetCoreHttpHttpResponse;
    session?: MicrosoftAspNetCoreHttpISession;
    traceIdentifier?: string | null;
    user?: SystemSecurityClaimsClaimsPrincipal;
    webSockets?: MicrosoftAspNetCoreHttpWebSocketManager;
};
export type SystemCollectionsGenericKeyValuePair602SystemTypeSystemObject = {
    key?: SystemType;
    value?: any | null;
};
export type SystemCollectionsGenericKeyValuePair602SystemTypeSystemObjectRead =
    {
        key?: SystemTypeRead;
        value?: any | null;
    };
export type MicrosoftAspNetCoreHttpHttpContextRead = {
    connection?: MicrosoftAspNetCoreHttpConnectionInfoRead;
    features?:
        | SystemCollectionsGenericKeyValuePair602SystemTypeSystemObjectRead[]
        | null;
    items?: {
        [key: string]: any | null;
    } | null;
    request?: MicrosoftAspNetCoreHttpHttpRequest;
    requestAborted?: SystemThreadingCancellationTokenRead;
    requestServices?: SystemIServiceProvider;
    response?: MicrosoftAspNetCoreHttpHttpResponseRead;
    session?: MicrosoftAspNetCoreHttpISessionRead;
    traceIdentifier?: string | null;
    user?: SystemSecurityClaimsClaimsPrincipalRead;
    webSockets?: MicrosoftAspNetCoreHttpWebSocketManagerRead;
};
export type MicrosoftAspNetCoreHttpPathString = {
    value?: string | null;
};
export type MicrosoftAspNetCoreHttpPathStringRead = {
    hasValue?: boolean;
    value?: string | null;
};
export type MicrosoftAspNetCoreHttpQueryString = {
    value?: string | null;
};
export type MicrosoftAspNetCoreHttpQueryStringRead = {
    hasValue?: boolean;
    value?: string | null;
};
export type MicrosoftAspNetCoreHttpHttpRequest = {
    body?: Blob | null;
    contentLength?: number | null;
    contentType?: string | null;
    cookies?:
        | SystemCollectionsGenericKeyValuePair602SystemStringSystemString[]
        | null;
    form?:
        | SystemCollectionsGenericKeyValuePair602SystemStringMicrosoftExtensionsPrimitivesStringValues[]
        | null;
    host?: MicrosoftAspNetCoreHttpHostString;
    httpContext?: MicrosoftAspNetCoreHttpHttpContext;
    isHttps?: boolean;
    method?: string | null;
    path?: MicrosoftAspNetCoreHttpPathString;
    pathBase?: MicrosoftAspNetCoreHttpPathString;
    protocol?: string | null;
    query?:
        | SystemCollectionsGenericKeyValuePair602SystemStringMicrosoftExtensionsPrimitivesStringValues[]
        | null;
    queryString?: MicrosoftAspNetCoreHttpQueryString;
    routeValues?: {
        [key: string]: any;
    } | null;
    scheme?: string | null;
};
export type MicrosoftAspNetCoreHttpHttpRequestRead = {
    body?: Blob | null;
    bodyReader?: Blob | null;
    contentLength?: number | null;
    contentType?: string | null;
    cookies?:
        | SystemCollectionsGenericKeyValuePair602SystemStringSystemString[]
        | null;
    form?:
        | SystemCollectionsGenericKeyValuePair602SystemStringMicrosoftExtensionsPrimitivesStringValues[]
        | null;
    hasFormContentType?: boolean;
    headers?: {
        [key: string]: string[];
    } | null;
    host?: MicrosoftAspNetCoreHttpHostStringRead;
    httpContext?: MicrosoftAspNetCoreHttpHttpContextRead;
    isHttps?: boolean;
    method?: string | null;
    path?: MicrosoftAspNetCoreHttpPathStringRead;
    pathBase?: MicrosoftAspNetCoreHttpPathStringRead;
    protocol?: string | null;
    query?:
        | SystemCollectionsGenericKeyValuePair602SystemStringMicrosoftExtensionsPrimitivesStringValues[]
        | null;
    queryString?: MicrosoftAspNetCoreHttpQueryStringRead;
    routeValues?: {
        [key: string]: any;
    } | null;
    scheme?: string | null;
};
export type MicrosoftODataUriParserSearchClause = {
    expression?: MicrosoftODataUriParserSingleValueNode;
};
export type MicrosoftODataUriParserSearchClauseRead = {
    expression?: MicrosoftODataUriParserSingleValueNodeRead;
};
export type MicrosoftAspNetCoreODataQuerySearchQueryOption = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    resultClrType?: SystemType;
    searchClause?: MicrosoftODataUriParserSearchClause;
};
export type MicrosoftAspNetCoreODataQuerySearchQueryOptionRead = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    resultClrType?: SystemTypeRead;
    searchClause?: MicrosoftODataUriParserSearchClauseRead;
};
export type MicrosoftODataUriParserSelectItem = object;
export type MicrosoftODataUriParserSelectExpandClause = {
    allSelected?: boolean;
    selectedItems?: MicrosoftODataUriParserSelectItem[] | null;
};
export type MicrosoftAspNetCoreODataQueryValidatorISelectExpandQueryValidator =
    object;
export type MicrosoftAspNetCoreODataQuerySelectExpandQueryOption = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOption;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    levelsMaxLiteralExpansionDepth?: number;
    selectExpandClause?: MicrosoftODataUriParserSelectExpandClause;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISelectExpandQueryValidator;
};
export type MicrosoftAspNetCoreODataQuerySelectExpandQueryOptionRead = {
    compute?: MicrosoftAspNetCoreODataQueryComputeQueryOptionRead;
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    levelsMaxLiteralExpansionDepth?: number;
    rawExpand?: string | null;
    rawSelect?: string | null;
    selectExpandClause?: MicrosoftODataUriParserSelectExpandClause;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISelectExpandQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryValidatorISkipQueryValidator = object;
export type MicrosoftAspNetCoreODataQuerySkipQueryOption = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISkipQueryValidator;
};
export type MicrosoftAspNetCoreODataQuerySkipQueryOptionRead = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISkipQueryValidator;
    value?: number;
};
export type MicrosoftAspNetCoreODataQuerySkipTokenHandler = object;
export type MicrosoftAspNetCoreODataQueryValidatorISkipTokenQueryValidator =
    object;
export type MicrosoftAspNetCoreODataQuerySkipTokenQueryOption = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    handler?: MicrosoftAspNetCoreODataQuerySkipTokenHandler;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISkipTokenQueryValidator;
};
export type MicrosoftAspNetCoreODataQuerySkipTokenQueryOptionRead = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    handler?: MicrosoftAspNetCoreODataQuerySkipTokenHandler;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorISkipTokenQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryValidatorITopQueryValidator = object;
export type MicrosoftAspNetCoreODataQueryTopQueryOption = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorITopQueryValidator;
};
export type MicrosoftAspNetCoreODataQueryTopQueryOptionRead = {
    context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
    rawValue?: string | null;
    validator?: MicrosoftAspNetCoreODataQueryValidatorITopQueryValidator;
    value?: number;
};
export type MicrosoftAspNetCoreODataQueryValidatorIoDataQueryValidator = object;
export type MicrosoftAspNetCoreODataQueryODataQueryOptions601FeedlotManagerDataEntitiesMedicalAnimal =
    {
        apply?: MicrosoftAspNetCoreODataQueryApplyQueryOption;
        compute?: MicrosoftAspNetCoreODataQueryComputeQueryOption;
        context?: MicrosoftAspNetCoreODataQueryODataQueryContext;
        count?: MicrosoftAspNetCoreODataQueryCountQueryOption;
        filter?: MicrosoftAspNetCoreODataQueryFilterQueryOption;
        ifMatch?: MicrosoftAspNetCoreODataQueryETag601FeedlotManagerDataEntitiesMedicalAnimal;
        ifNoneMatch?: MicrosoftAspNetCoreODataQueryETag601FeedlotManagerDataEntitiesMedicalAnimal;
        orderBy?: MicrosoftAspNetCoreODataQueryOrderByQueryOption;
        rawValues?: MicrosoftAspNetCoreODataQueryODataRawQueryOptions;
        request?: MicrosoftAspNetCoreHttpHttpRequest;
        search?: MicrosoftAspNetCoreODataQuerySearchQueryOption;
        selectExpand?: MicrosoftAspNetCoreODataQuerySelectExpandQueryOption;
        skip?: MicrosoftAspNetCoreODataQuerySkipQueryOption;
        skipToken?: MicrosoftAspNetCoreODataQuerySkipTokenQueryOption;
        top?: MicrosoftAspNetCoreODataQueryTopQueryOption;
        validator?: MicrosoftAspNetCoreODataQueryValidatorIoDataQueryValidator;
    };
export type MicrosoftAspNetCoreODataQueryODataQueryOptions601FeedlotManagerDataEntitiesMedicalAnimalRead =
    {
        apply?: MicrosoftAspNetCoreODataQueryApplyQueryOptionRead;
        compute?: MicrosoftAspNetCoreODataQueryComputeQueryOptionRead;
        context?: MicrosoftAspNetCoreODataQueryODataQueryContextRead;
        count?: MicrosoftAspNetCoreODataQueryCountQueryOptionRead;
        filter?: MicrosoftAspNetCoreODataQueryFilterQueryOptionRead;
        ifMatch?: MicrosoftAspNetCoreODataQueryETag601FeedlotManagerDataEntitiesMedicalAnimalRead;
        ifNoneMatch?: MicrosoftAspNetCoreODataQueryETag601FeedlotManagerDataEntitiesMedicalAnimalRead;
        orderBy?: MicrosoftAspNetCoreODataQueryOrderByQueryOptionRead;
        rawValues?: MicrosoftAspNetCoreODataQueryODataRawQueryOptionsRead;
        request?: MicrosoftAspNetCoreHttpHttpRequestRead;
        search?: MicrosoftAspNetCoreODataQuerySearchQueryOptionRead;
        selectExpand?: MicrosoftAspNetCoreODataQuerySelectExpandQueryOptionRead;
        skip?: MicrosoftAspNetCoreODataQuerySkipQueryOptionRead;
        skipToken?: MicrosoftAspNetCoreODataQuerySkipTokenQueryOptionRead;
        top?: MicrosoftAspNetCoreODataQueryTopQueryOptionRead;
        validator?: MicrosoftAspNetCoreODataQueryValidatorIoDataQueryValidator;
    };
export type FeedlotManagerCommonEnumsEidCsvOnDuplicateAction =
    | 'Error'
    | 'Ignore'
    | 'Update';
