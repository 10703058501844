import FocusTrap from 'focus-trap-react';
import { Spinner } from 'react-bootstrap';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../../Redux/Store';
import '../styles/appLoaderStyles.css';

export const AppLoader = () => {
    const { showAppLoader, allowOutsideClick } = useAppSelector(
        state => state.appLoader,
    );

    //necessary to prevent user scrolling while loading
    useEffect(() => {
        if (showAppLoader) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [showAppLoader]);

    return !showAppLoader ? null : (
        //focus trap prevents users from using keyboard to modify data. Focus is trapped in element with id 'default-focus-trap-element' found in App.tsx, rendering keyboard useless until loading is complete.
        <FocusTrap
            focusTrapOptions={{
                allowOutsideClick,
                fallbackFocus:
                    document.getElementById('default-focus-trap-element') ??
                    undefined,
            }}
        >
            <div
                id="app-loader"
                className="d-flex justify-content-center align-items-center full-page-app-loader"
            >
                <div>
                    <Spinner
                        animation="border"
                        data-size="lg"
                        className="text-center"
                    />
                </div>
            </div>
        </FocusTrap>
    );
};
