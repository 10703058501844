import { baseFMSettingsApi } from './baseFMSettingsApi';

export const fmSettingsApi = baseFMSettingsApi.enhanceEndpoints({
    addTagTypes: [],
    endpoints: {
        putApiFmFmsettingsById: {
            invalidatesTags: (result, error, arg) =>
                !error
                    ? [
                          {
                              type: 'FMSettings',
                              id: arg.feedlotManagerModelsFmSettingModel
                                  .fmSettingId,
                          },
                      ]
                    : [],
        },
        postApiFmFmsettings: {
            invalidatesTags: (result, error, arg) =>
                !error
                    ? [
                          {
                              type: 'FMSettings',
                              id: arg.feedlotManagerModelsFmSettingModel
                                  .fmSettingId,
                          },
                      ]
                    : [],
        },
        getApiFmFmsettingsNamesByName: {
            providesTags: (result, error, arg) => [
                { type: 'FMSettings', id: arg.name },
            ],
        },
    },
});
