import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../Redux/Store';
import { userManagementActions } from '../reducer/userManagementSlice';
import {
    Button,
    Col,
    Form,
    OverlayTrigger,
    Row,
    Tooltip,
} from 'react-bootstrap';
import { USER_MANAGEMENT_INITIAL_STATE } from '../constants/userManagementInitialState';
import StaticOption from '../../../../../../components/common/StaticOption';
import { useTranslation } from 'react-i18next';
import {
    ILocalFilter,
    ISelectStatus,
    UserStatusEnum,
} from '../interfaces/UserManagementInterface';
import {
    spaceRoleName,
    useGetDataForFilterDropdowns,
    useGetUserManagementUsersQueryArgs,
} from '../hooks/userManagementHooks';
import {
    Roles,
    RolesByAppDropdownOptionForFilter,
} from '../../../../../common/modules/auth/constants/roles';
import { AppDropdownOptionForFilter } from '../../../../../common/modules/auth/constants/appDropdownOption';
import InfoIcon from '../../../../../../Assets/InfoIcon';
import { InformationalTooltip } from '../../../../../../components/common/InformationalTooltip';
import { IGenericSetState } from '../../../../../../utils/Interface/CommonInterface';
import { feedlotManagerFeatures } from '../../../../featureFlags/feedlotManagerFeatures';
import { useDebouncedEffect } from '../../../../../../components/useDebounceEffect';

const {
    isCA13333On_InformationalTooltipComponent,
    isCA13360On_FixUserManagementTableHeading,
} = feedlotManagerFeatures;

export const UserManagementFilter = ({
    localFilter,
    setLocalFilter,
}: {
    localFilter: ILocalFilter;
    setLocalFilter: IGenericSetState<ILocalFilter>;
}) => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation('userManagement');
    const roles = useAppSelector(state => state.userManagement.roles);
    const userManagementFilter = useAppSelector(
        state => state.userManagement.userManagementFilter,
    );

    const { myAppsForFilterDropdown, myAccounts, editingPrivileges } =
        useGetDataForFilterDropdowns();

    const statusHandler = (status: UserStatusEnum) => {
        const filterCopy = {
            ...userManagementFilter,
            ...localFilter,
            status,
            offset: USER_MANAGEMENT_INITIAL_STATE.userManagementFilter.offset,
            limit: USER_MANAGEMENT_INITIAL_STATE.userManagementFilter.limit,
        };

        dispatch(userManagementActions.setUserManagementFilter(filterCopy));
    };

    const debouncedFilterHandler = ({
        target,
    }: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = target;
        setLocalFilter({ ...localFilter, [name]: value });
    };

    useDebouncedEffect(
        () => {
            if (
                userManagementFilter.name !== localFilter.name ||
                userManagementFilter.email !== localFilter.email
            ) {
                dispatch(
                    userManagementActions.setUserManagementFilter({
                        ...userManagementFilter,
                        ...localFilter,
                        offset: USER_MANAGEMENT_INITIAL_STATE
                            .userManagementFilter.offset,
                        limit: USER_MANAGEMENT_INITIAL_STATE
                            .userManagementFilter.limit,
                    }),
                );
            }
        },
        [dispatch, localFilter, userManagementFilter],
        500,
    );

    const filterHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const fieldName = target.name;
        const fieldValue =
            target.name === 'accountId' ? +target.value : target.value;

        const filterCopy = {
            ...userManagementFilter,
            ...localFilter,
            [fieldName]: fieldValue,
            offset: USER_MANAGEMENT_INITIAL_STATE.userManagementFilter.offset,
            limit: USER_MANAGEMENT_INITIAL_STATE.userManagementFilter.limit,
        };

        if (fieldName === 'app') {
            filterCopy.accountId = 0;
            filterCopy.role = '';
            const rolesForAppFilter: Roles[] =
                RolesByAppDropdownOptionForFilter[fieldValue] ?? [];
            dispatch(userManagementActions.setRoles(rolesForAppFilter));
        }
        if (
            fieldValue === t('userManagementLabels.staticOptions.selectApp') ||
            fieldValue === t('userManagementLabels.staticOptions.selectRole')
        ) {
            filterCopy[fieldName] = '';
        }
        dispatch(userManagementActions.setUserManagementFilter(filterCopy));
    };

    const queryArgs = useGetUserManagementUsersQueryArgs();

    const clearFilters = () => {
        if (queryArgs.filter || queryArgs.role) {
            setLocalFilter({
                name: USER_MANAGEMENT_INITIAL_STATE.userManagementFilter.name,
                email: USER_MANAGEMENT_INITIAL_STATE.userManagementFilter.email,
            });
            dispatch(
                userManagementActions.setUserManagementFilter(
                    USER_MANAGEMENT_INITIAL_STATE.userManagementFilter,
                ),
            );
        }
    };

    const renderEditUserRulesTooltip = props => (
        <Tooltip {...props}>
            {editingPrivileges.isAdmin
                ? t(
                      'userManagementLabels.tooltips.editAndSetPasswordRulesForAdmin',
                  )
                : t(
                      'userManagementLabels.tooltips.editAndSetPasswordRulesForNonAdmin',
                  )}
        </Tooltip>
    );

    return (
        <Row className="mb-3">
            <Col>
                <Form.Group
                    className={
                        isCA13360On_FixUserManagementTableHeading ? '' : 'ms-3'
                    }
                >
                    <Form.Label>
                        {t('userManagementLabels.staticOptions.name')}
                    </Form.Label>
                    <Form.Control
                        className="text-secondary form-item border border-rounded"
                        size="sm"
                        placeholder={t(
                            'userManagementLabels.staticOptions.searchUser',
                        )}
                        value={localFilter.name}
                        name="name"
                        onChange={debouncedFilterHandler}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label>
                        {t('userManagementLabels.staticOptions.email')}
                    </Form.Label>
                    <Form.Control
                        className="text-secondary form-item border border-rounded"
                        size="sm"
                        placeholder={t(
                            'userManagementLabels.staticOptions.searchEmail',
                        )}
                        value={localFilter.email}
                        name="email"
                        onChange={debouncedFilterHandler}
                    />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label>
                        {t('userManagementLabels.staticOptions.apps')}
                    </Form.Label>
                    <Form.Control
                        className="text-secondary form-item border border-rounded"
                        as="select"
                        size="sm"
                        value={userManagementFilter.app}
                        name="app"
                        onChange={filterHandler}
                    >
                        <option>
                            {t('userManagementLabels.staticOptions.selectApp')}
                        </option>
                        {myAppsForFilterDropdown?.map(app => (
                            <option key={app}>{app}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label>
                        {t('userManagementLabels.staticOptions.feedlot')}
                    </Form.Label>
                    <Form.Control
                        className="text-secondary form-item border border-rounded"
                        as="select"
                        size="sm"
                        value={userManagementFilter.accountId}
                        disabled={
                            userManagementFilter.app !==
                            AppDropdownOptionForFilter['Feedlot Manager']
                        }
                        name="accountId"
                        onChange={filterHandler}
                    >
                        <StaticOption
                            options={t(
                                'userManagementLabels.staticOptions.selectFeedlot',
                                { returnObjects: true },
                            )}
                        />
                        {myAccounts.map(account => (
                            <option
                                key={account.accountId}
                                value={account.accountId}
                            >
                                {account.name}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group>
                    <Form.Label>
                        {t('userManagementLabels.staticOptions.roles')}
                    </Form.Label>
                    <Form.Control
                        className="text-secondary form-item border border-rounded"
                        as="select"
                        size="sm"
                        value={userManagementFilter.role}
                        name="role"
                        onChange={filterHandler}
                    >
                        <option>
                            {t('userManagementLabels.staticOptions.selectRole')}
                        </option>
                        {roles?.map(role => (
                            <option key={role}>{spaceRoleName(role)}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Col>
            <Col className="d-flex align-items-end">
                <div
                    className="btn-group show-toggle"
                    data-toggle="buttons"
                    role="group"
                >
                    {(
                        t('userManagementLabels.staticOptions.selectStatus', {
                            returnObjects: true,
                        }) as ISelectStatus[]
                    ).map(status => (
                        <label
                            key={status.name}
                            className={`btn btn-secondary py-1 ${
                                userManagementFilter.status === status.status
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <input
                                type="radio"
                                name="status"
                                onChange={() => statusHandler(status.status)}
                                checked={
                                    userManagementFilter.status ===
                                    status.status
                                }
                            />
                            {status.name}
                        </label>
                    ))}
                </div>
            </Col>
            <Col className="d-flex align-items-end">
                <Button className="secondary" onClick={clearFilters}>
                    {t('userManagementLabels.staticOptions.clearFilters')}
                </Button>
            </Col>
            <Col className="d-flex align-items-end justify-content-end">
                <span className="px-1 pb-1">
                    {isCA13333On_InformationalTooltipComponent ? (
                        <InformationalTooltip
                            iconClassName="pb-1"
                            tooltipContent={
                                editingPrivileges.isAdmin
                                    ? t(
                                          'userManagementLabels.tooltips.editAndSetPasswordRulesForAdmin',
                                      )
                                    : t(
                                          'userManagementLabels.tooltips.editAndSetPasswordRulesForNonAdmin',
                                      )
                            }
                        />
                    ) : (
                        <OverlayTrigger
                            placement="top"
                            overlay={renderEditUserRulesTooltip}
                        >
                            <span className="me-4">
                                <InfoIcon
                                    className="pb-1"
                                    style={{
                                        width: '24px',
                                    }}
                                />
                            </span>
                        </OverlayTrigger>
                    )}
                </span>
            </Col>
        </Row>
    );
};
