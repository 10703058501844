import { isRejected, Middleware } from '@reduxjs/toolkit';
import { setDisplayTimeoutErrorModal } from '../Reducer/common';

export const rtkQueryErrorLogger: Middleware =
    ({ dispatch }) =>
    next =>
    action => {
        if (isRejected(action)) {
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            const errorPayload = action.payload as any;
            const error = errorPayload?.status;
            if (error === 'TIMEOUT_ERROR') {
                dispatch(setDisplayTimeoutErrorModal(true));
            }
        }
        return next(action);
    };
