import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfoIcon from '../../Assets/InfoIcon';
import { Placement } from 'react-bootstrap/esm/types';

export const InformationalTooltip = ({
    tooltipContent,
    iconClassName,
    placement = 'top',
    width = '24px',
}: {
    tooltipContent: string;
    iconClassName?: string;
    placement?: Placement;
    width?: string;
}) => {
    const renderInformationalTooltip = props => {
        return <Tooltip {...props}>{tooltipContent}</Tooltip>;
    };
    return (
        <OverlayTrigger
            placement={placement}
            overlay={renderInformationalTooltip}
        >
            <span className="ms-1 my-1">
                <InfoIcon style={{ width: width }} className={iconClassName} />
            </span>
        </OverlayTrigger>
    );
};
