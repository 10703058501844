import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/query';
import saveAs from 'file-saver';
import { useFeedlotId } from '../../../../../../commonHooks/useFeedlotId';
import { fmSettingsApi } from '../../../../../../Redux/Apis/FMSettings/fmSettingsApi';
import { QuickBookConstant } from '../../../../../../utils/constants/QuickBookConstant';
import { FeedlotManagerDataEnumsFmSettingNameEnum } from '../../../../../../Redux/Apis/FMSettings/baseFMSettingsApi';
import { quickBooksApi } from '../../../../../../Redux/Apis/QuickBooks/quickBooksApi';
import { useAppDispatch } from '../../../../../../Redux/Store';
import {
    setIsLoading,
    setISQuickBookAuthenticated,
    setQbSettingId,
} from '../../../../../../Redux/Reducer/quickBookSlice';
import { FeedlotManagerCommonEnumsQbConnectionFileType } from '../../../../../../Redux/Apis/QuickBooks/baseQuickBooksApi';
import { toast } from 'react-toastify';
import { useCallback, useEffect } from 'react';
import { toastMessage } from '../../../../../../Redux/Reducer/common';
import { getToastContent } from '../../../../../../utils/ToastContent';
import Constants from '../../../../../../utils/Constants';
import { feedlotManagerFeatures } from '../../../../featureFlags/feedlotManagerFeatures';

const { isCA12073On_QuickBooksIntegration } = feedlotManagerFeatures;

const stringToBoolean = (value: string) => {
    return value.toLowerCase() === 'true';
};

export const useGetFmSettingsForQuickBook = () => {
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const { data, error, isSuccess } =
        fmSettingsApi.useGetApiFmFmsettingsNamesByNameQuery(
            feedlotId && isCA12073On_QuickBooksIntegration
                ? {
                      name: QuickBookConstant.fmeSettingQuickBookRouteName,
                  }
                : skipToken,
        );

    useEffect(() => {
        if (error && 'status' in error) {
            if (error.status !== Constants.responseCode.NOT_FOUND) {
                dispatch(toastMessage(getToastContent(error.status)));
                dispatch(setISQuickBookAuthenticated(false));
            }
        }
    }, [dispatch, error]);

    useEffect(() => {
        if (data && isSuccess) {
            dispatch(setQbSettingId(Number(data.fmSettingId)));
            dispatch(
                setISQuickBookAuthenticated(
                    stringToBoolean(data.value ?? 'false'),
                ),
            );
        }
    }, [data, isSuccess, dispatch]);
};

export const useCreateFmSettingsForQuickBook = () => {
    const dispatch = useAppDispatch();
    const [createFmSettingQbIntegration] =
        fmSettingsApi.usePostApiFmFmsettingsMutation();

    const createFMSettingsQbIntegration = useCallback(async () => {
        try {
            const response = await createFmSettingQbIntegration({
                feedlotManagerModelsFmSettingModel: {
                    feedlotId: null,
                    name: QuickBookConstant.fmSettingsQuickBookRoute as FeedlotManagerDataEnumsFmSettingNameEnum,
                    value: String(true),
                },
            }).unwrap();

            if (response) {
                dispatch(setQbSettingId(response));
                dispatch(setISQuickBookAuthenticated(true));
            }
        } catch (error) {
            toast.error(QuickBookConstant.messages.errorFMSettingCreateQb);
            dispatch(setISQuickBookAuthenticated(false));
        }
    }, [dispatch, createFmSettingQbIntegration]);

    return { createFMSettingsQbIntegration };
};

export const useCreateUserQuickBooksConfig = (
    quickBookUserName: string,
    qbSettingId: number,
    closeNotifyConnectionModal: () => void,
) => {
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const [userQuickBooksConfig] =
        quickBooksApi.usePostApiFmQuickbooksUserByQbUserNameMutation();
    const [getUserQbRequestId] =
        quickBooksApi.useLazyGetApiFmQuickbooksUserByUserQbRequestIdQuery();

    const { createFMSettingsQbIntegration } = useCreateFmSettingsForQuickBook();

    const createUserQuickBooksConfig = async () => {
        dispatch(setIsLoading(true));

        if (!feedlotId || !quickBookUserName) {
            dispatch(setIsLoading(false));
            return;
        }

        try {
            const response = await userQuickBooksConfig({
                qbUserName: quickBookUserName,
            }).unwrap();
            let elapsedPollingTime = 0;

            const intervalId = setInterval(async () => {
                try {
                    const qbRequestIdResponse = await getUserQbRequestId({
                        userQbRequestId: Number(response),
                    }).unwrap();

                    if (stringToBoolean(qbRequestIdResponse.value ?? 'false')) {
                        createFMSettingsQbIntegration();
                        dispatch(setIsLoading(false));
                        closeNotifyConnectionModal();
                        toast.success(
                            QuickBookConstant.messages.fileAvailableMessage,
                        );
                        clearInterval(intervalId);
                    } else {
                        elapsedPollingTime +=
                            QuickBookConstant.quickBooksResponseTime
                                .pollingInterval;
                        if (
                            elapsedPollingTime >=
                            QuickBookConstant.quickBooksResponseTime
                                .pollingTimeout
                        ) {
                            dispatch(setIsLoading(false));
                            closeNotifyConnectionModal();
                            clearInterval(intervalId);
                        }
                    }
                } catch (pollError) {
                    dispatch(setIsLoading(false));
                    clearInterval(intervalId);
                }
            }, QuickBookConstant.quickBooksResponseTime.pollingInterval);
        } catch (error) {
            dispatch(setIsLoading(false));
        }
    };

    return { createUserQuickBooksConfig };
};

export const useDownloadQbConfigFile = () => {
    const [fetchQbConfigFile] =
        quickBooksApi.useLazyGetApiFmQuickbooksCfgfileQuery();

    const downloadQbConfigFile = async (
        fileType: FeedlotManagerCommonEnumsQbConnectionFileType,
        extension: string,
    ) => {
        const report = await fetchQbConfigFile({ fileType });

        if (report.isError) {
            toast.error(QuickBookConstant.messages.errorDownloadFileMessage);
        } else {
            if (report.data) {
                const blob = report.data as Blob;
                const fileName = `QuickBooks_${fileType}.${extension}`;
                saveAs(blob, fileName);
            }
        }
    };

    return { downloadQbConfigFile };
};

export const useUpdateFmSettingForQbConfig = () => {
    const dispatch = useAppDispatch();
    const [putApiFmFmsettingsById] =
        fmSettingsApi.usePutApiFmFmsettingsByIdMutation();

    const updateFmSettingQbConfig = (
        qbSettingId: number | null,
        isQuickBookSettingsEnabled: boolean,
    ) => {
        putApiFmFmsettingsById({
            feedlotManagerModelsFmSettingModel: {
                name: QuickBookConstant.fmeSettingQuickBookRouteName as FeedlotManagerDataEnumsFmSettingNameEnum,
                value: String(isQuickBookSettingsEnabled),
                feedlotId: null,
                fmSettingId: Number(qbSettingId),
            },
            id: Number(qbSettingId),
        })
            .unwrap()
            .then(response => {
                if (!isQuickBookSettingsEnabled) {
                    dispatch(setISQuickBookAuthenticated(false));
                } else {
                    dispatch(setISQuickBookAuthenticated(true));
                }
            })
            .catch((error: FetchBaseQueryError) => {
                dispatch(toastMessage(getToastContent(error.status)));
            });
        dispatch(setIsLoading(false));
    };

    return { updateFmSettingQbConfig };
};
