import { IHospitalInitialState } from '../../utils/Interface/HospitalInterface';

export const CREATE_HOSPITAL_INITIAL_STATE: IHospitalInitialState = {
    hospitalAnimalsList: [],
    hospitalPens: [],
    loading: true,
    loadingHistory: false,
    animalAdminHistory: [],
    hospitalAnimalsReports: [],
    movePens: [],
};
