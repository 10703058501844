interface IUsCurrencyFormatter {
    formatAsUSCurrency: (
        input: string | number,
        showDecimals?: boolean,
    ) => string;
}

const useUSCurrencyFormatter = (): IUsCurrencyFormatter => {
    const formatAsUSCurrency = (
        input: string | number,
        showDecimals = true,
    ): string => {
        const numberValue =
            typeof input === 'string' ? parseFloat(input) : input;
        if (isNaN(numberValue)) {
            return '';
        }
        const formattedCurrency = numberValue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: showDecimals ? 2 : 0,
            maximumFractionDigits: showDecimals ? 2 : 0,
        });

        return formattedCurrency.replace('$', '');
    };

    return { formatAsUSCurrency };
};

export default useUSCurrencyFormatter;
