import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IOverlayTooltip } from '../../utils/Interface/CommonInterface';

const OverlayTooltip = ({
    tooltipValue,
    endStringLength,
    startStrLength = 0,
}: IOverlayTooltip) => {
    const lengthIsValid = tooltipValue?.length > endStringLength;
    return (
        <>
            {lengthIsValid ? (
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{tooltipValue}</Tooltip>}
                >
                    <div>
                        {`${tooltipValue?.slice(
                            startStrLength,
                            endStringLength,
                        )}...`}
                    </div>
                </OverlayTrigger>
            ) : (
                <>{tooltipValue}</>
            )}
        </>
    );
};

export default OverlayTooltip;
