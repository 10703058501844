import { CommonConstants } from './constants/CommonConstants';

export const mobileNumberValidation = (value: any) => {
    const rgx = new RegExp(/^[0-9\b]+$/);
    return rgx.test(value);
};

export const numberTypeValidation = (value: any) => {
    const rgx = new RegExp('^[0-9]+$');
    return rgx.test(value);
};

export const floatNumberValidation = (value: any) => {
    const rgx = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');
    return rgx.test(value);
};

export const positiveFloatNumberValidation = (value: any) => {
    const rgx = new RegExp('^(?:[0-9]+(?:\\.[0-9]*)?|\\.[0-9]+)$');
    return rgx.test(value);
};

export const zipCodeValidation = (zipcode: any) => {
    const rgx = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    return rgx.test(zipcode);
};

export const canadaZipCodeValidation = (zipcode: any) => {
    const rgx = new RegExp(/^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/);
    return rgx.test(zipcode);
};

export const textTypeValidation = (text: any) => {
    const rgx = new RegExp('^[a-zA-Z ]*$');
    return rgx.test(text);
};

export const stringTypeValidation = (string: any) => {
    const rgxString = CommonConstants.onlySpaceAllowedAlphanumericRegex;
    const rgx = new RegExp(rgxString);
    return rgx.test(string);
};

export const standardKeyValidation = (value: any) => {
    const rgx = new RegExp(
        '^[a-zA-Z0-9`~!@#$%^&*()_\\-+=\\[\\]{}\\:"\'<>,.?/ ]*$',
    );
    return rgx.test(value);
};

export const coordinateValidation = (coordinates: any) => {
    const rgx = new RegExp(
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
    );
    return rgx.test(coordinates);
};

export const emailValidation = (email: any) => {
    const rgx = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
    return rgx.test(email);
};

export const mobileLengthValidation = (value: any) => {
    const mobileNo = value.replace(/\D/g, '');
    return (
        mobileNo.length === 10 ||
        (mobileNo.length === 11 && mobileNo.startsWith('1'))
    );
};

export const passwordValidation = (password: string): boolean => {
    const rgx = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{12,}$/,
    );
    return rgx.test(password);
};

export const checkIsEmpty = (value: any) => {
    if (!value) {
        return true;
    }
    return false;
};

export const percentageValidation = (value: number): boolean => {
    return value >= 0 && value <= 100;
};

const dateValidation = (date: any): boolean => {
    if (date instanceof Date) {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = String(date.getFullYear());
        const formattedDate = `${month}/${day}/${year}`;

        const rgx = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[1-2][0-9]|3[0-1])\/\d{4}$/;
        return rgx.test(formattedDate);
    }
    return false;
};

export const validateAccordingType = (
    type: string | null | undefined,
    value: string | number | boolean | Date | null,
) => {
    let finalValue = false;
    if (!value || !type) {
        return true;
    }
    switch (type) {
        case 'zipcode':
            finalValue = zipCodeValidation(value);
            break;
        case 'canadaZipcode':
            finalValue = canadaZipCodeValidation(value);
            break;
        case 'mobile':
            finalValue = mobileNumberValidation(value);
            break;
        case 'number':
            finalValue = numberTypeValidation(value);
            break;
        case 'float':
            finalValue =
                typeof value === 'string' && value.trim() === '-'
                    ? true
                    : floatNumberValidation(value);
            break;
        case 'positiveFloat':
            finalValue = positiveFloatNumberValidation(value);
            break;
        case 'text':
            finalValue = textTypeValidation(value);
            break;
        case 'string':
            finalValue = stringTypeValidation(value);
            break;
        case 'standardKeys':
            finalValue = standardKeyValidation(value);
            break;
        case 'coordinates':
            finalValue = coordinateValidation(value);
            break;
        case 'email':
            finalValue = emailValidation(value);
            break;
        case 'mobileLength':
            finalValue = mobileLengthValidation(value);
            break;
        case 'date':
            finalValue = dateValidation(value);
            break;
        case 'password':
            finalValue = passwordValidation(value.toString());
            break;
        case 'percentage':
            finalValue = percentageValidation(Number(value));
            break;
        default:
            finalValue = false;
            break;
    }
    return finalValue;
};
