import { NumericalOperations } from '../NumericalOperationPackage/numericalOperationsInterfaces';
import { FeedlotManagerDataEnumsMedicalDosageType } from '../Redux/Apis/MedicalItemVersion/baseMedicalItemVersionApi';

interface GenericMedicalItem {
    medicalItemId: string | number;
    medicalLot?: GenericMedicalLot[];
    medicalLotId?: string | number | null;
}

interface GenericMedicalLot {
    medicalLotId?: string | number | null;
    lotAmount?: number;
}

export const medicalLotHasInventory = (
    medicalLot: GenericMedicalLot,
): boolean => {
    return medicalLot.lotAmount && medicalLot.lotAmount > 0 ? true : false;
};

export const medicalItemHasInventory = <T extends GenericMedicalItem>(
    medicalItem?: T,
): boolean => {
    return medicalItem?.medicalLot?.some(medicalLotHasInventory) ?? false;
};

const medicalLotsSelectedForSameMedicalItem = (
    medicalItemCharges: GenericMedicalItem[],
    medicalItemId: number,
    indexForThisCharge: number,
) => {
    return medicalItemCharges
        .filter(
            (item, medicalItemChargeIndex) =>
                item.medicalLotId !== undefined &&
                medicalItemChargeIndex !== indexForThisCharge &&
                item.medicalItemId === medicalItemId,
        )
        .map(item => item.medicalLotId);
};

export const medicalLotAlreadyInUse = <
    T extends GenericMedicalItem,
    U extends GenericMedicalLot,
>(
    medicalItemCharges: T[],
    medicalItemId: number,
    medicalLot: U,
    indexForThisCharge: number,
): boolean => {
    if (medicalLot.medicalLotId) {
        return medicalLotsSelectedForSameMedicalItem(
            medicalItemCharges,
            medicalItemId,
            indexForThisCharge,
        ).includes(medicalLot.medicalLotId);
    } else {
        return false;
    }
};

interface MedicalItemWithDosages {
    dosageRate?: number;
    dosageType?: FeedlotManagerDataEnumsMedicalDosageType | string;
}

export const calculateDose = <T extends MedicalItemWithDosages>(
    medicalItem: T,
    weight: number,
) => {
    const dose = medicalItem.dosageRate
        ? medicalItem.dosageType === 'CC'
            ? Math.ceil(
                  NumericalOperations().divide(weight, 100) *
                      (medicalItem.dosageRate ?? 0),
              )
            : medicalItem.dosageRate
        : 0;
    return dose;
};
