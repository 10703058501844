import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setBearer } from '../../../../Services/AxiosService';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: '',
        logout: false,
    },
    reducers: {
        setAccessToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
            setBearer(action.payload);
        },
        setLogout: state => {
            state.logout = true;
        },
    },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
