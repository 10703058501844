import { useEffect } from 'react';
import SideNavTab from './sidenav/SideNavTab';
import SideNavBuyPO from './sidenav/SideNavBuyPO';
import SideNavFM from './sidenav/SideNavFM';
import SideNavFinancial from './sidenav/SideNavFinancial';
import SidebarLogoBuyPo from '../Assets/sidebarlogos/SidebarLogoBuyPo';
import SidebarLogoFinancial from '../Assets/sidebarlogos/SidebarLogoFinancial';
import SidebarLogoNGAT from '../Assets/sidebarlogos/SidebarLogoNGAT';
import SidebarLogoFM from '../Assets/sidebarlogos/SidebarLogoFM';
import useAuthContext from '../apps/common/modules/auth/hooks/useAuthContext';
import { useAppDispatch, useAppSelector } from '../Redux/Store';
import {
    IGenericSetState,
    sideNavTabs,
} from '../utils/Interface/CommonInterface';
import { useLocation } from 'react-router-dom';
import { setSideNavHidden } from '../Redux/Reducer/common';
import Constants from '../utils/Constants';
import { commonFeatures } from '../apps/common/featureFlags/commonFeatures';

const { isCA13765On_TokenManagementRework } = commonFeatures;

function SideNav({
    sideNavTab,
    setSideNavTab,
}: {
    sideNavTab: string | number;
    setSideNavTab: IGenericSetState<string | number>;
}) {
    const { isAuthenticated } = useAuthContext();

    const { isSideNavHidden, currentSelectedTab } = useAppSelector(
        state => state.common,
    );

    const dispatch = useAppDispatch();

    const handleClick = event => {
        isSideNavHidden
            ? dispatch(setSideNavHidden(false))
            : dispatch(setSideNavHidden(true));
    };
    const location = useLocation();

    useEffect(() => {
        if (
            location.pathname === '/feedcall' ||
            location.pathname === Constants.Routes.NEWFEEDCALLV1DOT1 ||
            location.pathname ===
                Constants.Routes.CREATE_INBOUND_SCALE_TICKETS ||
            location.pathname ===
                Constants.Routes.CREATE_OUTGOING_SCALE_TICKETS ||
            location.pathname === Constants.Routes.REALIZER_HISTORY ||
            location.pathname.includes(
                `${Constants.Routes.UPDATE_SHIP_CATTLE_REALIZER}`,
            ) ||
            location.pathname === '/treatments/addtreatment' ||
            location.pathname.includes('/treatments/updatetreatment/')
        ) {
            dispatch(setSideNavHidden(true));
        }
    }, [dispatch, location.pathname]);

    const logoMap = {
        [sideNavTabs.BuyPo]: <SidebarLogoBuyPo />,
        [sideNavTabs.Financial]: <SidebarLogoFinancial />,
        [sideNavTabs.Fm]: <SidebarLogoFM />,
        default: <SidebarLogoNGAT />,
    };
    const logoMap_deprecated = {
        buyPo: <SidebarLogoBuyPo />,
        financial: <SidebarLogoFinancial />,
        fm: <SidebarLogoFM />,
        default: <SidebarLogoNGAT />,
    };

    const menuMap = {
        [sideNavTabs.BuyPo]: <SideNavBuyPO />,
        [sideNavTabs.Financial]: <SideNavFinancial />,
        [sideNavTabs.Fm]: <SideNavFM />,
        default: <></>,
    };
    const menuMap_deprecated = {
        buyPo: <SideNavBuyPO />,
        financial: <SideNavFinancial />,
        fm: <SideNavFM />,
        default: <></>,
    };

    const logoComponent = isCA13765On_TokenManagementRework
        ? logoMap[currentSelectedTab] || logoMap.default
        : logoMap_deprecated[sideNavTab] || logoMap_deprecated.default;
    const menuComponent = isCA13765On_TokenManagementRework
        ? menuMap[currentSelectedTab] || menuMap.default
        : menuMap_deprecated[sideNavTab] || menuMap_deprecated.default;

    return (
        <>
            {isAuthenticated() ? (
                <>
                    <nav
                        className={
                            isSideNavHidden
                                ? 'd-md-active sidenav sidebar-shrink'
                                : 'd-md-active sidenav'
                        }
                    >
                        <div className="sidebar-logo">{logoComponent}</div>
                        <SideNavTab setSideNavTab={setSideNavTab} />
                        {menuComponent}
                    </nav>
                    <div
                        className={
                            isSideNavHidden
                                ? 'sidenav-caret caret-slide-left d-md-block d-none'
                                : 'sidenav-caret d-md-block d-none'
                        }
                        onClick={handleClick}
                    >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a>
                            <i className="fas fa-caret-left"></i>
                        </a>
                    </div>
                </>
            ) : null}
        </>
    );
}

export default SideNav;
