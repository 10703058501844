import { baseQuickBooksApi } from './baseQuickBooksApi';

export const quickBooksApi = baseQuickBooksApi.enhanceEndpoints({
    addTagTypes: [],
    endpoints: {
        getApiFmQuickbooksUserByUserQbRequestId: {
            providesTags: (result, error, arg) => [
                { type: 'QuickBooks', id: arg.userQbRequestId },
            ],
        },
        postApiFmQuickbooksUserByQbUserName: {
            invalidatesTags: (result, error, arg) =>
                !error
                    ? [
                          {
                              type: 'QuickBooks',
                              id: arg.qbUserName,
                          },
                      ]
                    : [],
        },
        getApiFmQuickbooksCfgfile: {
            query: queryArg => ({
                url: `/api/fm/quickbooks/cfgfile`,
                params: {
                    fileType: queryArg.fileType,
                },
                responseHandler: response => response.blob(),
            }),
            providesTags: ['QuickBooks'],
        },
    },
});
