import { currentDate_deprecated } from '../../helpers';
import { IBackdatedInfoModal } from '../../utils/Interface/CommonInterface';
import { LotState } from '../../utils/Interface/LotInterface';
import {
    IFee,
    IFeeError,
    IReceiveCattleDetail,
    IReceiveCattleDetailError,
    IReceiveCattleFilter,
    IReceiveCattleInitialDetails,
    IReceiveCattleLot,
    IReceiveCattleOrigin,
    IReceiveCattleOwner,
    IReceiveCattlePen,
    IReceiveCattlePenError,
} from '../../utils/Interface/ReceiveCattleInterface';
import {
    LabelConstant,
    receiveCattleLabelConstants,
} from '../../utils/LabelConstant';

/**
 * Create Receive Cattle initial states
 */
export const CREATE_RECEIVE_CATTLE_INITIAL_STATE: {
    receiveCattleInitialDetails: IReceiveCattleInitialDetails;
    receiveCattleDetail: IReceiveCattleDetail;
    receiveCattleDetailError: IReceiveCattleDetailError;
    receiveCattleAssignPens: IReceiveCattlePen[];
    receiveCattleAssignPensError: IReceiveCattlePenError[];
    receiveCattleFees: IFee[];
    receiveCattleFeesInitialState: IFee[];
    prefilledFeeTypes: string[];
    receiveCattleFeesError: IFeeError[];
    receiveCattleFee: IFee;
    receiveCattleFeeError: IFeeError;
    feeTotalAmount: number;
    generatedInvoiceCutoffDate: string;
    FEE_TYPES: { name: string; value: string }[];
    RECEIVE_CATTLE_STATIC_PEN: IReceiveCattlePen;
    RECEIVE_CATTLE_STATIC_PEN_ERROR: IReceiveCattlePenError;
    OWNERS: { name: string; id: string }[];
    LOTS: IReceiveCattleLot[];
    PENS: IReceiveCattlePen[];
    BUYERS: { name: string; id: string }[];
    BUY_LOCATIONS: { name: string; id: string }[];
    CATTLE_TYPES: { name: string; id: string }[];
    CATTLE_SEX: { name: string; id: string }[];
    FM_FEEDLOT: { name: string; id: string }[];
    lots: IReceiveCattleLot[];
    loading: boolean;
    filter: IReceiveCattleFilter;
    owners: IReceiveCattleOwner[];
    origins: IReceiveCattleOrigin[];
    receiveCattleReports: [];
    submitPending: boolean;
    backdatedInfoModal: IBackdatedInfoModal;
} = {
    receiveCattleInitialDetails: {
        payWeight: '',
        offTruckWeight: '',
    },
    receiveCattleDetail: {
        receiveId: '',
        dateReceived: currentDate_deprecated('YYYY-MM-DD'),
        hdCount: '',
        ownerId: '',
        notes: '',
        lotId: '',
        lot: {
            lotId: '',
            name: '',
            hdCount: '',
            feedLotId: '',
            ownerId: '',
            cattleTypeId: '',
            sexId: '',
        },
        assignedPens: [],
        buyerId: '',
        buyLocationId: '',
        offTruckWeight: '',
        payWeight: '',
        cattleTypeId: '',
        sexId: '',
        status: false,
    },
    receiveCattleDetailError: {
        dateReceived: '',
        hdCount: '',
        ownerId: '',
        lotId: '',
        buyLocationId: '',
        buyerId: '',
        offTruckWeight: '',
        cattleTypeId: '',
        sexId: '',
    },
    receiveCattleAssignPens: [{ penId: '', hdCount: '', name: '', id: '' }],
    receiveCattleAssignPensError: [{ penId: false, hdCount: false }],
    receiveCattleFees: [
        { id: '', costs: 'Cattle', amount: '', notes: '', isDisable: true },
        { id: '', costs: 'Freight', amount: '', notes: '', isDisable: true },
    ],
    receiveCattleFeesInitialState: [
        { id: '', costs: 'Cattle', amount: '', notes: '', isDisable: true },
        { id: '', costs: 'Freight', amount: '', notes: '', isDisable: true },
    ],
    prefilledFeeTypes: ['Cattle', 'Freight'],
    receiveCattleFeesError: [{ costs: '' }, { costs: '' }],
    receiveCattleFee: {
        id: '',
        costs: '',
        amount: '',
        notes: '',
        isDisable: false,
    },
    receiveCattleFeeError: { costs: '' },
    feeTotalAmount: 0,
    generatedInvoiceCutoffDate: '',
    FEE_TYPES: [
        {
            name: LabelConstant.FeeType.SELECT_FEE_TYPE,
            value: LabelConstant.FeeType.SELECT_FEE_TYPE,
        },
        {
            name: LabelConstant.FeeType.TYPE_CATTLE,
            value: LabelConstant.FeeType.TYPE_CATTLE,
        },
        {
            name: LabelConstant.FeeType.TYPE_COMMISSION,
            value: LabelConstant.FeeType.TYPE_COMMISSION,
        },
        {
            name: LabelConstant.FeeType.TYPE_FREIGHT,
            value: LabelConstant.FeeType.TYPE_FREIGHT,
        },
        {
            name: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
            value: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
        },
    ],
    RECEIVE_CATTLE_STATIC_PEN: {
        penId: '',
        hdCount: '',
        name: '',
        id: '',
    },
    RECEIVE_CATTLE_STATIC_PEN_ERROR: {
        penId: false,
        hdCount: false,
    },
    OWNERS: [
        {
            name: receiveCattleLabelConstants.Owner.CREATE_OWNER,
            id: receiveCattleLabelConstants.Owner.CREATE_OWNER,
        },
    ],
    LOTS: [
        {
            name: receiveCattleLabelConstants.Lot.CREATE_LOT,
            lotId: receiveCattleLabelConstants.Lot.CREATE_LOT,
            hdCount: '',
            feedLotId: '',
            ownerId: '',
            cattleTypeId: '',
            sexId: '',
        },
    ],
    PENS: [],
    BUYERS: [
        {
            name: receiveCattleLabelConstants.Buyer.CREATE_BUYER,
            id: receiveCattleLabelConstants.Buyer.CREATE_BUYER,
        },
    ],
    BUY_LOCATIONS: [
        {
            name: receiveCattleLabelConstants.BuyLocation.CREATE_BUYER_LOCATION,
            id: receiveCattleLabelConstants.BuyLocation.CREATE_BUYER_LOCATION,
        },
    ],
    CATTLE_TYPES: [
        {
            name: receiveCattleLabelConstants.CattleType.SELECT_CATTLE_TYPE,
            id: '',
        },
    ],
    CATTLE_SEX: [
        {
            name: receiveCattleLabelConstants.CattleSex.SELECT_CATTLE_SEX,
            id: '',
        },
    ],
    FM_FEEDLOT: [
        { name: receiveCattleLabelConstants.Feedlot.SELECT_FEEDLOT, id: '' },
    ],
    lots: [],
    loading: false,

    filter: {
        lotId: '',
        ownerId: '',
        complete: '',
        buyLocationId: '',
        hdCount: '',
        receiveId: '',
        toDate: '',
        fromDate: '',
        top: 20,
        skip: 0,
        state: [`'${LotState.Open}'`, `'${LotState.Closing}'`],
    },
    owners: [
        {
            address1: '',
            associatedLots: '',
            city: '',
            contactNumber: '',
            email: '',
            id: '',
            isActive: false,
            isAssociated: false,
            name: '',
            postalCode: '',
            state: '',
            totalHdCount: '',
        },
    ],
    origins: [
        {
            active: false,
            address1: '',
            address2: '',
            altContactNumber: '',
            city: '',
            contactName: '',
            contactNumber: '',
            coordinates: '',
            country: '',
            email: '',
            id: '',
            isAssociated: false,
            name: '',
            postalCode: '',
            state: '',
        },
    ],
    receiveCattleReports: [],
    submitPending: false,
    backdatedInfoModal: {
        showModal: false,
        method: '',
    },
};
