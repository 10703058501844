import { formatToCamelFromPascal } from '../Services/FormatMethods';
import { getToastContent } from './ToastContent';
import { t } from 'i18next';

interface ErrorBody {
    type: string;
    title: string;
    status: number;
    detail?: string;
    subtype?: string;
    errtype?: string;
    errid?: string;
}

export const confirmIsErrorType = error => {
    if (error.type && error.title && error.status) {
        return error as ErrorBody;
    }
};

export const hasDetailedErrorInfo = (error: ErrorBody) => {
    return error.type && error.title && error.subtype && error.errtype;
};

export const findErrorMessage = (
    error: ErrorBody,
    lookupTranslations = true,
) => {
    let errorMessage: string | undefined = '';
    if (lookupTranslations && error.type && error.subtype && error.errtype) {
        const { subtype, errtype } = error;

        errorMessage = t(
            `${formatToCamelFromPascal(subtype ?? '')}:errorMessages.${formatToCamelFromPascal(errtype ?? '')}`,
        );
    } else if (hasDetailedErrorInfo(error)) {
        errorMessage = error.title;
    } else if (error.status) {
        errorMessage = getToastContent(error.status).message;
    }

    return errorMessage ?? getToastContent(400).message;
};
