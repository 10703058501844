import {
    IDeleteShipPOIds,
    IShipPODetail,
    IShipPOFilter,
    IShipPoGlobalInfo,
    IShipPOViewSteps,
    IShipPoFee,
    IShipmentAndFeeTotal,
    IShipment,
} from '../interfaces/ShipPosInterface';
import { ShipPOFeesConstants } from './ShipPOConstants';

export const CREATE_SHIP_PO_INITIAL_STATE = {
    shipPOList: [] as IShipPODetail[],
    filter: {
        shipPOId: '',
        pickupFromDate: '',
        pickupToDate: '',
        location: '',
        hdCount: '',
        estimatedHeadCount: '',
        destination: '',
        truckingCompany: '',
        payee: '',
        cattleAR: '',
        isFreightPaid: '',
        limit: 20,
        offset: 0,
        show: 'incomplete',
    } as IShipPOFilter,
    shipPoGlobalInfo: {
        id: '',
        pickupDate: null,
        location: '',
        locationName: '',
        destination: '',
        destinationName: '',
        truckingCompany: '',
        truckingCompanyName: '',
        brandSlip: '',
        files: [],
        fileArray: [],
        isAttachFileDisable: false,
        createdBy: '',
        createdDatetime: '',
        submittedBy: '',
        submittedDateTime: '',
        modifiedBy: '',
        modifiedDatetime: '',
        invoiceNumber: '',
        shipmentNotes: '',
        shipPoPayee: '',
        shipPoPayeeName: '',
        payee: '',
        ccEmailState: false,
    } as IShipPoGlobalInfo,
    shipPoGlobalInfoError: {
        pickupDate: null,
        location: null,
        destination: null,
        truckingCompany: null,
    },
    shipPOViewSteps: {
        step1: 'flex',
        step1Values: 'none',
        step1Icon: 'none',
        step2: 'none',
        step2Values: 'none',
        step2Next: 'none',
        step2NextValues: 'none',
        step2Icon: 'none',
        step3: 'none',
        step3Values: 'none',
        step3Icon: 'none',
    } as IShipPOViewSteps,
    shipments: [
        {
            id: '',
            lot: '',
            lotError: '',
            broker: '',
            headCount: '',
            headCountError: '',
            estimatedHeadCount: '',
            estimatedHeadCountError: '',
            grossWeight: '',
            grossWeightError: '',
            estimatedGrossWeight: '',
            estimatedGrossWeightError: '',
            shrink: '',
            payWeight: '',
            totalDollars: '',
            owner: '',
            ownerName: '',
            ownerError: '',
        },
    ] as IShipment[],
    shipPoFees: [{ ...ShipPOFeesConstants.initialState.fee }] as IShipPoFee[],
    shipmentAndFeeTotal: {
        headCountTotal: 0,
        grossWeightTotal: 0.0,
        estimatedHeadCountTotal: 0,
        estimatedGrossWeightTotal: 0.0,
        payWeightTotal: 0,
        totalDollarsTotal: 0.0,
        feeAmountTotal: 0.0,
        avgGrossWeight: 0.0,
        grandTotal: 0.0,
    } as IShipmentAndFeeTotal,
    lastModifiedDate: '',
    lastModifiedUser: '',
    selectedDestinationDetail: {},
    loading: false,
    deleteShipPOIds: [] as IDeleteShipPOIds[],
    canCallService: true,
    paymentLoading: false,
    paymentInfo: {
        isCattleAR: false,
        isFreightPaid: false,
    },
    isSubmitted: false,
    SHIPMENTS_INITIAL: {
        id: '',
        lot: '',
        lotError: '',
        broker: '',
        estimatedHeadCount: '',
        estimatedHeadCountError: '',
        headCount: '',
        headCountError: '',
        estimatedGrossWeight: '',
        estimatedGrossWeightError: '',
        grossWeight: '',
        grossWeightError: '',
        payWeight: '',
        shrink: '',
        totalDollars: '',
        owner: '',
        ownerName: '',
        ownerError: '',
    } as IShipment,
    shouldAllowPageChange: {
        lastUpdate: false,
        showModal: false,
        route: '',
    },
};
