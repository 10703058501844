import { feedlotManagerFeatures } from '../../../../featureFlags/feedlotManagerFeatures';
const { isCA13466On_AddNoFeedCallOptionToRationFilter } =
    feedlotManagerFeatures;

export enum penFilterByRationDefaultIds {
    all = 'all',
    cattleNoFeedCall = 'cattleNoFeedCall',
    noFeedCall = 'noFeedCall',
    unassignedSchedules = 'unassignedSchedules',
}

export const disableablePenFilterByRationIds = [
    penFilterByRationDefaultIds.cattleNoFeedCall,
    penFilterByRationDefaultIds.noFeedCall,
    penFilterByRationDefaultIds.unassignedSchedules,
];

export const penFilterByRationDefaultOptions = [
    { name: 'All', id: penFilterByRationDefaultIds.all },
    {
        name: 'CATTLE NO FEED CALL',
        id: penFilterByRationDefaultIds.cattleNoFeedCall,
    },
    ...(isCA13466On_AddNoFeedCallOptionToRationFilter
        ? [
              {
                  name: 'NO FEED CALL',
                  id: penFilterByRationDefaultIds.noFeedCall,
              },
          ]
        : []),
    {
        name: 'UNASSIGNED SCHEDULES',
        id: penFilterByRationDefaultIds.unassignedSchedules,
    },
];
