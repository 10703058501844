import { Modal, Spinner } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../../../Redux/Store';
import { setIsLoading } from '../../../../../../Redux/Reducer/quickBookSlice';
import { QuickBookConstant } from '../../../../../../utils/constants/QuickBookConstant';
import { IQuickBooksNotifyConnectionModal } from '../../../../../../utils/Interface/QuickBookInterface';

const QuickBooksNotifyConnectionModal = ({
    showModal,
}: IQuickBooksNotifyConnectionModal) => {
    const dispatch = useAppDispatch();
    const { isLoading } = useAppSelector(state => state.quickBook);

    const onHide = () => {
        dispatch(setIsLoading(false));
    };

    return (
        <Modal
            className="px-2 pt-5"
            data-size="md"
            show={showModal}
            centered
            animation
            onHide={onHide}
            backdrop={'static'}
        >
            <Modal.Header className="border-0" />
            <Modal.Body className="text-center">
                {isLoading && (
                    <h5>
                        {QuickBookConstant.messages.connectionMomentMessage}
                    </h5>
                )}
                <Spinner animation="border" data-size="lg" />
            </Modal.Body>
        </Modal>
    );
};

export default QuickBooksNotifyConnectionModal;
