import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { processingChargeoutInitialState } from './ProcessingChargeoutInitialState';
import {
    IProcessingCharge,
    IProcessingPen,
    IProcessingWorkset,
    IProcessingWorksetFormErrors,
} from './ProcessingChargeoutInterfaces';
import { ProcessingSubmitButtonOptions } from '../../../../../../utils/Interface/HealthSection/LotProcessingWorksheet';

const processingChargeoutSlice = createSlice({
    name: 'processingChargeout',
    initialState: processingChargeoutInitialState,
    reducers: {
        setProcessingWorkset: (
            state,
            action: PayloadAction<Partial<IProcessingWorkset>>,
        ) => {
            for (const key in action.payload) {
                state.processingWorkset[key] = action.payload[key];
            }
        },
        updateProcessingPens: (
            state,
            action: PayloadAction<IProcessingPen[]>,
        ) => {
            state.processingWorkset.processingPens = action.payload;
            const updatedHeadCount =
                action.payload.reduce(
                    (sum, pen) => sum + pen.selectedHeadCount,
                    0,
                ) ?? 0;
            state.processingWorkset.charges.forEach(charge => {
                charge.hdCount = updatedHeadCount;
            });
        },
        setFirstAllowedDate: (state, action: PayloadAction<string>) => {
            state.firstAllowedDate = action.payload;
        },
        setFirstAllowedDateIsLoading: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.firstAllowedDateIsLoading = action.payload;
        },
        setProcessingWorksetErrors: (
            state,
            action: PayloadAction<IProcessingWorksetFormErrors>,
        ) => {
            state.formErrors = action.payload;
        },
        setActiveButton: (
            state,
            action: PayloadAction<ProcessingSubmitButtonOptions>,
        ) => {
            state.activeButton = action.payload;
        },
        addCharge: (
            state,
            action: PayloadAction<{ charge: IProcessingCharge; index: number }>,
        ) => {
            state.processingWorkset.charges.splice(
                action.payload.index,
                0,
                action.payload.charge,
            );
        },
        deleteCharge: (state, action: PayloadAction<number>) => {
            state.processingWorkset.charges.splice(action.payload, 1);
        },
        updateMedicalLotId: (
            state,
            action: PayloadAction<{
                medicalLotId: string | null;
                chargeIndex: number;
            }>,
        ) => {
            state.processingWorkset.charges[
                action.payload.chargeIndex
            ].medicalLotId = action.payload.medicalLotId;
        },
        updateDosage: (
            state,
            action: PayloadAction<{
                actualDose: number;
                chargeIndex: number;
            }>,
        ) => {
            state.processingWorkset.charges[
                action.payload.chargeIndex
            ].actualDose = action.payload.actualDose;
        },
        updateHeadCount: (
            state,
            action: PayloadAction<{ headCount: number; chargeIndex: number }>,
        ) => {
            state.processingWorkset.charges[
                action.payload.chargeIndex
            ].hdCount = action.payload.headCount;
        },
        resetState: () => {
            return processingChargeoutInitialState;
        },
    },
});

export const processingChargeoutActions = processingChargeoutSlice.actions;
export const processingChargeoutReducer = processingChargeoutSlice.reducer;
