import { getFeatureFlagValue } from '../../common/featureFlags/helpers';
import { IFinanceManagerFeatures } from './interface';

export const financeManagerFeatures: IFinanceManagerFeatures = {
    isFA201AutoSaveFeedBills: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA201_AUTO_SAVE_FEEDBILLES,
    ),
    isFA740ApiCallOptimized: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA740_API_CALL_OPTIMIZED,
    ),
    isFA507FixPrepaidTotal: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA507_FIX_PREPAID_TOTAL,
    ),
    isFA727SidetabButton: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA727_SIDE_TAB_BUTTON,
    ),
    isFA748HedgeProfitFieldIssue: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA748_HEDGE_PROFIT_FIELD_ISSUE,
    ),
    isFA722WarehousingAppLabel: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA722_WAREHOUSING_APP_LABEL,
    ),
    isFA747ClosedLotPurchaseRecordNonEditable: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_FA747_CLOSED_LOT_PURCHASE_RECORD_NON_EDITABLE,
    ),
    isFA750TypeAheadDropdownOpenHedge: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA750_TYPE_AHEAD_DROPDOWN_OPEN_HEDGE,
    ),
    isFA697FeedBillsMixingBetweenFeedlots: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA697_FEED_BILLS_MIXING_BETWEEN_FEEDLOTS,
    ),
    isFA702FeedbillPagination: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA702_FEEDBILL_PAGINATION,
    ),
    isFA693ModifiedAndCreateBy: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_FA693_ADD_MODIFIED_AND_CREATED_BY,
    ),
};
