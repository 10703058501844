import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FinancialRoutes from '../../utils/constants/FinancialApp/FinancialRoutes';
import {
    Roles,
    SideNavItems,
    privateAppRoutes,
} from '../../utils/constants/RoleConstants';
import { ISideNavTabButton } from '../../utils/Interface/SideNavTabInterface';
import { IPrivateRoute } from '../../utils/routes/Interface';
import {
    IGenericSetState,
    sideNavTabs,
} from '../../utils/Interface/CommonInterface';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { setRoute, setShowModal } from '../../Redux/Reducer/feedBillUpdate';
import { setSelectedSideNavTab } from '../../Redux/Reducer/common';
import Constants from '../../utils/Constants';
import { financeManagerFeatures } from '../../apps/financeManager/featureFlags/financeManagerFeatures';
import { setShouldAllowBuyPoPageChange } from '../../apps/shippingTracker/modules/BuyPo/actions/buyPoActions';
import { commonFeatures } from '../../apps/common/featureFlags/commonFeatures';
import { setShouldAllowShipPoPageChange } from '../../apps/shippingTracker/modules/shippo/reducers/shipPoReducer';

const SideNavTabButton = ({
    id,
    setSideNavTab,
    item,
    active,
    handleActive,
    usersRoles,
}: ISideNavTabButton) => {
    const { isFA727SidetabButton } = financeManagerFeatures;
    const { isCA13765On_TokenManagementRework } = commonFeatures;
    const navigate = useNavigate();
    const location = useLocation();
    const [buyPoRoutes, setBuyPoRoutes] = useState<string[]>([]);
    const [financialRoutes, setFinancialRoutes] = useState<string[]>([]);
    const [feedlotManagerRoutes, setFeedlotManagerRoutes] = useState<string[]>(
        [],
    );
    const { lastUpdate } = useAppSelector(state => state.feedBillUpdate);
    const { shouldAllowPageChange } = useAppSelector(state => state.buyPO);
    const { shouldAllowPageChange: shouldAllowShipPoPageChange } =
        useAppSelector(state => state.shipPO);

    const dispatch = useAppDispatch();

    //This useEffect is being replaced with isCA13765On_TokenManagementRework
    useEffect(() => {
        if (!isCA13765On_TokenManagementRework) {
            const setRoutesArray = (
                routeList: IPrivateRoute[] | undefined,
                setterFunction: IGenericSetState<string[]>,
            ) => {
                const routeArray = routeList?.map(route => route.path) ?? [];
                setterFunction(routeArray);
            };
            setRoutesArray(privateAppRoutes.buyPoRoutes.routes, setBuyPoRoutes);
            setRoutesArray(
                privateAppRoutes.financialRoutes.routes,
                setFinancialRoutes,
            );
            setRoutesArray(
                privateAppRoutes.feedlotManagerRoutes.routes,
                setFeedlotManagerRoutes,
            );
        }
    }, []);

    //This useEffect is being replaced with isCA13765On_TokenManagementRework
    useEffect(() => {
        if (!isCA13765On_TokenManagementRework) {
            const checkRouteExists = (
                routesArray: string[],
                pathname: string,
            ) =>
                routesArray.some((route: string) => {
                    const routeStrings = route.split('/');
                    if (
                        routeStrings[1] === pathname ||
                        (routeStrings[2] ? routeStrings[2] === pathname : false)
                    ) {
                        return true;
                    }
                    return false;
                });
            const routeMatchString = location.pathname.split('/')[1];
            if (location.pathname === '/') {
                handleActive('');
                setSideNavTab('');
                dispatch(setSelectedSideNavTab(sideNavTabs.NotSelected));
            } else if (checkRouteExists(buyPoRoutes, routeMatchString)) {
                handleActive(0);
                setSideNavTab('buyPo');
                dispatch(setSelectedSideNavTab(sideNavTabs.BuyPo));
            } else if (checkRouteExists(financialRoutes, routeMatchString)) {
                handleActive(1);
                setSideNavTab('financial');
                dispatch(setSelectedSideNavTab(sideNavTabs.Financial));
            } else if (
                checkRouteExists(feedlotManagerRoutes, routeMatchString)
            ) {
                handleActive(2);
                setSideNavTab('fm');
                dispatch(setSelectedSideNavTab(sideNavTabs.Fm));
            }
        }
    }, [
        buyPoRoutes,
        financialRoutes,
        feedlotManagerRoutes,
        location.pathname,
        active,
        handleActive,
        setSideNavTab,
        dispatch,
    ]);

    useEffect(() => {
        if (isCA13765On_TokenManagementRework) {
            const pathname = location.pathname.split('/')[1];

            const checkRouteExists = (routesArray: IPrivateRoute[]) =>
                routesArray.some(route => {
                    const [, first, second] = route.path.split('/');
                    return first === pathname || second === pathname;
                });

            const routeMapping = [
                {
                    routes: privateAppRoutes.buyPoRoutes.routes,
                    navTab: sideNavTabs.BuyPo,
                },
                {
                    routes: privateAppRoutes.financialRoutes.routes,
                    navTab: sideNavTabs.Financial,
                },
                {
                    routes: privateAppRoutes.feedlotManagerRoutes.routes,
                    navTab: sideNavTabs.Fm,
                },
            ];

            if (location.pathname === '/') {
                dispatch(setSelectedSideNavTab(sideNavTabs.NotSelected));
            } else {
                const matchedRoute = routeMapping.find(({ routes }) =>
                    checkRouteExists(routes),
                );

                if (matchedRoute) {
                    dispatch(setSelectedSideNavTab(matchedRoute.navTab));
                }
            }
        }
    }, [dispatch, location.pathname]);

    const handleNavigate = () => {
        if (
            isCA13765On_TokenManagementRework
                ? item.state === sideNavTabs.BuyPo
                : item.state === 'buyPo'
        ) {
            if (
                usersRoles.includes(Roles.ADMIN) ||
                usersRoles.includes(Roles.BUYER) ||
                usersRoles.includes(Roles.TENANT_ADMIN) ||
                usersRoles.includes(Roles.OFFICE_MANAGER) ||
                usersRoles.includes(Roles.FEEDLOT_MANAGER)
            ) {
                navigate(Constants.Routes.BUY_POS);
            } else if (usersRoles.includes(Roles.TRUCKING_COMPANY)) {
                navigate(Constants.Routes.TRUCKINGCOMPANY);
            }
        } else if (
            isCA13765On_TokenManagementRework
                ? item.state === sideNavTabs.Financial
                : item.state === 'financial'
        ) {
            navigate(FinancialRoutes.App.LOTS);
        } else if (
            isCA13765On_TokenManagementRework
                ? item.state === sideNavTabs.Fm
                : item.state === 'fm'
        ) {
            navigate(Constants.Routes.FM_DASHBOARD);
        } else {
            navigate('/');
        }
    };

    const handleClick = () => {
        if (lastUpdate) {
            dispatch(setShowModal(true));
            handleStoreRoute();
        } else if (shouldAllowPageChange.lastUpdate) {
            dispatch(
                setShouldAllowBuyPoPageChange({
                    ...shouldAllowPageChange,
                    showModal: true,
                }),
            );
            handleStoreRoute();
        } else if (shouldAllowShipPoPageChange.lastUpdate) {
            dispatch(
                setShouldAllowShipPoPageChange({
                    ...shouldAllowShipPoPageChange,
                    showModal: true,
                }),
            );
            handleStoreRoute();
        } else {
            handleNavigate();
        }

        if (!isCA13765On_TokenManagementRework) {
            setSideNavTab(item.state);
        }
    };

    const handleStoreRoute = () => {
        if (
            isCA13765On_TokenManagementRework
                ? item.state === sideNavTabs.BuyPo
                : item.state === 'buyPo'
        ) {
            dispatch(setRoute(Constants.Routes.BUY_POS));
        } else if (
            isCA13765On_TokenManagementRework
                ? item.state === sideNavTabs.Financial
                : item.state === 'financial'
        ) {
            dispatch(setRoute(FinancialRoutes.App.LOTS));
        } else if (
            isCA13765On_TokenManagementRework
                ? item.state === sideNavTabs.Fm
                : item.state === 'fm'
        ) {
            dispatch(setRoute(Constants.Routes.FM_DASHBOARD));
        } else {
            dispatch(setRoute('/'));
        }
    };

    return (
        <button
            className={`${active ? 'active' : ''}`}
            key={id}
            onClick={handleClick}
        >
            {item.label === SideNavItems.financialSideNavItem.label &&
            usersRoles.includes(Roles.WAREHOUSING_APP) &&
            isFA727SidetabButton
                ? Constants.headerConstants.warehouseFinance
                : item.label}
        </button>
    );
};

export default SideNavTabButton;
