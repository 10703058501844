import { rootApi as api } from '../rootApi';
export const addTagTypes = ['Auth'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: build => ({
            getApiTmAuth: build.query<
                GetApiTmAuthApiResponse,
                GetApiTmAuthApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/auth`,
                    params: {
                        account: queryArg.account,
                    },
                }),
                providesTags: ['Auth'],
            }),
            getApiTmAuthUser: build.query<
                GetApiTmAuthUserApiResponse,
                GetApiTmAuthUserApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/auth/user`,
                    params: {
                        id: queryArg.id,
                        name: queryArg.name,
                    },
                }),
                providesTags: ['Auth'],
            }),
            getApiTmAuthAccount: build.query<
                GetApiTmAuthAccountApiResponse,
                GetApiTmAuthAccountApiArg
            >({
                query: queryArg => ({
                    url: `/api/tm/auth/account`,
                    params: {
                        accountId: queryArg.accountId,
                    },
                }),
                providesTags: ['Auth'],
            }),
            getApiTmAuthRefresh: build.query<
                GetApiTmAuthRefreshApiResponse,
                GetApiTmAuthRefreshApiArg
            >({
                query: () => ({ url: `/api/tm/auth/refresh` }),
                providesTags: ['Auth'],
            }),
            getApiTmAuthTransient: build.query<
                GetApiTmAuthTransientApiResponse,
                GetApiTmAuthTransientApiArg
            >({
                query: () => ({ url: `/api/tm/auth/transient` }),
                providesTags: ['Auth'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as baseAuthApi };
export type GetApiTmAuthApiResponse =
    /** status 200 OK */ TenantManagementModelsUserManagementResponseLoginResponseType;
export type GetApiTmAuthApiArg = {
    account?: string;
};
export type GetApiTmAuthUserApiResponse =
    /** status 200 OK */ TenantManagementModelsUserManagementResponseLoginResponse;
export type GetApiTmAuthUserApiArg = {
    id?: number;
    name?: string;
};
export type GetApiTmAuthAccountApiResponse =
    /** status 200 OK */ TenantManagementModelsUserManagementResponseLoginResponse;
export type GetApiTmAuthAccountApiArg = {
    accountId?: number;
};
export type GetApiTmAuthRefreshApiResponse =
    /** status 200 OK */ TenantManagementModelsUserManagementResponseLoginResponse;
export type GetApiTmAuthRefreshApiArg = void;
export type GetApiTmAuthTransientApiResponse =
    /** status 200 OK */ TenantManagementModelsUserManagementResponseLoginResponse;
export type GetApiTmAuthTransientApiArg = void;
export type TenantManagementCommonEnumsLoginType = 'Basic' | 'MFA';
export type TenantManagementModelsUserManagementResponseLoginResponseType = {
    loginType?: TenantManagementCommonEnumsLoginType;
};
export type TenantManagementModelsUserManagementResponseLoginResponse = {
    loginType?: TenantManagementCommonEnumsLoginType;
    refreshToken?: string | null;
    refreshTokenExpiry?: string | null;
    roles?: string[] | null;
    tenantId?: string | null;
    token?: string | null;
    tokenExpiry?: string | null;
};
