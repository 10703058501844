import { ProcessingSubmitButtonOptions } from '../../../../../../utils/Interface/HealthSection/LotProcessingWorksheet';
import { ProcessingChargeoutInitialState } from './ProcessingChargeoutInterfaces';

export const processingChargeoutInitialState: ProcessingChargeoutInitialState =
    {
        processingWorkset: {
            averageWeight: undefined,
            status: 'Started',
            processingPens: [],
            processingProtocolId: undefined,
            processedDate: undefined,
            charges: [],
        },
        firstAllowedDate: '',
        firstAllowedDateIsLoading: false,
        formErrors: {
            processingPenIds: new Set<number>(),
        },
        activeButton: ProcessingSubmitButtonOptions.NoneSelected,
    };
