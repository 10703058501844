import Constants from '../../../utils/Constants';
import { BaseOdataQueryApiArg } from '../rootApi';
import {
    baseAnimalApi,
    FeedlotManagerCommonEnumsEidCsvOnDuplicateAction,
    GetApiFmByFlidAnimalOdataApiResponse,
    PostApiFmByFlidAnimalAndLotidEidsApiResponse,
} from './baseAnimalApi';

export const animalApi = baseAnimalApi
    .injectEndpoints({
        endpoints: build => ({
            postApiFmByFlidAnimalAndLotidEids: build.mutation<
                PostApiFmByFlidAnimalAndLotidEidsApiResponse,
                ModifiedPostApiFmByFlidAnimalAndLotidEidsApiArg
            >({
                query: queryArg => ({
                    url: `/api${Constants.apiUrls.FEED_LOT_MANAGER}/${queryArg.flid}${Constants.apiUrls.ANIMAL}/${queryArg.lotid}${Constants.apiUrls.EIDS}`,
                    method: 'POST',
                    body: queryArg.csvUpload,
                    prepareHeaders: headers => {
                        headers.set('Content-Type', 'multipart/form-data');
                    },
                    params: { duplicateAction: queryArg.duplicateAction },
                }),
                invalidatesTags: ['Animal'],
            }),
            getApiFmByFlidAnimalOdata: build.query<
                GetApiFmByFlidAnimalOdataApiResponse,
                AnimalOdataQueryApiArg
            >({
                query: queryArg => ({
                    url: `/api${Constants.apiUrls.FEED_LOT_MANAGER}/${queryArg.flid}${Constants.apiUrls.ANIMAL}${Constants.apiUrls.ODATA}`,
                    params: {
                        include: queryArg.include,
                        filter: queryArg.filter,
                        orderBy: queryArg.orderBy,
                        top: queryArg.top,
                        skip: queryArg.skip,
                    },
                }),
                providesTags: ['Animal'],
            }),
        }),
        overrideExisting: true,
    })
    .enhanceEndpoints({
        addTagTypes: [],
        endpoints: {},
    });

export interface AnimalOdataQueryApiArg extends BaseOdataQueryApiArg {
    flid: number;
    include?: string;
}

export type ModifiedPostApiFmByFlidAnimalAndLotidEidsApiArg = {
    flid: number;
    lotid: number;
    duplicateAction?: FeedlotManagerCommonEnumsEidCsvOnDuplicateAction;
    csvUpload?: FormData;
};
