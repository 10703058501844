import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { COMMON_INITIAL_STATE } from '../Constants/commonInitialState';
import {
    IFetchFees,
    ISelectedLanguage,
    IToastMessage,
    IWindowSize,
    sideNavTabs,
} from '../../utils/Interface/CommonInterface';
import { FeedlotManagerModelsResponseModelsFeedlotApiResponse } from '../Apis/FMFeedlot/baseFMFeedlotApi';
import StorageService from '../../Services/storage/storage.service';
import Constants from '../../utils/Constants';
import { commonFeatures } from '../../apps/common/featureFlags/commonFeatures';

const { isCA13765On_TokenManagementRework } = commonFeatures;

const commonSlice = createSlice({
    name: 'common',
    initialState: COMMON_INITIAL_STATE,
    reducers: {
        fetchWindowSize: (state, action: PayloadAction<IWindowSize>) => {
            state.windowSize = action.payload;
        },
        setSideNavHidden: (state, action: PayloadAction<boolean>) => {
            state.isSideNavHidden = action.payload;
        },
        toastMessage: (state, action: PayloadAction<IToastMessage>) => {
            state.toastMessage = action.payload;
        },
        setErrorModal: (
            state,
            action: PayloadAction<{ showModal: boolean; errorMessage: string }>,
        ) => {
            state.errorModal = action.payload;
        },
        fetchFeeTypes: (state, action: PayloadAction<IFetchFees[]>) => {
            state.feeTypes = action.payload;
        },
        setFeedlotToUpdate: (
            state,
            action: PayloadAction<
                Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>
            >,
        ) => {
            state.feedlotToUpdate = action.payload;
        },
        setSelectedFeedlot: (
            state,
            action: PayloadAction<
                Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>
            >,
        ) => {
            state.selectedFeedlot = action.payload;
            state.feedlotToUpdate = COMMON_INITIAL_STATE.feedlotToUpdate;
        },
        setSelectedLot: (state, action: PayloadAction<string>) => {
            state.selectedLot = action.payload;
        },
        setDisplayTimeoutErrorModal: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.displayTimeoutErrorModal = action.payload;
        },
        setShowSupportModal: (state, action: PayloadAction<boolean>) => {
            state.showSupportModal = action.payload;
        },
        setSelectedLanguage: (
            state,
            action: PayloadAction<ISelectedLanguage>,
        ) => {
            state.selectedLanguage = action.payload;
        },
        setSelectedSideNavTab: (state, action: PayloadAction<sideNavTabs>) => {
            state.currentSelectedTab = action.payload;
            if (isCA13765On_TokenManagementRework) {
                new StorageService().setValue(
                    Constants.StorageItems.SIDE_NAV_TAB,
                    action.payload,
                    Constants.Storage.SESSION,
                );
            }
        },
    },
});

export const {
    fetchWindowSize,
    setSideNavHidden,
    toastMessage,
    setErrorModal,
    fetchFeeTypes,
    setFeedlotToUpdate,
    setSelectedFeedlot,
    setSelectedLot,
    setDisplayTimeoutErrorModal,
    setShowSupportModal,
    setSelectedLanguage,
    setSelectedSideNavTab,
} = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
export const commonActions = commonSlice.actions;
