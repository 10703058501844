import { IBackdatedInfoModal } from '../../utils/Interface/CommonInterface';
import { IMortalityDetails } from '../../utils/Interface/MortalityReportInterface';
import { MORTALITY_INITIAL_STATE } from '../Constants/mortalityInitialState';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const mortalitySlice = createSlice({
    name: 'mortality',
    initialState: MORTALITY_INITIAL_STATE,
    reducers: {
        setLots: (state, action) => {
            state.lots = action.payload;
        },
        setPens: (state, action) => {
            state.pens = action.payload;
        },
        setSex: (state, action) => {
            state.sex = action.payload;
        },
        setRiders: (state, action) => {
            state.riders = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setAnimalIds: (state, action) => {
            state.animalIds = action.payload;
        },
        setMortalityDetails: (state, action) => {
            state.mortalityDetails = action.payload;
        },
        updateMortalityDetails: (
            state,
            action: PayloadAction<Partial<IMortalityDetails>>,
        ) => {
            for (const key in action.payload) {
                state.mortalityDetails[key] = action.payload[key];
            }
        },
        setMortalityReasons: (state, action) => {
            state.mortalityReasons = action.payload;
        },
        setMortalityTreatments: (state, action) => {
            state.mortalityTreatments = action.payload;
        },
        setMortalityDetailsError: (state, action) => {
            state.mortalityReportsDetailsError = action.payload;
        },
        setMortalityFilter: (state, action) => {
            state.filter = action.payload;
        },
        setCanCallApi: (state, action) => {
            state.canCallApi = action.payload;
        },
        setMortalityList: (state, action) => {
            state.mortalityList = action.payload;
        },
        setGeneratedInvoiceCutOffDate: (state, action) => {
            state.generatedInvoiceCutoffDate = action.payload;
        },
        setFirstAllowedDateBasedOnCutoff: (state, action) => {
            state.firstAllowedDateBasedOnCutoff = action.payload;
        },
        appendMortalityList: (state, action) => {
            state.mortalityList = [...state.mortalityList, ...action.payload];
        },
        setUsedAnimalTags: (state, action) => {
            state.usedAnimalTags = action.payload;
        },
        appendAnimalIdToMortalityDetails: (state, action) => {
            state.mortalityDetails = {
                ...state.mortalityDetails,
                animalId: action.payload,
            };
        },
        setMortalityReportCSV: (state, action) => {
            state.mortalityReportCSV = action.payload;
        },
        setAnimalIdFilter: (state, action) => {
            state.filter = {
                ...state.filter,
                animalId: action.payload,
            };
        },
        setMortalityReasonId: (state, action) => {
            state.mortalityDetails = {
                ...state.mortalityDetails,
                mortalityReasonId: action.payload,
            };
        },
        resetMortalityState: (state, action) => {
            return MORTALITY_INITIAL_STATE;
        },
        setMortalitySubmitPending: (state, action) => {
            state.submitPending = action.payload;
        },
        setBackdatedInfoModal: (
            state,
            action: PayloadAction<IBackdatedInfoModal>,
        ) => {
            state.backdatedInfoModal = action.payload;
        },
    },
});

export const mortalityActions = mortalitySlice.actions;

const mortalityReducer = mortalitySlice.reducer;
export default mortalityReducer;
