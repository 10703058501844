import { useEffect, useState, Suspense, useRef, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { AuthContext } from './apps/common/modules/auth/contexts/authContext';
import { resetAuthorization } from './Services/AxiosService';
import Constants from './utils/Constants';
import { IAuth, ILocalAuthResponse } from './utils/Interface/AuthInterface';
import { Toast_State } from './utils/ToastConstant';
import Auth from './apps/common/modules/auth/classes/Auth';
import Nav from './components/Nav';
import Loading from './components/common/Loading';
import SideNav from './components/SideNav';
import { useAppDispatch, useAppSelector } from './Redux/Store';
import { getRoutesList, handleEnvRedirect } from './utils/routes/Helpers';
import useIdleUserLogout from './utils/hooks/useIdleUserLogout';
import { AxiosInterceptor } from './apps/common/modules/appLoader/components/AxiosInterceptor';
import { AppLoader } from './apps/common/modules/appLoader/components/AppLoader';
import { commonFeatures } from './apps/common/featureFlags/commonFeatures';
import { authActions } from './apps/common/modules/auth/authSlice';
import {
    ApplicationTabNames,
    DisplayTabNames,
    sideNavTabs,
} from './utils/Interface/CommonInterface';
import { COMMON_INITIAL_STATE } from './Redux/Constants/commonInitialState';
import { findDisplayFeedlotName } from './helpers';
import {
    fetchWindowSize,
    setErrorModal,
    setDisplayTimeoutErrorModal,
    setSelectedFeedlot,
} from './Redux/Reducer/common';
import { OwnerContextProvider } from './Context/OwnerContext/OwnerContext';
import dayjs from './utils/dayjs';
import { accountsApi } from './Redux/Apis/Accounts/accountsApi';
import StorageService from './Services/storage/storage.service';
import { useNavigate } from 'react-router-dom';
import { authApi } from './Redux/Apis/Auth/authApi';
import { t } from 'i18next';
import { feedlotManagerFeatures } from './apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import ErrorModal from './components/common/ErrorModal';

const { isCA10347_useAppLoaderHookOn, isCA13765On_TokenManagementRework } =
    commonFeatures;

const { isCA13269On_LongReportTimeouts } = feedlotManagerFeatures;
const App = () => {
    const [auth] = useState<IAuth>(
        Auth(Constants.StorageItems.LOCAL_AUTH_STORAGE_KEY),
    );
    const [accountAuth] = useState<IAuth>(
        Auth(Constants.StorageItems.ACCOUNT_AUTH_STORAGE_KEY),
    );
    const displayTimeoutErrorModal = useAppSelector(
        state => state.common.displayTimeoutErrorModal,
    );
    const [tokenRenewalComplete, setTokenRenewalComplete] = useState(false);
    const [accountTokenRenewalComplete, setAccountTokenRenewalComplete] =
        useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        toastMessage,
        errorModal,
        isSideNavHidden,
        selectedFeedlot,
        feedlotToUpdate,
        selectedLot,
        currentSelectedTab,
    } = useAppSelector(state => state.common);
    const { logout } = useAppSelector(state => state.auth);
    const [sideNavTab, setSideNavTab] = useState<string | number>(
        new StorageService().getValue(
            Constants.StorageItems.SELECTED_FEEDLOT,
            Constants.Storage.LOCAL,
        )?.label
            ? 'fm'
            : '',
    );
    const closeTimeoutModalHandler = () => {
        dispatch(setDisplayTimeoutErrorModal(false));
    };

    useIdleUserLogout(
        localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
            Constants.AuthType.LOCAL
            ? () => dispatch(authActions.setLogout())
            : auth.logout,
        auth.isAuthenticated,
    );

    const closeModalHandler = () => {
        dispatch(setErrorModal(COMMON_INITIAL_STATE.errorModal));
    };

    const timeoutId = useRef<NodeJS.Timeout | null>(null);
    const debounceTimeoutId = useRef<NodeJS.Timeout | null>(null);

    const resetTimer = useCallback(() => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
        }
        timeoutId.current = setTimeout(() => {
            window.location.reload();
        }, Constants.time.idleTimeout);
    }, []);

    const debouncedResetTimer = useCallback(() => {
        if (debounceTimeoutId.current) {
            clearTimeout(debounceTimeoutId.current);
        }
        debounceTimeoutId.current = setTimeout(resetTimer, 500);
    }, [resetTimer]);

    useEffect(() => {
        resetTimer();

        window.addEventListener('mousemove', debouncedResetTimer);
        window.addEventListener('keypress', debouncedResetTimer);

        return () => {
            window.removeEventListener('mousemove', debouncedResetTimer);
            window.removeEventListener('keypress', debouncedResetTimer);
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
            if (debounceTimeoutId.current) {
                clearTimeout(debounceTimeoutId.current);
            }
        };
    }, [debouncedResetTimer, resetTimer]);

    useEffect(() => {
        const resizeWin = () => {
            dispatch(fetchWindowSize(COMMON_INITIAL_STATE.windowSize));
        };
        window.addEventListener('resize', resizeWin);
        return () => window.removeEventListener('resize', resizeWin);
    }, [dispatch]);

    useEffect(() => {
        if (toastMessage.showMessage) {
            if (toastMessage.type === Constants.message.type.SUCCESS) {
                toast.success(toastMessage.message, { ...Toast_State });
            } else if (toastMessage.type === Constants.message.type.ERROR) {
                toast.error(toastMessage.message, { ...Toast_State });
            }
        }
    }, [toastMessage]);

    useEffect(() => {
        auth.renewToken(() => {
            setTokenRenewalComplete(true);
        });
    }, [auth]);

    useEffect(() => {
        accountAuth.renewToken(() => {
            setAccountTokenRenewalComplete(true);
        });
    }, [accountAuth]);

    const isAuthenticated = auth.isAuthenticated(false);

    const accountIsAuthenticated = accountAuth.isAuthenticated(false);

    const CA13765Dependencies = isCA13765On_TokenManagementRework
        ? currentSelectedTab
        : undefined;

    useEffect(() => {
        //useEffect required to resolve this error: Warning: Cannot update a component (`App`) while rendering a different component (`App`). To locate the bad setState() call inside `App`, follow the stack trace as described in https://reactjs.org/link/setstate-in-render
        if (
            accountIsAuthenticated &&
            (isCA13765On_TokenManagementRework
                ? currentSelectedTab === sideNavTabs.Fm
                : sideNavTab.toString() === ApplicationTabNames.FeedlotManager)
        ) {
            dispatch(authActions.setAccessToken(accountAuth.getAccessToken()));
        } else if (isAuthenticated) {
            dispatch(authActions.setAccessToken(auth.getAccessToken()));
        } else {
            dispatch(authActions.setAccessToken(''));
            resetAuthorization();
        }
    }, [
        dispatch,
        auth,
        accountAuth,
        isAuthenticated,
        accountIsAuthenticated,
        sideNavTab,
        CA13765Dependencies,
    ]);

    useEffect(() => {
        if (
            isCA13765On_TokenManagementRework
                ? currentSelectedTab === sideNavTabs.Fm
                : sideNavTab.toString() === ApplicationTabNames.FeedlotManager
        ) {
            const displayFeedlotName = findDisplayFeedlotName(selectedFeedlot);
            if (selectedFeedlot.label && selectedLot) {
                document.title = `${DisplayTabNames.FeedlotManager} | ${displayFeedlotName} | ${selectedLot}`;
            } else if (selectedFeedlot.label) {
                document.title = `${DisplayTabNames.FeedlotManager} | ${displayFeedlotName}`;
            } else {
                document.title = DisplayTabNames.FeedlotManager;
            }
        } else if (
            isCA13765On_TokenManagementRework
                ? currentSelectedTab === sideNavTabs.Financial
                : sideNavTab.toString() === ApplicationTabNames.FinApp
        ) {
            if (selectedLot) {
                document.title = `${DisplayTabNames.Financial} | ${selectedLot}`;
            } else {
                document.title = DisplayTabNames.Financial;
            }
        } else if (
            isCA13765On_TokenManagementRework
                ? currentSelectedTab === sideNavTabs.BuyPo
                : sideNavTab.toString() === ApplicationTabNames.CattlePo
        ) {
            document.title = DisplayTabNames.BuyPo;
        } else {
            document.title = DisplayTabNames.Default;
        }
    }, [sideNavTab, selectedFeedlot, selectedLot, CA13765Dependencies]);

    const [fetchAccounts, { data: accounts }] =
        accountsApi.useLazyGetApiTmAccountsQuery();

    const [getToken] = authApi.useLazyGetApiTmAuthAccountQuery();

    useEffect(() => {
        const fetchAccountToken = async () => {
            if (!accounts) {
                dispatch(authActions.setAccessToken(auth.getAccessToken()));
                fetchAccounts({});
            }
            const accountId = accounts?.find(
                account => account.name === feedlotToUpdate.label,
            )?.accountId;
            if (accountId) {
                const res = await getToken({ accountId });
                if (res.data?.token) {
                    dispatch(authActions.setAccessToken(res.data.token));
                    const authres = res.data as ILocalAuthResponse;
                    accountAuth.saveLocalToken(JSON.stringify(authres));
                    accountAuth.setSession({
                        expiresIn:
                            dayjs(res.data.tokenExpiry).valueOf() -
                            dayjs().valueOf(),
                        accessToken: res.data.token,
                    });
                    if (feedlotToUpdate.label) {
                        dispatch(setSelectedFeedlot(feedlotToUpdate));
                    }
                }
            }
        };
        if (isAuthenticated) {
            if (
                localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
                    Constants.AuthType.AUTH_0 ||
                auth.getUsersRoles().includes('Admin') ||
                auth.getUsersRoles().includes('OfficeManager')
            ) {
                if (feedlotToUpdate.label) {
                    dispatch(setSelectedFeedlot(feedlotToUpdate));
                }
            } else {
                fetchAccountToken();
            }
        }
    }, [
        dispatch,
        fetchAccounts,
        accounts,
        auth,
        accountAuth,
        feedlotToUpdate,
        isAuthenticated,
        getToken,
    ]);

    useEffect(() => {
        if (
            logout &&
            localStorage.getItem(Constants.StorageItems.AUTH_TYPE) ===
                Constants.AuthType.LOCAL
        ) {
            //This will wipe all instances of Auth, clear tokens from local storage,
            //and return the user to the login page
            new StorageService().clear(Constants.Storage.LOCAL);
            window.location.reload();
        }
    }, [accountAuth, auth, dispatch, logout, navigate]);

    if (
        !tokenRenewalComplete ||
        !accountTokenRenewalComplete ||
        handleEnvRedirect(auth)
    ) {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center vh-100 vw-100">
                <strong>Loading...</strong>
                <div
                    className="spinner-border ml-auto"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        );
    }

    const authInstanceInUse =
        accountIsAuthenticated &&
        (isCA13765On_TokenManagementRework
            ? currentSelectedTab === sideNavTabs.Fm
            : sideNavTab.toString() === ApplicationTabNames.FeedlotManager)
            ? accountAuth
            : auth;

    return (
        <AuthContext.Provider value={authInstanceInUse}>
            {isCA10347_useAppLoaderHookOn ? (
                <AxiosInterceptor>
                    <div
                        id="default-focus-trap-element"
                        tabIndex={0}
                        style={{ width: 0, height: 0 }}
                    />
                    <ErrorModal
                        showModal={errorModal.showModal}
                        errorMessage={errorModal.errorMessage}
                        closeModalHandler={closeModalHandler}
                    />
                    <ToastContainer />
                    <div className="container-fluid">
                        <div className="row">
                            <SideNav
                                sideNavTab={sideNavTab}
                                setSideNavTab={setSideNavTab}
                            />
                            <Suspense
                                fallback={
                                    <div className="m-auto">
                                        <Loading />
                                    </div>
                                }
                            >
                                <div
                                    className={`p-0 ${
                                        authInstanceInUse.isAuthenticated()
                                            ? `main-container ${
                                                  isSideNavHidden
                                                      ? 'main-container-expand'
                                                      : ''
                                              }`
                                            : ''
                                    }`}
                                >
                                    <div id="content">
                                        <AppLoader />
                                        <OwnerContextProvider>
                                            <>
                                                {isCA13269On_LongReportTimeouts && (
                                                    <ErrorModal
                                                        showModal={
                                                            displayTimeoutErrorModal
                                                        }
                                                        closeModalHandler={
                                                            closeTimeoutModalHandler
                                                        }
                                                        errorMessage={t(
                                                            'common:timeoutMessage',
                                                        )}
                                                    />
                                                )}
                                            </>
                                            <Nav sideNavTab={sideNavTab} />
                                            {getRoutesList()}
                                        </OwnerContextProvider>
                                    </div>
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </AxiosInterceptor>
            ) : (
                <>
                    <ToastContainer />
                    <div className="container-fluid">
                        <div className="row">
                            <SideNav
                                sideNavTab={sideNavTab}
                                setSideNavTab={setSideNavTab}
                            />

                            <Suspense
                                fallback={
                                    <div className="m-auto">
                                        <Loading />
                                    </div>
                                }
                            >
                                <div
                                    className={`p-0 ${
                                        authInstanceInUse.isAuthenticated()
                                            ? `main-container ${
                                                  isSideNavHidden
                                                      ? 'main-container-expand'
                                                      : ''
                                              }`
                                            : ''
                                    }`}
                                >
                                    <div id="content">
                                        <OwnerContextProvider>
                                            <>
                                                {isCA13269On_LongReportTimeouts && (
                                                    <ErrorModal
                                                        showModal={
                                                            displayTimeoutErrorModal
                                                        }
                                                        closeModalHandler={
                                                            closeTimeoutModalHandler
                                                        }
                                                        errorMessage={t(
                                                            'common:timeoutMessage',
                                                        )}
                                                    />
                                                )}
                                            </>
                                            <Nav sideNavTab={sideNavTab} />
                                            {getRoutesList()}
                                        </OwnerContextProvider>
                                    </div>
                                </div>
                            </Suspense>
                        </div>
                    </div>
                </>
            )}
        </AuthContext.Provider>
    );
};

export default App;
