import { bigJsOperations } from './BigJsOperations';

export interface INumericalOperations {
    add(num1: number, num2: number): number;
    subtract(num1: number, num2: number): number;
    multiply(num1: number, num2: number): number;
    divide(num1: number, num2: number): number;
}

export const NumericalOperations: () => INumericalOperations = () => {
    return bigJsOperations;
};
