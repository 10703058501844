import { Draft } from '@reduxjs/toolkit';
import {
    FeedlotManagerDataEnumsFeedBunkScoreEnum,
    FeedlotManagerModelsFeedFeedCallCreateFeedCallModel,
    FeedlotManagerModelsFeedFeedCallFeedCallResponseModel,
    FeedlotManagerModelsFeedFeedCallUpdateFeedCallModel,
    FeedlotManagerModelsFeedRationRationApiResponse,
} from '../../../../../Redux/Apis/FeedCall/baseFeedCallApi';
import { FeedlotManagerModelsFeedFeedRouteFeedCallRoutePenInfo } from '../../../../../Redux/Apis/FeedCallRoute/baseFeedCallRouteApi';
import { penFilterByRationDefaultIds } from './constants/penFilterByRationDefaultOptions';
import { IFeedCallPageSliceInitialState } from './slices/interfaces';
import { bunkScoreDropdownId } from './constants/feedCallTabOrder';
import { feedlotManagerFeatures } from '../../../featureFlags/feedlotManagerFeatures';
import { FeedCallConstants } from '../../../../../utils/constants/FeedCallConstants';
import { useHotkeys } from 'react-hotkeys-hook';
import { useMemo } from 'react';
const {
    isCA13466On_AddNoFeedCallOptionToRationFilter,
    isCA14058On_FeedCallAfDmChangeBugAndOtherBugs,
    isCA14074On_FeedCallHotKeys,
} = feedlotManagerFeatures;

export const getFeedCallPutPayload = (
    feedCallPayload: FeedlotManagerModelsFeedFeedCallFeedCallResponseModel,
): FeedlotManagerModelsFeedFeedCallUpdateFeedCallModel | null => {
    const { penId, hdCount, estimatedHdCount, afChangePerHd } = feedCallPayload;
    let feedCallPutPayload: FeedlotManagerModelsFeedFeedCallUpdateFeedCallModel | null =
        null;

    if (
        penId &&
        hdCount !== undefined &&
        (hdCount || estimatedHdCount) &&
        (afChangePerHd || afChangePerHd === 0)
    ) {
        feedCallPutPayload = {
            penId,
            hdCount,
            bunkScore:
                feedCallPayload.bunkScore as unknown as FeedlotManagerDataEnumsFeedBunkScoreEnum,
            afChangePerHd,
            dmChangePerHd: feedCallPayload.dmChangePerHd,
            totalCall: feedCallPayload.totalCall,
            afPerHd: feedCallPayload.afPerHd,
            dmPerHd: feedCallPayload.dmPerHd,
            wtPerDM: feedCallPayload.wtPerDM ?? 0,
            adg: feedCallPayload.adg,
            estimatedHdCount: feedCallPayload.estimatedHdCount,
            estimatedHeadTracker: feedCallPayload.estimatedHeadTracker,
            comments: feedCallPayload.comments,
        };
    }
    return feedCallPutPayload;
};

export const getFeedCallPostPayload = (
    feedCallPayload: FeedlotManagerModelsFeedFeedCallFeedCallResponseModel,
    rations: FeedlotManagerModelsFeedRationRationApiResponse[] | undefined,
    penRationScheduleId: number,
): FeedlotManagerModelsFeedFeedCallCreateFeedCallModel | null => {
    const feedCallPutPayload = getFeedCallPutPayload(feedCallPayload);

    if (!feedCallPutPayload || !rations?.length) {
        return null;
    }

    const startRation = rations[0];
    const endRation = rations[1];

    const rationInfo = {
        startRationName: startRation?.name ?? '',
        startRationId: startRation?.rationId ?? null,
        startRationPercentage: startRation?.rationPercentage || 0,
        ...(endRation
            ? {
                  endRationName: endRation.name,
                  endRationId: endRation.rationId,
                  endRationPercentage: endRation.rationPercentage || 0,
              }
            : {}),
    };

    const feedCallPostPayload: FeedlotManagerModelsFeedFeedCallCreateFeedCallModel =
        {
            ...feedCallPutPayload,
            ...rationInfo,
            afPerHd: feedCallPutPayload.afPerHd ?? 0,
            bunkScore: feedCallPutPayload.bunkScore ?? 'NoBunkScore',
            totalCall: feedCallPutPayload.totalCall ?? 0,
            daysOnCurrentRation: feedCallPayload.daysOnCurrentRation,
            penRationScheduleId,
        };

    return feedCallPostPayload;
};

export const getFilteredFeedCallPensList = (
    pens:
        | FeedlotManagerModelsFeedFeedRouteFeedCallRoutePenInfo[]
        | null
        | undefined,
    penFilterByRation: string | number,
): FeedlotManagerModelsFeedFeedRouteFeedCallRoutePenInfo[] => {
    if (!pens) {
        return [];
    }
    let pensFilteredByRation: FeedlotManagerModelsFeedFeedRouteFeedCallRoutePenInfo[] =
        [];
    const penFilterByRationString = penFilterByRation.toString();
    if (
        penFilterByRationString === penFilterByRationDefaultIds.cattleNoFeedCall
    ) {
        pensFilteredByRation = pens.filter(
            pen =>
                !pen.hasCurrentDayFeedCall &&
                pen.hdCount !== undefined &&
                pen.hdCount > 0,
        );
    } else if (
        isCA13466On_AddNoFeedCallOptionToRationFilter &&
        penFilterByRation === penFilterByRationDefaultIds.noFeedCall
    ) {
        pensFilteredByRation = pens.filter(pen => !pen.hasCurrentDayFeedCall);
    } else if (penFilterByRationString === penFilterByRationDefaultIds.all) {
        pensFilteredByRation = pens;
    } else if (
        penFilterByRationString ===
        penFilterByRationDefaultIds.unassignedSchedules
    ) {
        pensFilteredByRation = pens.filter(
            pen => !pen.applicableRationIds?.length,
        );
    } else if (Number(penFilterByRation)) {
        pensFilteredByRation = pens.filter(pen =>
            pen.applicableRationIds?.includes(Number(penFilterByRation)),
        );
    }

    return pensFilteredByRation;
};

export const setLocalAssessmentValues = (
    state: Draft<IFeedCallPageSliceInitialState>,
    payload: {
        totalCall?: number | undefined;
        afChangePerHd?: number | undefined;
        dmChangePerHd?: number | undefined;
    },
) => {
    const { totalCall, afChangePerHd, dmChangePerHd } = payload;
    if (isCA14058On_FeedCallAfDmChangeBugAndOtherBugs) {
        if (totalCall !== undefined) {
            state.localAssessmentValues.totalCall = totalCall;
        }
        if (afChangePerHd !== undefined) {
            state.localAssessmentValues.afChangePerHd = afChangePerHd;
        }
        if (dmChangePerHd !== undefined) {
            state.localAssessmentValues.dmChangePerHd = dmChangePerHd;
        }
    } else {
        if (totalCall || totalCall === 0) {
            state.localAssessmentValues.totalCall = Number(
                totalCall.toFixed(2),
            );
        }
        if (afChangePerHd || afChangePerHd === 0) {
            state.localAssessmentValues.afChangePerHd = Number(
                afChangePerHd.toFixed(2),
            );
        }
        if (dmChangePerHd || dmChangePerHd === 0) {
            state.localAssessmentValues.dmChangePerHd = Number(
                dmChangePerHd.toFixed(2),
            );
        }
    }
};

export const getNewIndexForNextOrPrevious = ({
    change,
    currentIndex,
    listLength,
}: {
    change: 'next' | 'previous';
    currentIndex: number;
    listLength: number;
}): number =>
    change === 'next'
        ? (currentIndex + 1) % listLength
        : (currentIndex + listLength - 1) % listLength;

export const focusBunkScoreDropdown = (): void =>
    document.getElementById(bunkScoreDropdownId)?.focus();

export const useUseHotkeysHook = isCA14074On_FeedCallHotKeys
    ? (key: string, callback: () => void) =>
          useHotkeys(key, e => callback(), {
              ignoreEventWhen: e =>
                  (e.target as unknown as { name: string }).name ===
                  FeedCallConstants.textFields.comments,
              enableOnFormTags: true,
              keyup: true,
              keydown: false,
          })
    : () => {
          return;
      };

export const useHotkeyLabel = isCA14074On_FeedCallHotKeys
    ? (label: string, hotkey: string) =>
          useMemo(() => {
              if (hotkey.length !== 1) {
                  return label;
              }
              const hotkeyIndex = label
                  .toLowerCase()
                  .indexOf(hotkey.toLowerCase());
              if (hotkeyIndex === -1) {
                  return label;
              }
              const underlinedLabel = (
                  <span>
                      {label.slice(0, hotkeyIndex)}
                      <u>{label[hotkeyIndex]}</u>
                      {label.slice(hotkeyIndex + 1)}
                  </span>
              );

              return underlinedLabel;
          }, [hotkey, label])
    : (label: string) => {
          return label;
      };
