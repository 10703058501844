import { utils, WorkSheet } from 'xlsx';

export const formatCurrencyWithSpan = (number: any) => {
    if (!number && number !== 0) {
        return;
    }
    number = +number;

    return Math.abs(number.toFixed(2)) < 0.005 ? (
        <span>$ 0.00</span>
    ) : number < 0 ? (
        <span className="negative-number">
            $ (
            {Math.abs(number)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            )
        </span>
    ) : (
        <span>$ {number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</span>
    );
};

export const formatCurrency = (number: string | number) => {
    if (!number && number !== 0) {
        return;
    }
    number = +number;
    return `$ ${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

/**
 * @deprecated deprecated because it is not working fine with MST timezone
 */
export const formatDate = (dateValue: string | Date) => {
    const date = new Date(dateValue);
    return (
        date.getUTCFullYear() +
        `-` +
        ('0' + (date.getUTCMonth() + 1)).slice(-2) +
        `-` +
        ('0' + date.getUTCDate()).slice(-2)
    );
};

export const formatXLSXNumber = (value: string | number) => {
    if (!value && value !== 0) {
        return 0;
    }
    value = +value;
    return value;
};

export const formatXLSXPercent = (value: string | number) => {
    return `${formatXLSXNumber(value).toFixed(2)}%`;
};

export const formatToCamelFromPascal = (string: string) => {
    let stringCopy = string.slice(0);
    stringCopy = stringCopy.charAt(0).toLowerCase() + stringCopy.slice(1);
    return stringCopy;
};

export const formatXLSXNumbersAsCurrency = (
    ws: WorkSheet,
    excludeColumns: number[] = [0],
    excludeRows: number[] = [0],
) => {
    for (const i in ws) {
        if (typeof ws[i] !== 'object') {
            continue;
        }
        const cell = utils.decode_cell(i);
        if (excludeColumns.includes(cell.c) || excludeRows.includes(cell.r)) {
            continue;
        } else {
            delete ws[i].w;
            ws[i].z = '$#,##0.00';
            //TODO!!!:
            //can use something like this to color excel cells red if we have xlsx pro version; or switch excel package. This would need to be in a different formula and applied to relevant equity/hd or LTV cells
            //  but the method would be similar to formatXLSXNumbersAsCurrency
            //  formatting applied as s or z or w or other properties
            //  need to dig to find the right answer if we get pro package
            // ws[i].s = {
            //     type: 'pattern',
            //     pattern: 'solid',
            //     fgColor: { argb: 'FF1c4587' },
            //     bgColor: { argb: 'FF1c4587' },
            // };
        }
    }
};

export const formatWeight = (weight: any) =>
    weight ? (+weight).toFixed(1) : 0;
