import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../Actions';
import { PEN_RATION_SCHEDULE_INITIAL_STATE } from '../Constants/penRationScheduleInitialState';

const PenRationScheduleReducer = (
    state = PEN_RATION_SCHEDULE_INITIAL_STATE,
    action: AnyAction,
) => {
    let stateCopy = { ...state };
    switch (action.type) {
        case ActionTypes.SET_SCHEDULE_TYPE:
            stateCopy = {
                ...stateCopy,
                scheduleTypes: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_PEN_RATION_SCHEDULE_BASIC_DETAIL:
            stateCopy = {
                ...stateCopy,
                penRationScheduleBasicDetail: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_PEN_RATION_SCHEDULE_BASIC_DETAIL_ERROR:
            stateCopy = {
                ...stateCopy,
                penRationScheduleBasicDetailError: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_PEN_RATION_SCHEDULE_RATIONS:
            stateCopy = {
                ...stateCopy,
                rations: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_PEN_RATION_SCHEDULES:
            stateCopy = {
                ...stateCopy,
                penRationSchedules: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_DELETED_PEN_RATION_SCHEDULES: {
            const deletedPenRationSchedules = [
                ...stateCopy.deletedPenRationSchedules,
                ...action.payload,
            ].map(prs => {
                if (!prs.days) {
                    prs.days = 0;
                }
                return prs;
            });
            return {
                ...stateCopy,
                deletedPenRationSchedules,
            };
        }
        case ActionTypes.SET_PEN_RATION_SCHEDULE_INDEX:
            stateCopy = {
                ...stateCopy,
                rationIndex: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_CAN_CALL_API:
            stateCopy = {
                ...stateCopy,
                canCallApi: action.payload,
            };
            return stateCopy;
        case ActionTypes.RESET_PEN_RATION_SCHEDULE:
            stateCopy = {
                ...stateCopy,
                ...action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_PEN_INFO:
            stateCopy = {
                ...stateCopy,
                penInfo: action.payload,
            };
            return stateCopy;

        case ActionTypes.PEN_RATION_SELECTED_PEN_ID:
            stateCopy = {
                ...stateCopy,
                penRationSelectedPenId: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_PEN_RATION_SCHEDULE_LOADING:
            stateCopy.loading = action.payload;
            return stateCopy;
        case ActionTypes.SET_PEN_RATION_SCHEDULE_ERRORS:
            stateCopy.penRationScheduleErrors = action.payload;
            return stateCopy;
        case ActionTypes.SET_IS_PEN_FOR_SELECTED_FEEDLOT:
            stateCopy.isPenForSelectedFeedlot = action.payload;
            return stateCopy;
        case ActionTypes.SET_VARIABLE_SPLIT_RATION_SCHEDULE:
            return {
                ...state,
                variableSplitRationSchedule: action.payload,
            };
        case ActionTypes.SET_VARIABLE_SPLIT_RATION_SCHEDULES: {
            return {
                ...state,
                variableSplitRationSchedules: action.payload,
            };
        }
        case ActionTypes.SET_DELETED_VARIABLE_SPLIT_RATION_SCHEDULES: {
            return {
                ...state,
                deletedVariableSplitRationSchedules: [
                    ...state.deletedVariableSplitRationSchedules,
                    ...action.payload,
                ],
            };
        }
        case ActionTypes.SET_VARIABLE_SPLIT_SCHEDULE_ERROR:
            return {
                ...state,
                variableSplitScheduleError: action.payload,
            };
        case ActionTypes.SET_VARIABLE_SPLIT_SCHEDULES_ERROR:
            return {
                ...state,
                variableSplitSchedulesError: action.payload,
            };
        case ActionTypes.SET_SELECTED_SCHEDULE_TYPE:
            return {
                ...state,
                selectedScheduleType: action.payload,
            };
        case ActionTypes.SET_IS_PEN_RATION_SCHEDULE_EDITED:
            return {
                ...state,
                isPenRationScheduleDetailsEdited: action.payload,
            };
        case ActionTypes.SET_IS_NEW_PEN_RATION_SCHEDULE:
            return {
                ...state,
                isNewPenRationSchedule: action.payload,
            };
        default:
            return stateCopy;
    }
};

export default PenRationScheduleReducer;
