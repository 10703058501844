import { IDailyCharge, IInvoice } from '../../utils/Interface/BillingInterface';
import { ICattleType } from '../../utils/Interface/FeedlotInterface';
import { IProcessingWorkset } from '../../utils/Interface/HealthSection/LotProcessingWorksheet';
import {
    IFMLotUpdateModel,
    IGeneralSummaryModel,
    IHeadCountHistoryItem,
    IMovementHistoryItem,
} from '../../utils/Interface/LotInterface';
import {
    ILotSummaryDetails,
    ILotTreatment,
    IPen,
    LotSummaryTab,
} from '../../utils/Interface/LotSummaryInterface';
import { ISexResponse } from '../../utils/Interface/TreatmentsInterface';

const selectedTabFromStorage = window.location.hash.split('#')[1]
    ? (window.location.hash.split('#')[1] as LotSummaryTab)
    : LotSummaryTab.ReceivingAndShippingHistory;

interface ILotSummaryInitialState {
    movementHistory: IMovementHistoryItem[];
    lotTreatments: ILotTreatment[];
    lotProcessing: IProcessingWorkset[];
    headCountHistory: IHeadCountHistoryItem[];
    processingChargeouts: number;
    pens: IPen[];
    isPensTableLoading: boolean;
    invoices: IInvoice[];
    dailyCharge: IDailyCharge;
    lotSummary: ILotSummaryDetails[];
    generalSummary: IGeneralSummaryModel;
    currentNavTab: number;
    selectedNavTab: LotSummaryTab;
    treatmentsFilter: { limit: number; offset: number };
    processingFilter: { limit: number; offset: number };
    lots: [{ name: string; id: string }];
    lotDailyCharges: IDailyCharge[];
    lotExcelCharges: IDailyCharge[];
    isDailyChargesLoading: boolean;
    isExcelDataLoading: boolean;
    sexTypes: ISexResponse[];
    cattleTypes: ICattleType[];
    isLotSummaryLoading: boolean;
    lotSummaryLot: IFMLotUpdateModel;
    lotsPastInvoices: IInvoice[];
    isLotsInvoiceLoading: boolean;
    lotNames: string[];
    totalHeadCount: number;
}

export const LOT_SUMMARY_INITIAL_STATE: ILotSummaryInitialState = {
    movementHistory: [],
    lotTreatments: [],
    lotProcessing: [],
    headCountHistory: [],
    processingChargeouts: 0,
    pens: [],
    isPensTableLoading: false,
    invoices: [],
    dailyCharge: {
        chargeDate: '',
        hdCount: 0,
        feedCharge: 0,
        yardageCharge: 0,
        medCharge: 0,
        processingCharge: 0,
        brandsCharge: 0,
        cviCharge: 0,
        miscCharge: 0,
        miscCredit: 0,
        totalCharge: 0,
        cumulativeCharge: 0,
    },
    lotSummary: [],
    generalSummary: {
        total: {
            totalCattleCost: 0,
            totalCost: 0,
            totalPayWeight: 0,
        },
        average: {
            payWeightPerHead: 0,
            averageDateIn: '',
            averageCostInPerLb: 0,
            averageDayOnFeed: 0,
            averageDailyGain: 0,
            estimatedCurrentWeight: 0,
        },
        currentHeadCount: {
            purchased: 0,
            sold: 0,
            dead: 0,
            realizers: 0,
            remaining: 0,
        },
        lotInfo: {
            lotId: 0,
            lotName: '',
            ownerId: 0,
            ownerName: '',
            sexId: 0,
            sex: '',
        },
        penCurrentHeadCount: [],
    },
    treatmentsFilter: { limit: 20, offset: 0 },
    processingFilter: { limit: 20, offset: 0 },
    currentNavTab: 1,
    selectedNavTab: selectedTabFromStorage,
    isLotSummaryLoading: true,
    lots: [{ name: 'SELECT LOT', id: '' }],
    lotDailyCharges: [],
    lotExcelCharges: [],
    isDailyChargesLoading: false,
    isExcelDataLoading: false,
    sexTypes: [],
    cattleTypes: [],
    lotSummaryLot: {},
    lotsPastInvoices: [],
    isLotsInvoiceLoading: false,
    lotNames: [],
    totalHeadCount: 0,
};
