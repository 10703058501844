import { HospitalActionTypes } from '.';
import { apiCall, apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    IAnimal,
    IAnimalAdminHistory,
    IPen,
    IUpdateAnimalPayload,
} from '../../utils/Interface/HospitalInterface';
import { CREATE_HOSPITAL_INITIAL_STATE } from '../Constants/hospitalInitialState';
import { AppDispatch, AppThunkDispatch } from '../Store';
import { MoveFromHospitalPenModalConstants } from '../../utils/constants/MoveFromHospitalPenModalConstants';
import { getToastContent } from '../../utils/ToastContent';
import { ICsvData } from '../../utils/Interface/CommonInterface';
import { t } from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import { toastMessage } from '../Reducer/common';
import { IPenForHospitalScreen } from '../../utils/Interface/TreatmentsInterface';

export const setLoading = (data = CREATE_HOSPITAL_INITIAL_STATE.loading) => {
    return {
        type: HospitalActionTypes.SET_HOSPITAL_LOADING,
        payload: data,
    };
};

export const setLoadingHistory = (
    data = CREATE_HOSPITAL_INITIAL_STATE.loadingHistory,
) => {
    return {
        type: HospitalActionTypes.SET_HOSPITAL_LOADING_HISTORY,
        payload: data,
    };
};

export const setMovePens = data => {
    return {
        type: HospitalActionTypes.SET_MOVE_PENS,
        payload: data,
    };
};

export const fetchHospitalPens =
    (feedlotId: number) => async (dispatch: AppDispatch) => {
        try {
            setLoadingHistory(true);

            const hospitalPens: IPen[] = await apiCall_v2({
                method: 'get',
                url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.PENS}/odata?$filter=Kind eq 'Hospital'&isRationDetailsRequired=${true}`,
            });

            dispatch(setHospitalPens(hospitalPens));
        } finally {
            setLoadingHistory(false);
        }
    };

export const fetchMovePens =
    (
        feedlotId: number,
        lotId: number,
        setShowHomePenMessage: Dispatch<SetStateAction<boolean>>,
    ) =>
    async (dispatch: AppDispatch) => {
        let movePens = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.PENS}/AssociatedPens?lotId=${lotId}&isRationDetailsRequired=${true}`,
        });
        if (movePens.length && !movePens.some(pen => pen.kind === 'Home')) {
            movePens = await apiCall_v2({
                method: 'get',
                url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.PENS}`,
            });
            setShowHomePenMessage(true);
        }
        dispatch(setMovePens(movePens || []));
    };

export const fetchMovePens_deprecated =
    (feedlotId: number, lotId: number, lotPens?) =>
    async (dispatch: AppDispatch) => {
        const movePens: IPenForHospitalScreen[] = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.PENS}/AssociatedPens?lotId=${lotId}`,
        });

        if (movePens && lotPens) {
            const hospitalPens =
                movePens?.filter(pen => pen.kind === 'Hospital') ?? [];
            const backdatedMovePens = [...lotPens, ...hospitalPens].reduce(
                (hospitalPen, homePen) => {
                    const existingPen = hospitalPen.find(
                        p => p.penId === homePen.penId,
                    );
                    if (!existingPen) {
                        hospitalPen.push(homePen);
                    }
                    return hospitalPen;
                },
                [],
            );
            dispatch(setMovePens(backdatedMovePens));
        } else if (movePens) {
            const feedhomePens = movePens?.map(pen => ({
                penId: pen.penId,
                name: pen.name,
                hdCount: pen.hdCount,
            }));
            dispatch(setMovePens(feedhomePens));
        }
    };

export const fetchAdminHistory =
    (feedlotId: number, animalId: string | number) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingHistory(true));

            const url = `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.CATTLE_TREATMENT}${Constants.apiUrls.ODATA}?include=Rider,Diagnosis,TreatmentProtocol&$filter=AnimalId eq ${animalId}&$orderby=createdDatetime desc`;

            const response = await apiCall('get', url, {}, true);

            if (response?.status >= 200 && response?.status < 300) {
                const animalList: IAnimalAdminHistory[] = response?.data?.map(
                    animal => ({
                        cattleTreatmentId: animal?.cattleTreatmentId,
                        treatmentDate: animal?.date,
                        riderName: animal?.riderName ?? '-',
                        protocolName: animal?.treatmentProtocolName,
                        diagnosisName: animal?.diagnosisName,
                    }),
                );

                dispatch(setAnimalAdminHistory(animalList));
            }
        } finally {
            dispatch(setLoadingHistory(false));
        }
    };

export const fetchAnimalsHospital =
    (feedlotId: number, queryString?: string) =>
    async (dispatch: AppThunkDispatch) => {
        try {
            dispatch(setLoading(true));

            let url = `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.ANIMAL}${Constants.apiUrls.ODATA}?include=CurrentPen,HomePen,Lot`;

            const filterConditions = [
                `Lot/FeedlotId eq ${feedlotId}`,
                `CurrentPen/Kind eq 'Hospital'`,
                `Lot/hdCount gt 0`,
                `Lot/Pens/any(pen: pen/PenId eq CurrentPenId)`,
                `Mortalities/any() eq false`,
                `Realizers/any() eq false`,
                queryString ? `CurrentPenId eq ${queryString}` : undefined,
            ];

            const filterClause = filterConditions.filter(Boolean).join(' and ');
            if (filterClause) {
                url += `&$filter=${filterClause}`;
            }

            const response = await apiCall('get', url, {}, true);

            if (response && response.status >= 200 && response.status < 300) {
                const animalsInHospital: IAnimal[] = response.data.map(
                    animal => {
                        return {
                            animalId: animal.animalId,
                            eid: animal.eid,
                            animalTag: animal.animalTag,
                            currentPenId: animal.currentPenId,
                            currentPenName: animal.currentPen.name,
                            homePenId: animal.homePenId,
                            homePenName: animal.homePen?.name || '',
                            lotId: animal.lotId,
                            lotName: animal.lot.name,
                        };
                    },
                );

                dispatch(setHospitalAnimalsList(animalsInHospital));

                if (animalsInHospital.length) {
                    const hospitalAnimalsReports =
                        formatHospitalAnimalsList(animalsInHospital);
                    const excelStaticData: ICsvData[] = t(
                        'hospital:excelStaticData',
                        {
                            returnObjects: true,
                        },
                    );
                    dispatch(
                        setHospitalAnimalsReports([
                            ...excelStaticData,
                            ...hospitalAnimalsReports,
                        ]),
                    );
                }
            }
        } finally {
            dispatch(setLoading(false));
        }
    };

export const formatHospitalAnimalsList = response => {
    const hospitalAnimalsReports: ICsvData[] = [];
    response.forEach(animal => {
        hospitalAnimalsReports.push({
            hospitalPen: animal?.currentPenName || '',
            lot: animal?.lotName || '',
            eid: animal?.eid || '',
            animalId: animal?.animalTag || '',
            homePen: animal?.homePenName || '',
        });
    });

    return hospitalAnimalsReports;
};

export const setHospitalAnimalsList = (data: IAnimal[]) => {
    return {
        type: HospitalActionTypes.SET_HOSPITAL_ANIMALS_LIST,
        payload: data,
    };
};

export const setHospitalAnimalsReports = (data: ICsvData[]) => {
    return {
        type: HospitalActionTypes.SET_HOSPITAL_ANIMALS_REPORTS,
        payload: data,
    };
};

export const setAnimalAdminHistory = (data: IAnimalAdminHistory[]) => {
    return {
        type: HospitalActionTypes.SET_HOSPITAL_ANIMAL_ADMIN_HISTORY,
        payload: data,
    };
};

export const setHospitalPens = (data: IPen[]) => {
    return {
        type: HospitalActionTypes.SET_HOSPITAL_PENS,
        payload: data,
    };
};

export const moveCattleToDifferentPen =
    (
        feedlotId: number,
        payload: IUpdateAnimalPayload,
        languageValue: string,
        penFilter: string,
    ) =>
    async (dispatch: AppThunkDispatch) => {
        const response = await apiCall(
            'put',
            `${Constants.apiUrls.FEED_LOT_MANAGER}/${feedlotId}${Constants.apiUrls.ANIMAL}/${payload.animalId}`,
            payload,
            true,
        );
        const message =
            response?.status === Constants.responseCode.SUCCESS
                ? MoveFromHospitalPenModalConstants[languageValue]
                      .successMessage
                : null;
        dispatch(toastMessage(getToastContent(response.status, '', message)));
        if (message) {
            dispatch(fetchAnimalsHospital(feedlotId, penFilter));
        }
    };
