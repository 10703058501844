import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { api as instance } from '../../../../../Services/AxiosService';
import { useAppDispatch } from '../../../../../Redux/Store';
import { appLoaderActions } from '../slices/appLoaderSlice';
import { setDisplayTimeoutErrorModal } from '../../../../../Redux/Reducer/common';
import { feedlotManagerFeatures } from '../../../../feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA13269On_LongReportTimeouts } = feedlotManagerFeatures;

export const AxiosInterceptor: FC<PropsWithChildren> = ({ children }) => {
    const [reqCount, setReqCount] = useState(0);
    const [resCount, setResCount] = useState(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const reqInterceptor = response => {
            setReqCount(reqCount => reqCount + 1);
            return response;
        };

        const reqErrInterceptor = error => {
            setReqCount(reqCount => reqCount + 1);
            return Promise.reject(error);
        };

        const requestInterceptor = instance.interceptors.request.use(
            reqInterceptor,
            reqErrInterceptor,
        );

        const resInterceptor = response => {
            setResCount(resCount => resCount + 1);
            return response;
        };

        const errResInterceptor = error => {
            setResCount(resCount => resCount + 1);
            if (isCA13269On_LongReportTimeouts) {
                if (error?.code === 'ECONNABORTED') {
                    dispatch(setDisplayTimeoutErrorModal(true));
                }
            }
            return Promise.reject(error);
        };

        const responseInterceptor = instance.interceptors.response.use(
            resInterceptor,
            errResInterceptor,
        );

        return () => {
            instance.interceptors.response.eject(responseInterceptor);
            instance.interceptors.request.eject(requestInterceptor);
        };
    }, [dispatch]);

    useEffect(() => {
        dispatch(appLoaderActions.setIsRequestPending(reqCount !== resCount));
    }, [dispatch, reqCount, resCount]);

    return children;
};
