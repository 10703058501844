import { FeedlotManagerCommonEnumsQbConnectionFileType } from '../../Redux/Apis/QuickBooks/baseQuickBooksApi';
import { TabOption } from '../Interface/QuickBookInterface';

export const QuickBookConstant = {
    header: 'Connect Feedlot Manager to QuickBooks',
    connectPassword: 'QuickBooks Connection Password',
    fmSettingsQuickBookRoute: 'QuickBooksIntegration',
    tabOptions: [TabOption.No, TabOption.Yes],
    tabOptionTitle: {
        yes: 'Yes',
        no: 'No',
        inputName: 'yesNoTab',
    },
    active: {
        value: 'active',
    },
    quickBookDocument: 'QuickBooks Documents',
    documentFiles: [
        {
            name: 'Configuration File',
            key: 'QWC' as FeedlotManagerCommonEnumsQbConnectionFileType,
            extension: 'qwc',
        },
        {
            name: 'Invoice Template',
            key: 'InvoiceTemplate' as FeedlotManagerCommonEnumsQbConnectionFileType,
            extension: 'des',
        },
        {
            name: 'Connect to QuickBooks Guide',
            key: 'Instructions' as FeedlotManagerCommonEnumsQbConnectionFileType,
            extension: 'pdf',
        },
    ],
    messages: {
        okVerificationMessage:
            'Your connection file will take a moment to be created. Please reach out if it takes longer than 5 minutes to appear.',
        noConfirmationMessage:
            'Please confirm you want to disconnect to QuickBooks from Feedlot Manager. You will no longer be able to send invoice data to QuickBooks',
        fileAvailableMessage: 'QuickBooks connection file is now available.',
        connectionMomentMessage:
            'Your connection file will take a moment to be created. Please reach out if it takes longer than 5 minutes to appear.',
        errorDownloadFileMessage:
            'Error retrieving QuickBooks Config Document.',
        errorFMSettingCreateQb:
            'Something went wrong while creating FM Setting for QuickBooks configuration. Please try again.',
    },
    modal: {
        title: 'QuickBooks Web Connector Authentication',
        userInputPlaceHolder: 'Please Enter email',
    },
    validEmailMessage: 'Please enter a valid email address',
    qwcPassword: 'NGAT@1234567890',
    quickBooksResponseTime: {
        pollingInterval: 5000,
        pollingTimeout: 120000,
    },
    fmeSettingQuickBookRouteName: 'QuickBooksIntegration',
};
