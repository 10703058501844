import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { setShouldAllowBuyPoPageChange } from '../../apps/shippingTracker/modules/BuyPo/actions/buyPoActions';
import { setShouldAllowShipPoPageChange } from '../../apps/shippingTracker/modules/shippo/reducers/shipPoReducer';

const SidebarLogoBuyPo = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { shouldAllowPageChange } = useAppSelector(state => state.buyPO);
    const { shouldAllowPageChange: shouldAllowShipPoPageChange } =
        useAppSelector(state => state.shipPO);
    const handleClick = () => {
        if (shouldAllowPageChange.lastUpdate) {
            dispatch(
                setShouldAllowBuyPoPageChange({
                    ...shouldAllowPageChange,
                    showModal: true,
                    route: '/',
                }),
            );
        } else if (shouldAllowShipPoPageChange.lastUpdate) {
            dispatch(
                setShouldAllowShipPoPageChange({
                    ...shouldAllowShipPoPageChange,
                    showModal: true,
                    route: '/',
                }),
            );
        } else {
            navigate('/');
        }
    };

    return (
        <img
            {...props}
            alt="./sidebar_buypo.png"
            src={require('./sidebar_cattlepo.png')}
            title={`CC v${process.env.REACT_APP_VERSION || '0.0.0.0'}`}
            onClick={handleClick}
        />
    );
};

export default SidebarLogoBuyPo;
