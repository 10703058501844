import { TenantManagementModelsUserManagementUserModelRead } from '../../../../../../Redux/Apis/Users/baseUsersApi';
import { AppDropdownOptionForFilter } from '../../../../../common/modules/auth/constants/appDropdownOption';

export interface IUserManagementFilter {
    limit: number;
    offset: number;
    name: string;
    email: string;
    accountId: number;
    role: string;
    app: AppDropdownOptionForFilter;
    status: UserStatusEnum;
}

export enum UserStatusEnum {
    Active = 'Active',
    Inactive = 'Inactive',
    All = 'All',
}

export interface AccountRole {
    account: string | null | undefined;
    role: string | null | undefined;
}

export interface IUser {
    userId: number;
    name: string;
    email: string;
    apps: string[];
    roles: string[];
    status: UserStatusEnum;
    resetPassword: boolean;
    editUser: boolean;
}

export interface IFeedlot {
    accountId: number;
    name: string;
}

export interface IRole {
    feedlot?: string;
    name: string;
}

export interface IUserManagementInitialState {
    userManagementFilter: IUserManagementFilter;
    users: IUser[];
    feedlots: IFeedlot[];
    roles: string[];
    apps: string[];
}

export interface ISelectStatus {
    name: string;
    status: UserStatusEnum;
}

export interface IAdditionalRole {
    app: string;
    accountId: number;
    role: string;
}

export interface UserModelWithRequiredNames
    extends TenantManagementModelsUserManagementUserModelRead {
    firstName: string;
    lastName: string;
    username: string;
    enabled: boolean;
}

export interface ILocalFilter {
    name: string;
    email: string;
}
