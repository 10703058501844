import { getFeatureFlagValue } from './helpers';
import { ICommonFeatures } from './interface';

export const commonFeatures: ICommonFeatures = {
    isCA11388On_TermsOfServiceLoginScreen: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11388_TERMS_OF_SERVICE_LOGIN_SCREEN,
    ),
    isCA10347_useAppLoaderHookOn: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10347_ADD_USE_APP_LOADER_HOOK,
    ),
    enableAuth0Option: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_ENABLE_AUTH0_OPTION,
    ),
    isCA12555On_ScaleTicketCommodityDropdownBugFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12555_SCALE_TICKET_COMMODITY_DROPDOWN_BUG_FIX,
    ),
    isCA12793On_CloseAutocompleteDropdownOnTabThrough: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12793_CLOSE_AUTOCOMPLETE_DROPDOWN_ON_TAB_THROUGH,
    ),
    isCA12732_AutoCompleteDropDownCloseShiftTabList: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12732_AUTO_COMPLETE_DROP_DOWN_CLOSE_SHIFT_TAB_LIST,
    ),
    isCA12653On_FixHigherEnvInaccessible: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA12653_FIX_HIGHER_ENV_INACCESSIBLE,
    ),
    isCA13229On_AutocompleteAllowsNonOptionValues: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13229_AUTOCOMPLETE_ALLOWS_NON_OPTION_VALUES,
    ),
    isCA13259On_AutocompleteOptionHighlightCorrection: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13259_AUTOCOMPLETE_OPTION_HIGHLIGHT_CORRECTION,
    ),
    isCA13765On_TokenManagementRework: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13765_TOKEN_MANAGEMENT_REWORK,
    ),
    isCA13422On_AutocompleteShouldSelectOnlyFilteredMatch: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13422_AUTOCOMPLETE_SHOULD_SELECT_ONLY_FILTERED_MATCH,
    ),
    isCA13777On_ExportToExcelNotWorking: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA13777_EXPORT_TO_EXCEL_NOT_WORKING,
    ),
};
