import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import { apiCall_v2 } from '../../Services/AxiosService';
import Constants from '../../utils/Constants';
import {
    findErrorMessage,
    hasDetailedErrorInfo,
} from '../../utils/ErrorHelper';
import {
    ICreateLocation,
    IDeleteLocation,
    IFetchLocationDetails,
    IUpdateLocationDetail,
} from '../../utils/Interface/LocationInterface';
import { getToastContent } from '../../utils/ToastContent';
import { setErrorModal, toastMessage } from '../Reducer/common';
import { setLocationDetailsLoader } from '../Reducer/locationSlice';
import { AppDispatch } from '../Store';
import { getFilterUrl } from './FMSettingActions';

const { isCA13933On_BetterErrorsForDuplicateRecordsWith702 } =
    feedlotManagerFeatures;

export const fetchLocationDetails: IFetchLocationDetails =
    (setLocationDetail, filter) => async (dispatch: AppDispatch) => {
        dispatch(setLocationDetailsLoader(true));
        const filterUrl = filter ? getFilterUrl(filter, 'isActive') : '';
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_LOCATION}/odata?${filterUrl} and moduleName eq 'FMSource'&orderBy=Name`,
        });
        setLocationDetail(response ?? []);
        dispatch(setLocationDetailsLoader(false));
    };

export const updateLocationDetail: IUpdateLocationDetail =
    (
        locationDetail,
        fetchLocationData?,
        closeModalWithChanges?,
        setLoading?,
        handleActivateLocation?,
    ) =>
    async (dispatch: AppDispatch) => {
        setLoading?.(true);
        const response = await apiCall_v2({
            method: 'put',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_LOCATION}/${locationDetail.id}`,
            payload: locationDetail,
            isResRequired: true,
        });

        setLoading?.(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            if (
                isCA13933On_BetterErrorsForDuplicateRecordsWith702 &&
                hasDetailedErrorInfo(response)
            ) {
                dispatch(
                    setErrorModal({
                        showModal: true,
                        errorMessage: findErrorMessage(response, false),
                    }),
                );
            } else {
                dispatch(
                    toastMessage(
                        getToastContent(
                            response.status,
                            '',
                            Constants.customMessage.LOCATION_DUPLICATE,
                        ),
                    ),
                );
            }
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateLocation?.();
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
            closeModalWithChanges?.(true, locationDetail.id);
        }
        fetchLocationData?.();
    };

export const createLocation: ICreateLocation =
    (location, closeModalWithChanges, setLoading, handleActivateLocation) =>
    async (dispatch: AppDispatch) => {
        setLoading(true);
        const response = await apiCall_v2({
            method: 'post',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_LOCATION}`,
            payload: location,
            isResRequired: true,
        });
        setLoading(false);
        if (!response) return;
        const status = Number(response.status);

        if (status === Constants.responseCode.DUPLICATE) {
            if (isCA13933On_BetterErrorsForDuplicateRecordsWith702) {
                if (hasDetailedErrorInfo(response)) {
                    dispatch(
                        setErrorModal({
                            showModal: true,
                            errorMessage: findErrorMessage(response, false),
                        }),
                    );
                }
            } else {
                dispatch(
                    toastMessage(
                        getToastContent(
                            status,
                            '',
                            Constants.customMessage.LOCATION_DUPLICATE,
                        ),
                    ),
                );
            }
        } else if (status === Constants.responseCode.CONFLICT) {
            handleActivateLocation();
        } else {
            dispatch(toastMessage(getToastContent(status)));
            closeModalWithChanges(true, response.data);
        }
    };

export const fetchInactiveLocationInfo =
    (location?: string) => async (dispatch: AppDispatch) => {
        const response = await apiCall_v2({
            method: 'get',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_LOCATION}/odata?filter=name eq '${location}' and moduleName eq 'FMSource'`,
            isResRequired: true,
        });

        if (!response) return;
        const status = Number(response.status);

        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            return response.data;
        } else {
            dispatch(toastMessage(getToastContent(status)));
        }
    };

export const deleteLocation: IDeleteLocation =
    (location, fetchLocationData?) => async (dispatch: AppDispatch) => {
        dispatch(setLocationDetailsLoader(true));
        const response = await apiCall_v2({
            method: 'delete',
            url: `${Constants.apiUrls.FEED_LOT_MANAGER}${Constants.apiUrls.FM_LOCATION}/${location.id}`,
            payload: location,
            isResRequired: true,
        });
        dispatch(setLocationDetailsLoader(false));
        if (!response) return;
        dispatch(toastMessage(getToastContent(Number(response.status))));
        fetchLocationData?.();
    };
