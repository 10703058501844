import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Row, Form, Col } from 'react-bootstrap';
import { apiCall } from '../../Services/AxiosService';
import FinancialRoutes from '../../utils/constants/FinancialApp/FinancialRoutes';
import { validateAccordingType } from '../../utils/validation';
import ShawnaCard from './ShawnaFeedBillDetailCard';
import { useLots } from '../../utils/financialAppHooks';
import { formatDate } from '../../helpers/dateTime';
import Constants from '../../utils/Constants';
import {
    IGroupedMonth,
    IMonthWiseTotal,
    IShawnaSpreadSheet,
} from '../../utils/Interface/FinancialApp/ShawnaSpreadSheetInterface';
import { ShawnaSpreadSheetConstants } from '../../utils/constants/FinancialApp/ShawnaSpreadSheetConstants';
import { useOwnerContext } from '../../Context/OwnerContext/OwnerContext';
import ValueWithTooltip from './ValueWithToolKit';
import { useAppDispatch, useAppSelector } from '../../Redux/Store';
import { setId } from '../../Redux/Reducer/FinanceLotId';
import useUSCurrencyFormatter from '../../commonHooks/useUSCurrencyFormatter';
import dayjs from '../../utils/dayjs';

const ShawnaSpreadSheet = () => {
    const initialAllLots = useLots({
        orderBy: 'Name',
        $filter: 'closeDate eq null',
    });
    const dispatch = useAppDispatch();
    const { formatAsUSCurrency } = useUSCurrencyFormatter();
    const [selectedLot, setSelectedLot] = useState<string>();
    const { selectedOwner } = useOwnerContext();
    const [allLots, setAllLots] = useState(initialAllLots);
    const [lot, setLot] = useState<IShawnaSpreadSheet>();
    const [purchaseTotal, setpurchaseTotal] = useState(
        ShawnaSpreadSheetConstants.puchaseDataInitialState,
    );
    const [soldTotal, setSoldTotal] = useState(
        ShawnaSpreadSheetConstants.soldDataInitialState,
    );
    const [feedBillTotal, setFeedBillTotal] = useState(
        ShawnaSpreadSheetConstants.feedBillInitialState,
    );
    const [monthWiseTotal, setMonthWiseTotal] = useState<IMonthWiseTotal>({});
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const idLot = useAppSelector(state => state.lotId);
    const [groupedMonths, setGroupedMonths] = useState<IGroupedMonth>({});

    const calculateStartAndEndMonth = (
        groupedMonths: IGroupedMonth,
        year: string,
        lotCreationDate: Date | undefined,
    ): { startMonth: number; endMonth: number } => {
        let startMonth = 1;
        let endMonth = 12;
        const lotCreationYear =
            lotCreationDate && dayjs(lotCreationDate).toDate().getFullYear();
        const lotCreationMonth =
            lotCreationDate && dayjs(lotCreationDate).toDate().getMonth();

        if (Object.keys(groupedMonths).length <= 1) {
            startMonth = Math.min(
                lotCreationMonth ?? 0,
                ...groupedMonths[year].map(entry => entry.month),
            );
            endMonth = Math.max(
                ...groupedMonths[year].map(entry => entry.month),
            );
        } else {
            if (lotCreationYear === parseInt(year)) {
                startMonth = lotCreationMonth ?? 0;
                endMonth = 12;
            } else {
                startMonth = 0;
                endMonth = Math.max(
                    ...groupedMonths[year].map(entry => entry.month),
                );
            }
        }

        return { startMonth, endMonth };
    };

    useEffect(() => {
        if (!initialAllLots) return;
        if (selectedOwner.id !== '') {
            const filteredLots = initialAllLots.filter(
                lot => lot.customerId === selectedOwner.id,
            );
            setAllLots(filteredLots);
        } else {
            setAllLots(initialAllLots);
        }
    }, [initialAllLots, selectedOwner.id]);

    const resetState = () => {
        setSelectedLot('');
        setLot(undefined);
        setpurchaseTotal(ShawnaSpreadSheetConstants.puchaseDataInitialState);
        setSoldTotal(ShawnaSpreadSheetConstants.soldDataInitialState);
        setFeedBillTotal(ShawnaSpreadSheetConstants.feedBillInitialState);
        setMonthWiseTotal({});
        setTotalAmount(0);
    };

    useEffect(() => {
        resetState();
        dispatch(setId(''));
    }, [selectedOwner.name]);

    useEffect(() => {
        if (selectedLot === '') {
            resetState();
        }
    }, [selectedLot]);

    useEffect(() => {
        if (idLot) {
            setSelectedLot(idLot);
        }
    }, [idLot]);

    const handleChange = (
        { target }: ChangeEvent<HTMLInputElement>,
        fieldType?: string,
    ) => {
        if (fieldType && !validateAccordingType(fieldType, target.value)) {
            return;
        }
        setSelectedLot(target.value);
    };

    const getMonthName = monthIndex => {
        if (monthIndex >= 0 && monthIndex < Constants.monthList.length) {
            return Constants.monthList[monthIndex];
        }
        return 'Unknown';
    };

    const calculatePurchaseTotal = useCallback(() => {
        const totals = {
            price: 0,
            headCountChange: 0,
            weight: 0,
            weightPerHd: 0,
        };

        lot?.cattlePurchase?.forEach(purchaseEntry => {
            ShawnaSpreadSheetConstants.propertiesToSumIn.PurchaseData.forEach(
                property => {
                    totals[property] += purchaseEntry[property] || 0;
                },
            );
        });

        setpurchaseTotal(totals);
    }, [lot?.cattlePurchase, setpurchaseTotal]);

    const calculateSoldTotal = useCallback(() => {
        const total = { price: 0, headCountChange: 0, payWeight: 0 };
        lot?.cattleSale?.forEach(saleEntry => {
            ShawnaSpreadSheetConstants.propertiesToSumIn.SoldData.forEach(
                property => {
                    total[property] += saleEntry[property] || 0;
                },
            );
        });

        setSoldTotal(total);
    }, [lot?.cattleSale, setSoldTotal]);

    const calculateFeedBillTotal = useCallback(() => {
        const total = {
            purchase: 0,
            feed: 0,
            trucking: 0,
            brands: 0,
            processing: 0,
        };

        lot?.feedBillInfo?.forEach(feedBillEntry => {
            ShawnaSpreadSheetConstants.propertiesToSumIn.FeedBillData.forEach(
                property => {
                    total[property] += feedBillEntry[property] || 0;
                },
            );
        });

        setFeedBillTotal(total);
    }, [lot?.feedBillInfo, setFeedBillTotal]);

    const calculateMonthWiseTotal = useCallback(() => {
        if (!lot || !lot.feedBillInfo) return;
        const propertiesToSumSet = new Set(
            ShawnaSpreadSheetConstants.propertiesToSumIn.FeedBillData,
        );
        const updatedMonthWiseTotal = {};
        lot.feedBillInfo.forEach(feedBillEntry => {
            const { year, month } = feedBillEntry;
            const monthName = Constants.monthList[month - 1]?.toLowerCase();

            if (propertiesToSumSet.size > 0 && monthName) {
                if (!updatedMonthWiseTotal[year]) {
                    updatedMonthWiseTotal[year] = {};
                }
                if (!updatedMonthWiseTotal[year][monthName]) {
                    updatedMonthWiseTotal[year][monthName] = 0;
                }

                propertiesToSumSet.forEach(property => {
                    if (property in feedBillEntry) {
                        updatedMonthWiseTotal[year][monthName] +=
                            feedBillEntry[property];
                    }
                });
            }
        });

        const total = Object.keys(updatedMonthWiseTotal).reduce(
            (sum: number, year: string) => {
                const yearTotal = Object.keys(
                    updatedMonthWiseTotal[year],
                ).reduce((yearSum: number, month: string) => {
                    const monthTotal = updatedMonthWiseTotal[year][month] || 0;
                    return yearSum + monthTotal;
                }, 0);
                return sum + yearTotal;
            },
            0,
        );

        setMonthWiseTotal(updatedMonthWiseTotal);
        setTotalAmount(total);
    }, [lot?.feedBillInfo, setMonthWiseTotal]);

    useEffect(() => {
        const fetchData = async () => {
            const value = await apiCall(
                'get',
                `${FinancialRoutes.Api.LOTS}/${selectedLot}/LotSummary?includeLotActions=true`,
            );
            setLot(value);
        };
        if (selectedLot) {
            fetchData();
        }
    }, [selectedLot]);

    useEffect(() => {
        const groupedMonthsData = {};
        lot?.feedBillInfo.forEach(entry => {
            const { year }: { year: number } = entry;
            if (!groupedMonthsData[year]) {
                groupedMonthsData[year] = [];
            }
            groupedMonthsData[year].push(entry);
        });
        setGroupedMonths(groupedMonthsData);
    }, [selectedLot, lot]);

    useEffect(() => {
        calculateMonthWiseTotal();
        calculateFeedBillTotal();
        calculatePurchaseTotal();
        calculateSoldTotal();
    }, [selectedLot, lot]);

    return (
        <div className="pt-4">
            <section className="main-content">
                <div className="main-content-block">
                    <div className="white-content-block card-color">
                        <div className="d-flex mx-3 my-2 spreadsheet-header-details">
                            <Row>
                                <Form.Group as={Col}>
                                    <span>
                                        {
                                            ShawnaSpreadSheetConstants.heading
                                                .lotNumber
                                        }
                                    </span>
                                    <Form.Control
                                        name="feedLotId"
                                        as="select"
                                        value={selectedLot}
                                        onChange={handleChange}
                                        className="lot-input"
                                        required
                                    >
                                        <option value="">
                                            {
                                                ShawnaSpreadSheetConstants.other
                                                    .selectLot
                                            }
                                        </option>
                                        {allLots?.map(({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Row>
                            <div className="mx-2">
                                <span>
                                    {ShawnaSpreadSheetConstants.heading.sex}
                                </span>
                                <Form.Control
                                    value={lot?.lotInfo?.sex ?? ''}
                                    disabled
                                    className="narrow-input"
                                />
                            </div>
                            <div className="mx-2">
                                <span>
                                    {ShawnaSpreadSheetConstants.heading.death}
                                </span>
                                <Form.Control
                                    value={lot?.lotInfo?.deads ?? ''}
                                    disabled
                                    className="narrow-input"
                                />
                            </div>
                            <div className="mx-2">
                                <span>
                                    {
                                        ShawnaSpreadSheetConstants.heading
                                            .currentHeadCount
                                    }
                                </span>
                                <Form.Control
                                    value={lot?.lotInfo?.currentHeadCount ?? ''}
                                    disabled
                                    className="narrow-input"
                                />
                            </div>
                            <div className="mx-2">
                                <span>
                                    {
                                        ShawnaSpreadSheetConstants.heading
                                            .costPerHead
                                    }
                                </span>
                                <Form.Control
                                    value={
                                        lot?.lotInfo?.costPerHead !== undefined
                                            ? `$ ${formatAsUSCurrency(
                                                  lot.lotInfo.costPerHead.toFixed(
                                                      2,
                                                  ),
                                              )}`
                                            : ''
                                    }
                                    disabled
                                    className="narrow-input"
                                />
                            </div>
                        </div>
                        <div>
                            <span className="mx-3 spreadsheet-heading mb-4">
                                {ShawnaSpreadSheetConstants.heading.expenss}
                            </span>
                            <div className="display-feedbill-detail">
                                <div className="d-flex">
                                    <div className="feedbill-detail my-4">
                                        {ShawnaSpreadSheetConstants.feedBillDetail.map(
                                            feedBillConstant => (
                                                <p>{feedBillConstant}</p>
                                            ),
                                        )}
                                    </div>
                                    <div className="monthlist-size">
                                        <div className="d-flex div-size-month">
                                            {Object.keys(groupedMonths)
                                                .sort()
                                                .map(year => {
                                                    const {
                                                        startMonth,
                                                        endMonth,
                                                    } =
                                                        calculateStartAndEndMonth(
                                                            groupedMonths,
                                                            year,
                                                            lot?.lotInfo
                                                                .lotCreationDate,
                                                        );
                                                    return (
                                                        <>
                                                            {Array.from(
                                                                {
                                                                    length:
                                                                        endMonth -
                                                                        startMonth,
                                                                },
                                                                (
                                                                    _,
                                                                    monthIndex,
                                                                ) => (
                                                                    <div
                                                                        className="spreadsheet-month-list"
                                                                        key={`${year}_${monthIndex}`}
                                                                    >
                                                                        <span className="month-heading">
                                                                            {getMonthName(
                                                                                startMonth +
                                                                                    monthIndex,
                                                                            )}{' '}
                                                                            {
                                                                                year
                                                                            }
                                                                        </span>
                                                                        <ShawnaCard
                                                                            feedBill={
                                                                                groupedMonths[
                                                                                    year
                                                                                ] ??
                                                                                []
                                                                            }
                                                                            month={
                                                                                startMonth +
                                                                                monthIndex +
                                                                                1
                                                                            }
                                                                        />
                                                                        <span className="d-flex justify-content-between">
                                                                            <p className="d-inline">
                                                                                $
                                                                            </p>
                                                                            <p className="text-right">
                                                                                {formatAsUSCurrency(
                                                                                    monthWiseTotal?.[
                                                                                        year
                                                                                    ]?.[
                                                                                        getMonthName(
                                                                                            startMonth +
                                                                                                monthIndex,
                                                                                        )?.toLocaleLowerCase()
                                                                                    ]?.toFixed(
                                                                                        2,
                                                                                    ) ||
                                                                                        0,
                                                                                )}
                                                                            </p>
                                                                        </span>
                                                                    </div>
                                                                ),
                                                            )}
                                                        </>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                    <div className="total-value-div">
                                        <span>
                                            {
                                                ShawnaSpreadSheetConstants
                                                    .heading.total
                                            }
                                        </span>
                                        <div className="my-3">
                                            {JSON.stringify(feedBillTotal) !==
                                                JSON.stringify(
                                                    ShawnaSpreadSheetConstants.feedBillInitialState,
                                                ) &&
                                                feedBillTotal &&
                                                Object.keys(feedBillTotal).map(
                                                    key => (
                                                        <ValueWithTooltip
                                                            key={key}
                                                            value={
                                                                feedBillTotal[
                                                                    key
                                                                ]
                                                            }
                                                        />
                                                    ),
                                                )}

                                            {totalAmount !== 0 &&
                                                selectedLot && (
                                                    <p className="d-flex justify-content-between ">
                                                        <p className="d-inline mr-2">
                                                            $
                                                        </p>
                                                        <p className="text-right">
                                                            {formatAsUSCurrency(
                                                                totalAmount.toFixed(
                                                                    2,
                                                                ),
                                                            )}
                                                        </p>
                                                    </p>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="white-content-block">
                        <div className="row mx-2">
                            <div className="col-6 flex-container spreadsheet-heading">
                                {ShawnaSpreadSheetConstants.heading.purchase}
                            </div>
                            <div className="col-6 flex-container spreadsheet-heading">
                                {ShawnaSpreadSheetConstants.heading.sold}
                            </div>
                        </div>
                        <div className="d-flex ">
                            <div className="mx-3 white-content-block purchase-div-size">
                                <div className="table-box-with-sticky-footer">
                                    <div className="thead">
                                        {ShawnaSpreadSheetConstants.heading.purchaseEntry.map(
                                            value => (
                                                <div
                                                    className="column"
                                                    key={value}
                                                >
                                                    {value}
                                                </div>
                                            ),
                                        )}
                                    </div>
                                    <div className="tbody purchase-card-body">
                                        {lot?.cattlePurchase?.map(
                                            purchaseEntry => (
                                                <div className="tbody-row">
                                                    <div className="column">
                                                        <p>
                                                            {formatDate(
                                                                purchaseEntry.date,
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="column">
                                                        <p>
                                                            {
                                                                purchaseEntry.origin
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="column">
                                                        <p>
                                                            {
                                                                purchaseEntry.headCountChange
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="column">
                                                        <p>
                                                            ${' '}
                                                            {
                                                                purchaseEntry.weightPerHd
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="column">
                                                        <p>
                                                            {
                                                                purchaseEntry.weight
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="column">
                                                        <p>
                                                            ${' '}
                                                            {formatAsUSCurrency(
                                                                purchaseEntry.price,
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className="column">
                                                        <p>
                                                            ${' '}
                                                            {formatAsUSCurrency(
                                                                purchaseEntry.pricePerLb,
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                    <div className="tfoot">
                                        <div className="column heading-total">
                                            {
                                                ShawnaSpreadSheetConstants
                                                    .heading.total
                                            }
                                        </div>
                                        <div className="column" />
                                        <div className="column">
                                            <p>
                                                {purchaseTotal.headCountChange ||
                                                    0}
                                            </p>
                                        </div>
                                        <div className="column">
                                            ${' '}
                                            {formatAsUSCurrency(
                                                purchaseTotal.weightPerHd,
                                            )}
                                        </div>
                                        <div className="column" />
                                        <div className="column">
                                            <p>
                                                ${' '}
                                                {formatAsUSCurrency(
                                                    purchaseTotal.price,
                                                )}
                                            </p>
                                        </div>
                                        <div className="column" />
                                    </div>
                                </div>
                            </div>
                            <div className="mr-3 white-content-block purchase-div-size">
                                <div className="table-box-with-sticky-footer">
                                    <div className="thead">
                                        {ShawnaSpreadSheetConstants.heading.SoldEntry.map(
                                            value => (
                                                <div className="column">
                                                    {value}
                                                </div>
                                            ),
                                        )}
                                    </div>
                                    <div className="tbody">
                                        {lot?.cattleSale?.map(saleEntry => (
                                            <div className="tbody-row">
                                                <div className="column">
                                                    <p>
                                                        {formatDate(
                                                            saleEntry.date,
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="column">
                                                    <p>{saleEntry.buyer}</p>
                                                </div>
                                                <div className="column">
                                                    <p>
                                                        {
                                                            saleEntry.headCountChange
                                                        }
                                                    </p>
                                                </div>
                                                <div className="column">
                                                    <p>
                                                        ${' '}
                                                        {formatAsUSCurrency(
                                                            saleEntry.pricePerHd.toFixed(
                                                                2,
                                                            ),
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="column">
                                                    <p>
                                                        ${' '}
                                                        {formatAsUSCurrency(
                                                            saleEntry.price,
                                                        )}
                                                    </p>
                                                </div>
                                                <div className="column">
                                                    <p>{saleEntry.payWeight}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="tfoot">
                                        <div className="column heading-total">
                                            {
                                                ShawnaSpreadSheetConstants
                                                    .heading.total
                                            }
                                        </div>
                                        <div className="column"></div>
                                        <div className="column">
                                            <p>{soldTotal?.headCountChange}</p>
                                        </div>
                                        <div className="column"></div>
                                        <div className="column">
                                            <p>
                                                ${' '}
                                                {formatAsUSCurrency(
                                                    soldTotal.price,
                                                )}
                                            </p>
                                        </div>
                                        <div className="column">
                                            <p>{soldTotal?.payWeight}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default ShawnaSpreadSheet;
