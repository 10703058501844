import Big from 'big.js';
import { INumericalOperations } from './numericalOperationsInterfaces';

export const bigJsOperations: INumericalOperations = {
    add: (num1: number, num2: number): number => {
        return Big(num1).plus(Big(num2)).toNumber();
    },
    subtract: (num1: number, num2: number): number => {
        return Big(num1).minus(Big(num2)).toNumber();
    },
    multiply: (num1: number, num2: number): number => {
        return Big(num1).times(Big(num2)).toNumber();
    },
    divide: (num1: number, num2: number): number => {
        if (num2 === 0) throw new Error('Cannot divide by zero');
        return Big(num1).div(Big(num2)).toNumber();
    },
};
