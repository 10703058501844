import { Button, Col, Spinner } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import AutocompleteDropdown from '../common/autocompleteDropdown/AutocompleteDropdown';
import DeleteIcon from '../../Assets/DeleteIcon';
import { penConstants } from '../../utils/constants/PenDetailsConstant';
import { PEN_INITIAL_STATE } from '../../Redux/Constants/penInitialState';
import AddRowButton from '../common/AddRowButton';
import {
    createPen,
    fetchInactivePenInfo,
    updatePenDetail,
} from '../../Redux/Actions/penActions';
import { useFeedlotId } from '../../commonHooks/useFeedlotId';
import { useAppDispatch } from '../../Redux/Store';
import {
    IPenDetail,
    IPenDetailsError,
} from '../../utils/Interface/PenInterface';
import { validateAccordingType } from '../../utils/validation';
import { useNavigate } from 'react-router-dom';
import Constants from '../../utils/Constants';
import { useModalStatus } from '../../commonHooks/useModalStatus';
import { ActivateItemModal } from '../../apps/shippingTracker/modules/BuyPo/components/ActivateItemModal';
import { useLoading } from '../../commonHooks/useLoading';
import { FmSettingsConstant } from '../../utils/constants/FmSettingsConstants';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';

const { isCA13933On_BetterErrorsForDuplicateRecordsWith702 } =
    feedlotManagerFeatures;

const CreatePen = () => {
    const [penDetailsError, setPenDetailsError] = useState<IPenDetailsError[]>(
        penConstants.initialPenDetailsError,
    );
    const [deactivatedPens, setDeactivatedPens] = useState<string>('');
    const [penDetail, setPenDetails] = useState<IPenDetail[]>([]);
    const { isCA10320On_EditDeletePenSettings } = feedlotManagerFeatures;
    const { loading, setLoading } = useLoading();
    const {
        isOpen: isActivateModalOpen,
        open: openActivateModal,
        close: closeActivateModal,
    } = useModalStatus();
    const feedlotId = useFeedlotId();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const validatePenDetails = (penDetails: IPenDetail[]): boolean => {
        let isValid = true;
        const updatedPenDetailsErrors = [...penDetailsError];

        penDetails.forEach((pen, index) => {
            updatedPenDetailsErrors[index] = {
                name: pen.name?.trim() === '',
                kind: pen.kind.trim() === '',
            };
            const hasErrors = Object.values(
                updatedPenDetailsErrors[index],
            ).some(e => e);

            if (hasErrors) {
                isValid = false;
            }
        });

        setPenDetailsError(updatedPenDetailsErrors);
        return isValid;
    };

    const handleDetailChange = (
        fieldValue: string | number | boolean,
        fieldName: string,
        index: number,
        type?: string,
    ): void => {
        const sanitizedValue =
            isCA13933On_BetterErrorsForDuplicateRecordsWith702 &&
            typeof fieldValue === 'string'
                ? fieldValue.trim()
                : fieldValue;
        const isValid = validateAccordingType(type, sanitizedValue);

        if (isValid) {
            const updatedPen = [...penDetail];
            updatedPen[index] = {
                ...updatedPen[index],
                [fieldName]:
                    fieldName === 'headCapacity'
                        ? Number(sanitizedValue)
                        : sanitizedValue,
            };
            setPenDetails(updatedPen);
        }
    };

    const addPenDetail = (): void => {
        if (feedlotId) {
            setPenDetails(prevPenDetails => [
                ...prevPenDetails,
                {
                    ...PEN_INITIAL_STATE.createPenInitial,
                    feedLotId: feedlotId,
                },
            ]);
        }
    };

    const removePenDetail = (penName: string): void => {
        setPenDetails(prevPenDetails => {
            const index = prevPenDetails.findIndex(pen => pen.name === penName);
            if (index !== -1) {
                const updatedPenDetail = [
                    ...prevPenDetails.slice(0, index),
                    ...prevPenDetails.slice(index + 1),
                ];
                return updatedPenDetail;
            }
            return prevPenDetails;
        });

        const index = penDetail.findIndex(pen => pen.name === penName);
        const updatedPenDetailsErrors = [...penDetailsError];
        updatedPenDetailsErrors.splice(index, 1);
        setPenDetailsError(updatedPenDetailsErrors);
    };

    const resetPenDetails = (): void => {
        if (feedlotId) {
            setPenDetails([
                {
                    ...PEN_INITIAL_STATE.createPenInitial,
                    feedLotId: feedlotId,
                },
            ]);
        }
    };

    const moveToPenDetails = () => {
        navigate(Constants.Routes.PENS);
    };

    const formatResponse = (response: string) => {
        const entriesString = response.split(':')[1].trim();
        return entriesString;
    };

    const handleCreatePen = async (): Promise<void> => {
        const isValid = validatePenDetails(penDetail);
        if (feedlotId && isValid) {
            const response = await dispatch(
                createPen(feedlotId, penDetail, moveToPenDetails),
            );
            if (response) {
                const formattedResponse = formatResponse(
                    isCA13933On_BetterErrorsForDuplicateRecordsWith702
                        ? (response?.detail ?? '')
                        : response.title,
                );
                if (
                    Number(response.status) === Constants.responseCode.DUPLICATE
                ) {
                    handleDuplicatePen(formattedResponse);
                } else {
                    setDeactivatedPens(formattedResponse);
                    openActivateModal();
                }
            }
        }
    };

    const handleDuplicatePen = (response: string) => {
        const duplicatePens = response.split(', ');
        const updatedPenDetailsErrors = [...penDetailsError];
        duplicatePens.forEach(duplicatePen => {
            const index = penDetail.findIndex(pen => pen.name === duplicatePen);
            if (index !== -1) {
                updatedPenDetailsErrors[index] = {
                    ...updatedPenDetailsErrors[index],
                    name: true,
                };
            }
        });
        setPenDetailsError(updatedPenDetailsErrors);
    };

    const handleCloseActivateModal = async (isActivated: boolean) => {
        const deactivatePen = deactivatedPens.split(', ');
        for (const penName of deactivatePen) {
            if (isActivated && feedlotId) {
                const inactivePen = await dispatch(
                    fetchInactivePenInfo(feedlotId, penName),
                );
                if (inactivePen) {
                    const inActivePenCopy = {
                        ...inactivePen[0],
                        active: true,
                    };
                    dispatch(
                        updatePenDetail(feedlotId, inActivePenCopy, {
                            setLoading,
                        }),
                    );
                }
                removePenDetail(penName);
            }
        }
        closeActivateModal();
    };

    useEffect(() => {
        resetPenDetails();
        setPenDetailsError(penConstants.initialPenDetailsError);
    }, [feedlotId]);

    return (
        <>
            {loading && (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner
                        animation="border"
                        data-size="lg"
                        className="text-center"
                    />
                </div>
            )}
            <ActivateItemModal
                showModal={isActivateModalOpen}
                closeModalHandler={handleCloseActivateModal}
                itemName={deactivatedPens}
                item={penConstants.activateModalConstant}
            />
            <div className="pens-container py-4">
                <h5>{penConstants.createNewPens.heading}</h5>
                {penDetail.map((pen, index) => (
                    <div className="d-flex" key={index}>
                        <Col xs={2} className="d-flex flex-column m-2">
                            <label>
                                {penConstants.createNewPens.form.name.label}
                                <span className="necessaryFields">*</span>
                            </label>
                            <input
                                className={`text-secondary border-${
                                    penDetailsError[index]?.name ? 'danger' : ''
                                }`}
                                type="text"
                                name={penConstants.createNewPens.form.name.name}
                                value={pen.name}
                                placeholder={
                                    penConstants.createNewPens.form.name
                                        .placeholder
                                }
                                maxLength={
                                    penConstants.createNewPens.form.name
                                        .maxLength
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        index,
                                        'string',
                                    )
                                }
                            />
                        </Col>
                        <Col xs={2} className="d-flex flex-column m-2">
                            <label>
                                {penConstants.createNewPens.form.penType.label}
                                <span className="necessaryFields">*</span>
                            </label>
                            <AutocompleteDropdown
                                options={
                                    penConstants.createNewPens.form.penType
                                        .options
                                }
                                nameKey={
                                    penConstants.createNewPens.form.penType.name
                                }
                                valueKey={
                                    penConstants.createNewPens.form.penType.name
                                }
                                selected={pen.kind}
                                placeholder={
                                    penConstants.createNewPens.form.penType
                                        .placeholder
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        'kind',
                                        index,
                                    )
                                }
                                styleError={
                                    penDetailsError[index]?.kind &&
                                    penConstants.createNewPens.form.penType.name
                                }
                            />
                        </Col>
                        <Col xs={2} className="d-flex flex-column m-2">
                            <label>
                                {penConstants.createNewPens.form.capacity.label}
                            </label>
                            <input
                                className="text-secondary"
                                type="text"
                                name={
                                    penConstants.createNewPens.form.capacity
                                        .name
                                }
                                placeholder={
                                    penConstants.createNewPens.form.capacity
                                        .placeholder
                                }
                                value={pen.headCapacity}
                                maxLength={
                                    penConstants.createNewPens.form.capacity
                                        .maxLength
                                }
                                onChange={e =>
                                    handleDetailChange(
                                        e.target.value,
                                        e.target.name,
                                        index,
                                        'number',
                                    )
                                }
                            />
                        </Col>
                        {isCA10320On_EditDeletePenSettings && (
                            <Col xs={2} className="custom-checkbox mt-4">
                                <input
                                    type="checkbox"
                                    checked={pen.active}
                                    className="mx-4 mt-2"
                                    onChange={e =>
                                        handleDetailChange(
                                            !pen.active,
                                            'active',
                                            index,
                                        )
                                    }
                                />
                                <label className="mx-2">
                                    {FmSettingsConstant.active.label}
                                </label>
                            </Col>
                        )}
                        <Col xs={2} className="d-flex align-items-end m-3">
                            {penDetail.length > 1 && (
                                <DeleteIcon
                                    className="cursor-pointer delete-icon"
                                    style={{ marginRight: '10px' }}
                                    onClick={() => removePenDetail(pen.name)}
                                />
                            )}
                        </Col>
                    </div>
                ))}
                <div className="d-flex p-2 justify-content-end col-6">
                    <AddRowButton onClick={() => addPenDetail()} />
                </div>
                <div className="position-absolute bottom-0 end-0 m-4">
                    <Button
                        className="secondary mx-2"
                        onClick={moveToPenDetails}
                    >
                        {penConstants.createNewPens.form.cancel}
                    </Button>
                    <Button onClick={handleCreatePen}>
                        {penConstants.createNewPens.form.savePens}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CreatePen;
