import { commonFeatures } from '../../apps/common/featureFlags/commonFeatures';
import { feedlotManagerFeatures } from '../../apps/feedlotManager/featureFlags/feedlotManagerFeatures';
import StorageService from '../../Services/storage/storage.service';
import Constants from '../../utils/Constants';
import { sideNavTabs } from '../../utils/Interface/CommonInterface';
import { LabelConstant } from '../../utils/LabelConstant';
import { FeedlotManagerModelsResponseModelsFeedlotApiResponse } from '../Apis/FMFeedlot/baseFMFeedlotApi';
const { isCA10586On_refactorFeedCall } = feedlotManagerFeatures;
const { isCA13765On_TokenManagementRework } = commonFeatures;
/**
 * Application Common initial state
 */
export const COMMON_INITIAL_STATE: {
    isSideNavHidden: boolean;
    toastMessage: { showMessage: boolean; message: string; type: string };
    errorModal: { showModal: boolean; errorMessage: string };
    windowSize: { innerWidth: number; innerHeight: number };
    feeTypes: { name: string; value: string }[];
    feeRateTypes: { name: string; value: string }[];
    slideTypes: { name: string; value: string }[];
    feedlotToUpdate: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>;
    selectedFeedlot: Required<FeedlotManagerModelsResponseModelsFeedlotApiResponse>;
    selectedLot: string;
    displayTimeoutErrorModal: boolean;
    showSupportModal: boolean;
    selectedLanguage: { value: string; name: string };
    currentSelectedTab: sideNavTabs;
} = {
    isSideNavHidden: false,
    toastMessage: {
        showMessage: false,
        message: '',
        type: '',
    },
    errorModal: {
        showModal: false,
        errorMessage: '',
    },
    windowSize: {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
    },
    feeTypes: [
        { name: LabelConstant.FeeType.SELECT_FEE_TYPE, value: '' },
        {
            name: LabelConstant.FeeType.TYPE_COMMISSION,
            value: LabelConstant.FeeType.TYPE_COMMISSION,
        },
        {
            name: LabelConstant.FeeType.TYPE_FREIGHT,
            value: LabelConstant.FeeType.TYPE_FREIGHT,
        },
        {
            name: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
            value: LabelConstant.FeeType.TYPE_MISCELLANEOUS,
        },
        {
            name: LabelConstant.FeeType.TYPE_BRAND,
            value: LabelConstant.FeeType.TYPE_BRAND,
        },
    ],
    feeRateTypes: [
        { name: LabelConstant.FeeRateType.SELECT_RATE_TYPE, value: '' },
        {
            name: LabelConstant.FeeRateType.TYPE_CWT,
            value: LabelConstant.FeeRateType.TYPE_CWT,
        },
        {
            name: LabelConstant.FeeRateType.TYPE_PER_HD_CT,
            value: LabelConstant.FeeRateType.TYPE_PER_HD_CT,
        },
        {
            name: LabelConstant.FeeRateType.TYPE_PERCENTAGE,
            value: LabelConstant.FeeRateType.TYPE_PERCENTAGE,
        },
        {
            name: LabelConstant.FeeRateType.TYPE_PER_MILE,
            value: LabelConstant.FeeRateType.TYPE_PER_MILE,
        },
        {
            name: LabelConstant.FeeRateType.TYPE_FLAT,
            value: LabelConstant.FeeRateType.TYPE_FLAT,
        },
    ],
    slideTypes: [
        { name: LabelConstant.SlideType.SELECT_SLIDE_TYPE, value: '' },
        {
            name: LabelConstant.SlideType.TYPE_TRADITIONAL_ALL,
            value: LabelConstant.SlideType.TYPE_TRADITIONAL_ALL,
        },
        {
            name: LabelConstant.SlideType.TYPE_TRADITIONAL_SPLIT,
            value: LabelConstant.SlideType.TYPE_TRADITIONAL_SPLIT,
        },
        {
            name: LabelConstant.SlideType.TYPE_CALIFORNIA,
            value: LabelConstant.SlideType.TYPE_CALIFORNIA,
        },
        {
            name: LabelConstant.SlideType.TYPE_HARD_STOP,
            value: LabelConstant.SlideType.TYPE_HARD_STOP,
        },
    ],
    feedlotToUpdate: {
        id: 0,
        label: '',
        labelColorHex: '',
        manager: '',
        timezoneInfo: '',
        tzUtcMinuteOffset: 0,
        tzUtcTimeStartOfDay: '',
        locationId: '',
    },
    selectedFeedlot: isCA10586On_refactorFeedCall
        ? (new StorageService().getValue(
              Constants.StorageItems.SELECTED_FEEDLOT,
              Constants.Storage.LOCAL,
          ) ?? {
              id: 0,
              label: '',
              labelColorHex: '',
              manager: '',
              timezoneInfo: '',
              tzUtcMinuteOffset: 0,
              tzUtcTimeStartOfDay: '',
          })
        : {
              id: 0,
              label: '',
              labelColorHex: '',
              manager: '',
              timezoneInfo: '',
              tzUtcMinuteOffset: 0,
              tzUtcTimeStartOfDay: '',
          },
    selectedLot: '',
    displayTimeoutErrorModal: false,
    showSupportModal: false,
    selectedLanguage: { ...Constants.Languages[0] },
    currentSelectedTab: isCA13765On_TokenManagementRework
        ? (new StorageService().getValue(
              Constants.StorageItems.SIDE_NAV_TAB,
              Constants.Storage.SESSION,
          ) ?? sideNavTabs.NotSelected)
        : sideNavTabs.NotSelected,
};
