import { useEffect, useState } from 'react';
import { Card, Button, Col } from 'react-bootstrap';
import { apiCall } from '../../Services/AxiosService';
import { useOwnerContext } from '../../Context/OwnerContext/OwnerContext';
import Constants from '../../utils/Constants';
import saveAs from 'file-saver';
import { useAppDispatch } from '../../Redux/Store';
import { getToastContent } from '../../utils/ToastContent';
import FinancialRoutes from '../../utils/constants/FinancialApp/FinancialRoutes';
import { toastMessage } from '../../Redux/Reducer/common';
import { formatDate } from '../../helpers';
import dayjs from '../../utils/dayjs';

export default function ProjectBreakevenReport() {
    const { selectedOwner } = useOwnerContext();
    const [baseUrl, setBaseUrl] = useState<string>(
        `${FinancialRoutes.Api.PROJECT_BREAKEVEN_REPORT}?includeLotActions=true&includeHedgeContracts=true`,
    );
    const dispatch = useAppDispatch();

    const downloadProjectBreakevenReport = async () => {
        const response = await apiCall('get', baseUrl, {}, true, true, false);
        if (
            response.status >= Constants.responseCode.SUCCESS &&
            response.status < Constants.responseCode.MULTIPLE_CHOICES
        ) {
            const blob = new Blob([response.data], {
                type: 'application/octet-stream',
            });
            const currentDate = formatDate(dayjs(), 'YYYY-MM-DD');
            const fileName = `ProjectedBreakevenReport_${currentDate}_${selectedOwner.name}.xlsx`;

            saveAs(blob, fileName);
        } else {
            dispatch(toastMessage(getToastContent(response.status)));
        }
    };

    useEffect(() => {
        if (selectedOwner.id !== '') {
            const customerIdQueryParam = `customerId=${selectedOwner.id}`;
            const apiUrl = `${FinancialRoutes.Api.PROJECT_BREAKEVEN_REPORT}?${customerIdQueryParam}&includeLotActions=true&includeHedgeContracts=true`;
            setBaseUrl(apiUrl);
        } else {
            const apiUrl = `${FinancialRoutes.Api.PROJECT_BREAKEVEN_REPORT}?includeLotActions=true&includeHedgeContracts=true`;
            setBaseUrl(apiUrl);
        }
    }, [selectedOwner]);

    return (
        <div className="pt-4">
            <section className="main-content">
                <div className="main-content-block">
                    <div className="white-content-block">
                        <div className="row justify-content-center">
                            <h3 className="text-center my-3">
                                {
                                    Constants.headerConstants
                                        .projectBreakevenReport
                                }
                            </h3>
                            <div className="col-12">
                                <Card className="shadow">
                                    <Card.Header>
                                        <Col className="text-center my-2">
                                            <Button
                                                onClick={() =>
                                                    downloadProjectBreakevenReport()
                                                }
                                            >
                                                {
                                                    Constants.button
                                                        .downloadReport
                                                }
                                            </Button>
                                        </Col>
                                    </Card.Header>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
