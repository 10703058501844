import { useEffect, useRef, useState } from 'react';
import useAuthContext from '../../apps/common/modules/auth/hooks/useAuthContext';
import LogInLogOutComponent from './LogInLogOutComponent';
import ProductsDropdown from '../../apps/common/modules/products/components/ProductsDropdown';
import { ChangePasswordModalLink } from '../../apps/common/modules/auth/components/ChangePasswordModalLink';
import {
    Roles,
    RolesToHideFromUser,
} from '../../apps/common/modules/auth/constants/roles';

export default function UserProfileDropdown() {
    const { getUserName, getUsersRoles } = useAuthContext();
    const usersRoles = getUsersRoles();
    const [userName, setUserName] = useState<string>('');
    const [isActive, setIsActive] = useState<boolean>(false);
    const userDropdown = useRef<HTMLElement | null>(null);
    const userDropdownButton = useRef<HTMLElement | null>(null);

    useEffect(() => {
        userDropdown.current = document.getElementById('userdropdown');
        userDropdownButton.current =
            document.getElementById('userdropdown-btn');
    }, []);

    useEffect(() => {
        const checkIfClickedOutside = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            if (
                isActive &&
                userDropdown.current &&
                !userDropdown.current.contains(target) &&
                userDropdownButton.current &&
                !userDropdownButton.current.contains(target)
            ) {
                setIsActive(false);
            }
        };
        document.addEventListener('click', checkIfClickedOutside);
        return () => {
            document.removeEventListener('click', checkIfClickedOutside);
        };
    }, [isActive]);

    useEffect(() => {
        setUserName(getUserName());
    }, [getUserName]);

    return (
        <div>
            <button
                id="userdropdown-btn"
                className="top-user-icon"
                onClick={() => setIsActive(prev => !prev)}
            >
                <i className="fas fa-user"></i>
            </button>
            <div
                id="userdropdown"
                className={`user-dropdown ${isActive ? 'active' : ''}`}
            >
                <div className="user-profile d-flex">
                    <div className="inner-user-icon">
                        <button className="top-user-icon">
                            <i className="fas fa-user"></i>
                        </button>
                    </div>
                    <div className="user-profile-detail">
                        <h5 className="m-0">
                            <strong>{userName}</strong>
                        </h5>
                        {usersRoles
                            .filter(
                                role =>
                                    !RolesToHideFromUser.includes(
                                        role as Roles,
                                    ),
                            )
                            .map((item: string, index: number) => {
                                return (
                                    <span className="m-0" key={index}>
                                        {' '}
                                        {item}
                                    </span>
                                );
                            })}
                    </div>
                </div>

                <div className="dropdown-list">
                    <ul>
                        {usersRoles.some(
                            x => x === Roles.Admin || x === Roles.Manager,
                        ) ? (
                            <ProductsDropdown
                                isActive={isActive}
                                setIsActive={setIsActive}
                            />
                        ) : (
                            ''
                        )}
                        <ChangePasswordModalLink />
                        <LogInLogOutComponent />
                    </ul>
                </div>
            </div>
        </div>
    );
}
