import { AnyAction } from '@reduxjs/toolkit';
import { ActionTypes } from '../../../../../Redux/Actions';
import { CREATE_BUY_PO_INITIAL_STATE } from '../constants/buyPoInitialState';

const BuyPoReducer = (
    state = CREATE_BUY_PO_INITIAL_STATE,
    action: AnyAction,
) => {
    let stateCopy = { ...state };

    switch (action.type) {
        case ActionTypes.SET_GLOBAL_INFO_BUYPO:
            stateCopy = {
                ...state,
                buyPoGlobalInfo: action.payload,
            };
            return stateCopy;
        case ActionTypes.UPDATE_GLOBAL_INFO_BUYPO_ID:
            stateCopy = {
                ...state,
                buyPoGlobalInfo: {
                    ...state.buyPoGlobalInfo,
                    id: action.payload,
                },
            };
            return stateCopy;
        case ActionTypes.SET_GLOBAL_INFO_ERROR_BUYPO:
            stateCopy = {
                ...state,
                buyPoGlobalInfoError: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_COMPONENTS_STEP_STATE_BUYPO:
            stateCopy = {
                ...state,
                buyPOViewSteps: { ...state.buyPOViewSteps, ...action.payload },
            };
            return stateCopy;
        case ActionTypes.SET_ORDERS_STATE_BUYPO:
            stateCopy = {
                ...state,
                orders: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_ORDERS_AND_FEE_TOTAL_STATE_BUYPO:
            stateCopy = {
                ...state,
                ordersAndFeeTotal: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_FEE_TYPES_STATE_BUYPO:
            stateCopy = {
                ...state,
                fees: action.payload,
            };

            return stateCopy;
        case ActionTypes.SET_LAST_MODIFIED_DETAILS_BUYPO:
            stateCopy = {
                ...state,
                lastModifiedDate: action.payload.lastModifiedDate,
                lastModifiedUser: action.payload.lastModifiedUser,
            };

            return stateCopy;
        case ActionTypes.SET_DESTINATION_DETAIL_BUY_PO:
            stateCopy = {
                ...state,
                selectedDestinationDetail: action.payload,
            };

            return stateCopy;
        case ActionTypes.SET_BUY_PO_SUBMITTED:
            stateCopy = {
                ...state,
                isSubmitted: action.payload,
            };

            return stateCopy;
        case ActionTypes.SET_PAYMENT_INFO:
            stateCopy = {
                ...state,
                paymentInfo: { ...state.paymentInfo, ...action.payload },
            };

            return stateCopy;
        case ActionTypes.SET_BUY_PO_FILE_DETAIL:
            stateCopy = {
                ...state,
                buyPoGlobalInfo: {
                    ...state.buyPoGlobalInfo,
                    ...action.payload,
                },
            };
            return stateCopy;
        case ActionTypes.RESET_ALL_BUY_PO_CREATE_STATES:
            stateCopy = {
                ...state,
                ...action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_BUY_PO_LIST:
            stateCopy = {
                ...state,
                buyPOList: action.payload,
            };

            return stateCopy;
        case ActionTypes.APPEND_BUY_PO_LIST:
            stateCopy = {
                ...state,
                buyPOList: [...state.buyPOList, ...action.payload],
            };

            return stateCopy;
        case ActionTypes.SET_DELETE_BUY_PO_IDs:
            stateCopy = {
                ...state,
                deleteBuyPOIds: action.payload,
            };

            return stateCopy;
        case ActionTypes.SET_BUY_PO_FILTER:
            stateCopy = {
                ...state,
                filter: action.payload,
            };

            return stateCopy;
        case ActionTypes.APPEND_BUY_PO_FILTER:
            stateCopy = {
                ...state,
                filter: { ...state.filter, ...action.payload },
            };

            return stateCopy;
        case ActionTypes.SET_BUY_PO_PAYMENT_LOADING:
            stateCopy = {
                ...state,
                paymentLoading: action.payload,
            };

            return stateCopy;
        case ActionTypes.SET_BUY_PO_LOADING:
            stateCopy = {
                ...state,
                loading: action.payload,
            };

            return stateCopy;
        case ActionTypes.SET_BUY_PO_CAN_CALL_SERVICE:
            stateCopy = {
                ...state,
                canCallService: action.payload,
            };

            return stateCopy;
        case ActionTypes.RESET_ALL_BUY_PO_LIST_STATES:
            stateCopy = {
                ...state,
                ...action.payload,
            };

            return stateCopy;
        case ActionTypes.SET_BUY_PO_EXCEL_RECORD:
            stateCopy = {
                ...state,
                buyPoReport: action.payload,
            };

            return stateCopy;

        case ActionTypes.SET_DATE_RANGE_BUYPO:
            stateCopy = {
                ...state,
                dateRange: action.payload,
            };
            return stateCopy;
        case ActionTypes.SET_SHOULD_ALLOW_BUYPO_PAGE_CHANGE:
            stateCopy = {
                ...state,
                shouldAllowPageChange: action.payload,
            };
            return stateCopy;
        default:
            return state;
    }
};

export default BuyPoReducer;
