import { Modal, Form } from 'react-bootstrap';
import Constants from '../../../../../../utils/Constants';
import { IQuickBookVerificationModalProps } from '../../../../../../utils/Interface/QuickBookInterface';
import { QuickBookConstant } from '../../../../../../utils/constants/QuickBookConstant';
import { useAppDispatch, useAppSelector } from '../../../../../../Redux/Store';
import {
    setInputError,
    setUserEmail,
} from '../../../../../../Redux/Reducer/quickBookSlice';

const QuickBookVerificationModal = ({
    showModal,
    closeModalHandler,
    closeModal,
}: IQuickBookVerificationModalProps) => {
    const dispatch = useAppDispatch();
    const { userEmail, inputErrors } = useAppSelector(state => state.quickBook);
    const handleDetailChange = (user: string) => {
        dispatch(setInputError({ ...inputErrors, userName: false }));
        dispatch(setUserEmail(user));
    };

    return (
        <Modal
            show={showModal}
            size="sm"
            centered
            animation
            onHide={closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>{QuickBookConstant.modal.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column justify-content-center">
                    <Form.Group>
                        <Form.Label className="text-secondary">
                            {'Email'}
                            <span className="necessaryFields">*</span>
                        </Form.Label>
                        <Form.Control
                            className={`text-secondary ${inputErrors.userName ? 'border border-danger' : ''}`}
                            value={userEmail}
                            placeholder={
                                QuickBookConstant.modal.userInputPlaceHolder
                            }
                            onChange={e => handleDetailChange(e.target.value)}
                            size="sm"
                            type="text"
                        />
                        <Form.Label className="text-danger">
                            {inputErrors.userName &&
                                QuickBookConstant.validEmailMessage}
                        </Form.Label>
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer className="border-top-0">
                <button
                    type="button"
                    className="secondary"
                    data-bs-dismiss="modal"
                    onClick={closeModal}
                >
                    {'Cancel'}
                </button>
                <button
                    type="button"
                    className={`${!userEmail && 'shipping-blur'}`}
                    onClick={closeModalHandler}
                    disabled={!userEmail}
                >
                    {Constants.button.ok}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default QuickBookVerificationModal;
