import {
    IStaticPenRationSchedule,
    IVariableSplitRationSchedule,
} from '../utils/Interface/PenRationScheduleInterface';
import { IEditPenRationTemplate } from '../utils/Interface/PenRationTemplatesInterface';
import { addDurationInDate_deprecated } from '.';
import dayjs from '../utils/dayjs';

export const fetchRationScheduleDays = (rationSchedulesDays: number) => {
    return rationSchedulesDays;
};

export const handleDateAccordingToDays = (
    penRationSchedulesCopy:
        | IStaticPenRationSchedule[]
        | IEditPenRationTemplate[]
        | IVariableSplitRationSchedule[],
) => {
    penRationSchedulesCopy?.map((_penRationSchedules, index) => {
        if (index) {
            return (penRationSchedulesCopy[index]['date'] =
                addDurationInDate_deprecated(
                    penRationSchedulesCopy[index - 1]['date'],
                    'YYYY-MM-DD',
                    fetchRationScheduleDays(
                        +penRationSchedulesCopy[index - 1]['days'],
                    ),
                    'days',
                ));
        }
    });
};

export const rationSchedulesPushHandler = (
    penRationSchedulesCopy:
        | IStaticPenRationSchedule[]
        | IEditPenRationTemplate[]
        | IVariableSplitRationSchedule[],
    rowIndex: number,
) => {
    penRationSchedulesCopy?.forEach((_penRationSchedules, index) => {
        if (index >= rowIndex) {
            penRationSchedulesCopy[index]['date'] = dayjs(
                penRationSchedulesCopy[index - 1]['date'],
            )
                .add(
                    fetchRationScheduleDays(
                        +penRationSchedulesCopy[index - 1]['days'],
                    ),
                    'day',
                )
                .format('YYYY-MM-DD');
        }
    });
};
