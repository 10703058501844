import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CREATE_SHIP_PO_INITIAL_STATE } from '../constants/ShipPOInitialState';

const shipPoSlice = createSlice({
    name: 'shipPO',
    initialState: { ...CREATE_SHIP_PO_INITIAL_STATE },
    reducers: {
        setGlobalInfoStateShipPO: (state, action) => {
            state.shipPoGlobalInfo = action.payload;
        },
        updateShipPoGlobalInfoId: (state, action) => {
            state.shipPoGlobalInfo.id = action.payload;
        },
        setGlobalInfoErrorStateShipPO: (state, action) => {
            state.shipPoGlobalInfoError = action.payload;
        },
        setShipPOViewSteps: (state, action) => {
            state.shipPOViewSteps = {
                ...state.shipPOViewSteps,
                ...action.payload,
            };
        },
        setShipmentStateShipPO: (state, action) => {
            state.shipments = action.payload;
        },
        setShipmentsAndFeeTotalStateShipPO: (state, action) => {
            state.shipmentAndFeeTotal = action.payload;
        },
        setFeeTypeStateShipPO: (state, action) => {
            state.shipPoFees = action.payload;
        },
        setLastModifiedDetailShipPO: (state, action) => {
            const { lastModifiedDate, lastModifiedUser } = action.payload;
            state.lastModifiedDate = lastModifiedDate;
            state.lastModifiedUser = lastModifiedUser;
        },
        setDestinationDetailShipPO: (state, action) => {
            state.selectedDestinationDetail = action.payload;
        },
        setShipPOList: (state, action) => {
            state.shipPOList = action.payload;
        },
        appendShipPOList: (state, action) => {
            state.shipPOList = [...state.shipPOList, ...action.payload];
        },
        setDeleteShipPOIds: (state, action) => {
            state.deleteShipPOIds = action.payload;
        },
        setShipPOFilter: (state, action) => {
            state.filter = action.payload;
        },
        appendShipPOFilter: (state, action) => {
            state.filter = { ...state.filter, ...action.payload };
        },
        setPaymentInfo: (state, action) => {
            state.paymentInfo = { ...state.paymentInfo, ...action.payload };
        },
        setCanCallService: (state, action) => {
            state.canCallService = action.payload;
        },
        setIsCattleAR: (state, action: PayloadAction<boolean>) => {
            state.paymentInfo.isCattleAR = action.payload;
        },
        setIsFreightPaid: (state, action: PayloadAction<boolean>) => {
            state.paymentInfo.isFreightPaid = action.payload;
        },
        setIsShipPoSubmitted: (state, action: PayloadAction<boolean>) => {
            state.isSubmitted = action.payload;
        },
        setShipPoFileDetail: (state, action) => {
            state.shipPoGlobalInfo = {
                ...state.shipPoGlobalInfo,
                ...action.payload,
            };
        },
        setShipPoDestinationDetail: (state, action) => {
            state.selectedDestinationDetail = action.payload;
        },
        setShipPOLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setShipPOPaymentLoading: (state, action) => {
            state.paymentLoading = action.payload;
        },
        setShouldAllowShipPoPageChange: (state, action) => {
            state.shouldAllowPageChange = action.payload;
        },
        resetAllShipPOListStates: state => {
            state.shipPOList = [];
            state.deleteShipPOIds = [];
            state.paymentLoading = true;
            state.loading = false;
            state.canCallService = true;
        },
        resetAllShipPOCreateState: (state, action) => {
            return CREATE_SHIP_PO_INITIAL_STATE;
        },
    },
});

export const shipPoActions = shipPoSlice.actions;

export const {
    setGlobalInfoStateShipPO,
    updateShipPoGlobalInfoId,
    setGlobalInfoErrorStateShipPO,
    setShipPOViewSteps,
    setShipmentStateShipPO,
    setShipmentsAndFeeTotalStateShipPO,
    setFeeTypeStateShipPO,
    setLastModifiedDetailShipPO,
    setDestinationDetailShipPO,
    setShipPOList,
    appendShipPOList,
    setDeleteShipPOIds,
    setShipPOFilter,
    appendShipPOFilter,
    setPaymentInfo,
    setCanCallService,
    setIsCattleAR,
    setIsFreightPaid,
    setIsShipPoSubmitted,
    setShipPoFileDetail,
    setShipPoDestinationDetail,
    setShipPOLoading,
    setShipPOPaymentLoading,
    setShouldAllowShipPoPageChange,
    resetAllShipPOListStates,
    resetAllShipPOCreateState,
} = shipPoSlice.actions;

const shipPoReducer = shipPoSlice.reducer;
export default shipPoReducer;
